"use client";

import { CardContainer } from "@/components/common";
import {
  Box,
  Button,
  VStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  HStack,
  Heading,
} from "@chakra-ui/react";
import "./SectionCard.scss";
import { useEffect, useRef, useState } from "react";
import {
  createPost,
  getBadges,
  getCertification,
  getUserDetails,
} from "@/client-api-manager/alm-api";
import DetailCard from "../detail-card/DetailCard";
import {
  constructCertification,
  formatDate,
} from "../../learning-util-service";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { downloadIcon, noCatalogFound } from "public/assets";
import { LearningObjectDetails } from "@/models/academy-models";
import JSZip from "jszip";
import { saveAs } from "file-saver";

type SectionCardProps = {
  type: "certifications" | "badges";
};

export default function SectionCard({ type }: SectionCardProps) {
  const badgeLoadRef = useRef<boolean>(false);

  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };
  const [selectedItem, setSelectedItem] = useState<string[]>([]);

  const updateSelectedItem = (id: string) => {
    const items = selectedItem.includes(id)
      ? selectedItem.filter((item) => item !== id)
      : [...selectedItem, id];
    setSelectedItem(items);
  };
  const [earned, setEarned] = useState<LearningObjectDetails[]>([]);
  const [inProgress, setInProgress] = useState<LearningObjectDetails[]>([]);
  const [available, setAvailable] = useState<LearningObjectDetails[]>([]);
  const [upcoming, setUpcoming] = useState<LearningObjectDetails[]>([]);

  useEffect(() => {
    if (type === "certifications") loadCertifications();
    if (type === "badges") {
      getUserDetails().then((res) => {
        const userId = res.data?.data?.id;
        loadBadges(userId);
      });
    }
  }, []);

  const loadCertifications = () => {
    getCertification("earned").then((res: any) => {
      const d = constructCertification(res.data, true);
      setEarned(d);
    });
    getCertification("in-progress").then((res: any) => {
      const d = constructCertification(res.data, true);
      setInProgress(d);
    });
    getCertification("available").then((res: any) => {
      const d = constructCertification(res.data);
      setAvailable(d);
    });
    getCertification("upcoming").then((res: any) => {
      const d = constructCertification(res.data);
      setUpcoming(d);
    });
  };

  const loadBadges = (userId: string) => {
    if (!badgeLoadRef.current) {
      loadAllBadges(userId);
      badgeLoadRef.current = true;
    }
  };

  const loadAllBadges = (userId: string, url?: string) => {
    getBadges(userId, url).then((res: any) => {
      const allBadges = res.data.data.map((item: any) => {
        return {
          dateAchieved: item?.attributes?.dateAchieved,
          badgeId: item?.relationships?.badge?.data?.id,
          modelId: item?.relationships?.model?.data?.id,
          id: item?.id,
        };
      });

      const allBadgeDetails = allBadges.map((item: any) => {
        const badgeData = res.data.included.find(
          (badge: any) => badge.type === "badge" && badge.id === item.badgeId
        );
        const modelData = res.data.included.find(
          (model: any) =>
            model.type === "learningObject" && model.id === item.modelId
        );
        return {
          id: item.id,
          learnerObjectId: item.modelId,
          imageUrl: badgeData?.attributes.imageUrl,
          name: badgeData?.attributes.name,
          description:
            modelData?.attributes.localizedMetadata[0].description || "",
          overview: modelData?.attributes.localizedMetadata[0].overview || "",
          format: modelData?.attributes.loFormat,
          dateCompleted: item.dateAchieved,
          badgeState: badgeData?.attributes.state,
          ...(item.dateAchieved && { state: "COMPLETED" }),
        };
      });
      const availableBadges = allBadgeDetails.filter(
        (badge: any) =>
          !badge.dateCompleted &&
          badge.badgeState?.toLocaleLowerCase() !== "retired"
      );
      // Show earned badges irrespective even if it's retired
      const earnedBadges = allBadgeDetails.filter(
        (badge: any) => badge.dateCompleted
      );

      setAvailable((prev: any) => [...prev, ...availableBadges]);
      setEarned((prev: any) => [...prev, ...earnedBadges]);
      if (res.data.links?.next) {
        try {
          loadAllBadges(userId, res.data.links?.next);
        } catch (error) {
          console.error("Error loading all badges", error);
        }
      }
    });
  };

  const downloadBadgesAsZip = async (
    badges: LearningObjectDetails[],
    fileName = "badges.zip"
  ) => {
    if (badges.length) {
      if (badges.length === 1) {
        // Direct download if there's only one badge
        let badgeUrl: string;
        const badge = badges[0];
        const imageName = `${badge.name}.png`.toLowerCase();


        if (badge.type === "certification") {
          badgeUrl = badge.imageUrl || "";
        } else {
          const imageUrlParts = badge.imageUrl?.split("/") || [];
          const accountId = imageUrlParts[5] || "";
          const badgeName = imageUrlParts[9] || "";
          badgeUrl = `/console/badge-image?accountId=${accountId}&badgeName=${badgeName}`;
        }

        const response = await fetch(badgeUrl);
        if (response.ok) {
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = imageName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        } else {
          console.error("Failed to fetch", badge.imageUrl);
        }
      } else {
        const zip = new JSZip();

        for (const badge of badges) {
          const imageName = `${badge.name}.png`.toLowerCase();
          let badgeUrl: string;
          if (badge.type === "certification") {
          badgeUrl = badge.imageUrl || "";
        } else {
          const imageUrlParts = badge.imageUrl?.split("/") || [];
          const accountId = imageUrlParts[5] || "";
          const badgeName = imageUrlParts[9] || "";
          badgeUrl = `/console/badge-image?accountId=${accountId}&badgeName=${badgeName}`;
        }
          const response = await fetch(badgeUrl);
          if (response.ok) {
            const blob = await response.blob();
            zip.file(imageName, blob);
          } else {
            console.error("Failed to fetch", badge.imageUrl);
          }
        }

        zip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(content, fileName);
        });
      }
    }
  };

  const handleDownloadAll = () => {
    downloadBadgesAsZip(earned, "earned_badges.zip");
  };

  const handleDownloadSelected = () => {
    const selectedBadges = earned.filter((item) =>
      selectedItem.includes(item.learnerObjectId)
    );
    downloadBadgesAsZip(selectedBadges, "selected_badges.zip");
  };

  const handleDownloadById = (id: string) => {
    const selectedBadges = earned.filter((item) => item.learnerObjectId === id);
    downloadBadgesAsZip(selectedBadges, "badges.zip");
  };

  const noRecordMessage = () => (
    <VStack w={"100%"} justifyContent={"center"} mt={20} gap={6}>
      <Image src={noCatalogFound.src} alt={`No ${type} found`} mb={4} />
      <Heading as="h3" fontSize={24}>
        No {type} available
      </Heading>
    </VStack>
  );

  const tabsConfig =
    type === "badges"
      ? [
          { label: "Earned", content: earned },
          { label: "Available", content: available },
        ]
      : [
          { label: "Earned", content: earned },
          { label: "In-progress", content: inProgress },
          { label: "Available", content: available },
        ];

  return (
    <CardContainer customClass="your-item-main-holder">
      <HStack className="your-item-title-container">
        <Box>
          <Text as="h3" className="your-item-title">
            Your {type}
          </Text>
          <Text as="p" className="your-item-subtitle">
            View your complete list of {type} below.
          </Text>
        </Box>
        {tabIndex === 0 && (
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  className="your-item-menu-button"
                  isActive={isOpen}
                  as={Button}
                  rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  p={0}
                >
                  Download
                </MenuButton>
                <MenuList className="menu-list-container">
                  {selectedItem.length > 0 && (
                    <>
                      <MenuItem
                        className="dropdown-menu-item"
                        onClick={handleDownloadSelected}
                        isDisabled={false}
                      >
                        <Image
                          src={downloadIcon.src}
                          alt="Download"
                          className="menu-image"
                        />
                        <span className="menu-text">Download Selected</span>
                      </MenuItem>
                    </>
                  )}
                  <MenuItem
                    className="dropdown-menu-item"
                    onClick={handleDownloadAll}
                    isDisabled={false}
                  >
                    <Image
                      src={downloadIcon.src}
                      alt="Download"
                      className="menu-image"
                    />
                    <span className="menu-text">Download All</span>
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        )}
      </HStack>
      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          {tabsConfig.map(({ label }, index) => (
            <Tab key={index}>{label}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabsConfig.map(({ content }, index) => (
            <TabPanel key={index}>
              {content.length === 0 && noRecordMessage()}
              {content.length > 0 &&
                content.map((data: any) => (
                  <DetailCard
                    key={data.learnerObjectId}
                    id={data.learnerObjectId}
                    imageUrl={data.imageUrl}
                    title={data.name}
                    subtitle={data.description || data.overview}
                    status={data.state}
                    format={data.format}
                    date={formatDate(
                      data.dateCompleted ?? data.lastAccessDate,
                      "MM/DD/YY"
                    )}
                    onSelect={updateSelectedItem}
                    type={type}
                    onDownload={handleDownloadById}
                  />
                ))}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </CardContainer>
  );
}
