"use client";

import {
  AccordionComponent,
  SelectDropdown,
  Loader,
} from "@/components/common";
import {
  Flex,
  VStack,
  Text,
  Heading,
  Box,
  CheckboxGroup,
  Stack,
  Checkbox,
  HStack,
  Button,
  Image,
} from "@/components/ChakraUiManager";
import { useState, useEffect, useRef } from "react";
import { CourseCard } from "@/components";
import "./LearningCatalog.scss";
import { LearningObjectDetails } from "@/models/academy-models";
import { getLearningObjects, getListData } from "@/client-api-manager/alm-api";
import {
  DEFAULT_LO_TYPES,
  constructLearningObject,
  SORTING_MULTI_SELECT_PROP,
} from "../learning-util-service";
import { noCatalogFound } from "public/assets";
import { useSearchParams } from "next/navigation";
import BadgeHeader from "./badge-path/badge-header/BadgeHeader";

type PageData = {
  headline: string;
  subhead: string;
};

const LearningCatalogHeader = ({ headline, subhead }: PageData) => {
  return (
    <BadgeHeader
      pageType="ACADEMY"
      title={headline || "Catalog"}
      subtitle={
        subhead ||
        `These are all the Courses/Badge Paths/Certifications available to you.
        Use the filters on the left to refine your search.`
      }
      showBadgeProgress={false}
      showBackButton={false}
    />
  );
};

const CatalogFilterPanel = ({
  setFilterModel,
  bookmarked,
}: {
  setFilterModel: (value: string) => void;
  bookmarked: string;
}) => {
  const [myLearning, setMyLearning] = useState<string[]>(
    bookmarked ? ["course"] : []
  );
  const [format, setFormat] = useState<string[]>([]);
  const [type, setType] = useState<string[]>([]);

  useEffect(() => {
    let loTypes = type.concat(myLearning).join();
    let filterString = loTypes ? "filter.loTypes=" + loTypes : DEFAULT_LO_TYPES;
    let formatFilter = format.length ? "filter.loFormat=" + format.join() : "";
    if (formatFilter) filterString += `&${formatFilter}`;
    let bookmarksFilter = myLearning.length ? "filter.bookmarks=true" : "";
    if (bookmarksFilter) filterString += `&${bookmarksFilter}`;
    setFilterModel(filterString);
  }, [myLearning, format, type, setFilterModel]);

  return (
    <Box p={10} className="learning-catalog-filter-container">
      <AccordionComponent accordionTitle={"Filter"} isExpand={0}>
        <VStack alignItems={"start"} className="filter-group">
          <Text fontWeight={"bold"}>My learning</Text>
          <CheckboxGroup
            defaultValue={myLearning}
            onChange={(e: any) => setMyLearning(e)}
          >
            <Stack gap={5} direction={["column"]}>
              <Checkbox spacing={3} value="course">
                Saved course
              </Checkbox>
              <Checkbox spacing={3} value="certification">
                Saved certification
              </Checkbox>
            </Stack>
          </CheckboxGroup>
        </VStack>
        <VStack gap={5} alignItems={"start"} className="filter-group" pb={6}>
          <Text fontWeight={"bold"}>Format</Text>
          <CheckboxGroup
            defaultValue={format}
            onChange={(e: any) => setFormat(e)}
          >
            <Stack gap={5} direction={["column"]}>
              <Checkbox spacing={3} value="Self Paced">
                E-learning
              </Checkbox>
              <Checkbox spacing={3} value="Activity">
                Documentation
              </Checkbox>
              <Checkbox spacing={3} value="Virtual Classroom">
                Instructor-led sessions
              </Checkbox>
            </Stack>
          </CheckboxGroup>
        </VStack>
        <VStack gap={5} alignItems={"start"} className="filter-group" pb={6}>
          <Text fontWeight={"bold"}>Type</Text>
          <CheckboxGroup defaultValue={type} onChange={(e: any) => setType(e)}>
            <Stack gap={5} direction={["column"]}>
              <Checkbox spacing={3} value="learningProgram">
                Badge paths
              </Checkbox>
              <Checkbox spacing={3} value="course">
                Courses
              </Checkbox>
              <Checkbox spacing={3} value="certification">
                Certifications
              </Checkbox>
            </Stack>
          </CheckboxGroup>
        </VStack>
      </AccordionComponent>
    </Box>
  );
};
const LearningSortingPanel = ({
  sortingModel,
  setSortingModel,
}: {
  sortingModel: string;
  setSortingModel: any;
}) => {
  return (
    <>
      <Box className="sorting-box">
        <HStack
          className="catalog-sorting-container"
          alignItems={"center"}
          justifyContent={"end"}
          gap={3}
        >
          <Text as="label" fontWeight={"bold"} htmlFor={"sorting-container"}>
            Sort by
          </Text>
          <SelectDropdown
            {...SORTING_MULTI_SELECT_PROP}
            value={sortingModel}
            onChange={(e: string) => setSortingModel(e)}
            placeholder="Select"
            id="sorting-container"
            clearable={false}
          ></SelectDropdown>
        </HStack>
      </Box>
    </>
  );
};

const LearningCatalog = ({ pageData }: { pageData: PageData }) => {
  const queryParam = useSearchParams();
  const bookmarked = queryParam?.get("bookmarked");
  const [catalogList, setCatalogList] = useState<LearningObjectDetails[]>([]);
  const [sortingModel, setSortingModel] = useState<string>(
    "-recommendationScore"
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [filterModel, setFilterModel] = useState<string>("");
  const loadMoreLinkRef = useRef<string>("");

  const setCatalogItems = (catalogItems: any, update: boolean) => {
    loadMoreLinkRef.current = catalogItems.data?.links?.next ?? "";
    let catalogItemsList = constructLearningObject(catalogItems?.data, true);
    catalogItemsList = catalogItemsList.filter(
      (item: LearningObjectDetails) => {
        const typesInFilter = filterModel.match(/filter\.loTypes=([^&]*)/);
        if (typesInFilter && typesInFilter[1]) {
          const typesArray = typesInFilter[1].split(",");
          return item.type ? typesArray.includes(item.type) : false;
        }
        return true;
      }
    );

    if (update) {
      setCatalogList((prevCatalogList) => [
        ...prevCatalogList,
        ...catalogItemsList,
      ]);
    } else {
      setCatalogList(catalogItemsList);
    }
  };

  const getCatalogs = async () => {
    setLoading(true);
    try {
      const catalogItems = await getLearningObjects(
        filterModel,
        10,
        sortingModel
      );
      if (catalogItems?.data?.data) {
        setCatalogItems(catalogItems, false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const loadMoreCatalogItems = async () => {
    if (loadMoreLinkRef.current) {
      let getMoreLink = loadMoreLinkRef.current;
      if (!getMoreLink.includes("&filter.ignoreHigherOrderLOEnrollment=true")) {
        getMoreLink += "&filter.ignoreHigherOrderLOEnrollment=true";
      }
      const catalogItems = await getListData(getMoreLink);
      if (catalogItems?.data?.data?.length) {
        setCatalogItems(catalogItems, true);
      }
    }
  };

  const updateCatalogItem = (
    catalogItem: LearningObjectDetails,
    index: number
  ) => {
    let newCatalogList = [...catalogList];
    newCatalogList[index] = catalogItem;
    setCatalogList(newCatalogList);
  };

  useEffect(() => {
    if (filterModel) getCatalogs();
  }, [filterModel, sortingModel]);

  return (
    <Box className="academy-catalog-container">
      <LearningCatalogHeader
        headline={pageData.headline}
        subhead={pageData.subhead}
      />
      <Flex>
        <CatalogFilterPanel
          setFilterModel={setFilterModel}
          bookmarked={bookmarked || ""}
        />
        <VStack className="catalog-container">
          <LearningSortingPanel
            sortingModel={sortingModel}
            setSortingModel={setSortingModel}
          />
          {loading ? (
            <Loader isOverlay={false} spinnerColor="#222" spinnerSize="40px" />
          ) : (
            <>
              <Flex className="catalog-card-container">
                {catalogList?.map(
                  (catalog: LearningObjectDetails, index: number) => (
                    <CourseCard
                      key={index}
                      course={catalog}
                      updateCatalogItem={(
                        newCatalogItem: LearningObjectDetails
                      ) => updateCatalogItem(newCatalogItem, index)}
                    />
                  )
                )}
              </Flex>
              {!catalogList?.length && (
                <VStack
                  w={"100%"}
                  justifyContent={"center"}
                  mt={20}
                  gap={6}
                  className="no-results-found-section"
                >
                  <Image src={noCatalogFound.src} alt={"product"} mb={4} />
                  <Heading as="h3">There are no results</Heading>
                  <Text>
                    Update your filter selections on the left to view available
                    learning materials
                  </Text>
                </VStack>
              )}
              {loadMoreLinkRef.current && catalogList.length && (
                <Flex justifyContent={"center"} marginLeft={-20} w={"100%"}>
                  <Button
                    className="app-btn-reg-secondary"
                    onClick={loadMoreCatalogItems}
                  >
                    Show more
                  </Button>
                </Flex>
              )}
            </>
          )}
        </VStack>
      </Flex>
    </Box>
  );
};

export default LearningCatalog;
