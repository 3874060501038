"use client";
import { Box, Flex, Text } from "@/components/ChakraUiManager";
import "./DocumentListCard.scss";
import { SVGArrowForwardIcon, SVGResourceIcon } from "public/assets";
import Link from "next/link";
import { ROUTE_PATH } from "@/route-config/route-path";

type DocumentListCardProp = {
  title: string;
  description: string;
  url: string;
  type: string;
};

function DocumentListCard(props: DocumentListCardProp) {
  const { title, url, type } = props;
  return (
    <Box className="doc-card-wrapper">
      <Link href={`${ROUTE_PATH["DOCUMENT_DETAIL"]}/${type}/${url}`}>
        <Flex justifyContent="space-between" gap={8}>
          <Flex direction={{ base: "column", md: "row" }}>
            <SVGResourceIcon />
            <Box className="doc-card-content">
              <Text className="title">{title}</Text>
            </Box>
          </Flex>
          <Box alignSelf={{ base: "flex-end", md: "flex-start" }}>
            <SVGArrowForwardIcon />
          </Box>
        </Flex>
      </Link>
    </Box>
  );
}
export default DocumentListCard;
