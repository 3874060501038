"use client";
import {
  CardContainer,
  ToolTip,
  TimeField,
  ErrorAlert,
} from "@/components/common";
import { FormikErrors, FormikTouched, useFormik } from "formik";
import {
  Box,
  Checkbox,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  FinancialCalenderBusinessDaysConfigProps,
  getValidationSchema,
  financialCalenderBusinessDaysConfig,
  BusinessConfigDaySelection,
} from "./FinancialCalenderBusinessDaysConfigValidation";
import Toggle from "@/components/common/Toggle/Toggle";
import Image from "next/image";
import { finxactDarkModeIcon } from "public/assets";
import { IC_ENTITY, deepCopy, shouldUpdateWorkflowStatus } from "@/utils";
import { FinancialCalendarData } from "../../model";
import { updateFinancialCalendar } from "../../ic-config-api-service";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import styles from "./FinancialCalenderBusinessDaysConfig.module.scss";
import styleVars from "@/styles/_export.module.scss";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type FinancialCalendarConfigurationProps = {
  data: {
    formData: FinancialCalendarData | null;
    institutionTimezone: any;
    calendarOptionsData: PostSchema;
  };
};

const FinancialCalenderBusinessDaysConfig = (
  props: FinancialCalendarConfigurationProps
) => {
  const toast = useToast();
  const financialCalendarData = props.data?.formData ?? null;

  const { calendarOptionsData } = props.data;

  const onSubmit = async (values: FinancialCalenderBusinessDaysConfigProps) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?.calendarName!,
          stage: IC_ENTITY.financial_calendar,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }

    const businessDaysData = values.businessDays
      .filter(
        (day) =>
          day.isOn &&
          (day.isAllDay || (day.openTm.length > 0 && day.closeTm.length > 0))
      )
      .map((day) => ({
        calendarName: props.data.formData?.calendarName,
        dayOfWk: day.dayOfWk,
        isAllDay: day.isAllDay,
        openTm: day.isAllDay ? "" : day.openTm,
        closeTm: day.isAllDay ? "" : day.closeTm,
      }));

    const res = await updateFinancialCalendar({
      formData: {
        _vn: financialCalendarData?._vn,
        calendarName: props.data.formData?.calendarName,
        tmZoneCode: props.data?.formData?.tmZoneCode,

        //Patch Data
        businessDays: businessDaysData,
      },
      toastOptions: {
        toast: toast,
        toastId: "financial-calender-business-day-configuration",
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (res) {
      navigateToNextPage?.();
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(calendarOptionsData);
      setValidationSchema(yupSchema.fields.businessDays);
    })();
  }, [calendarOptionsData]);

  const daysValue = deepCopy(
    financialCalenderBusinessDaysConfig.businessDays
  ).map((day: any, idx: number) => {
    const wkday = props.data?.formData?.businessDays?.find(
      (bday) => bday.dayOfWk === day.dayOfWk
    );
    if (wkday) {
      day.isOn = true;
      day.isAllDay = wkday.isAllDay;
      day.openTm = wkday.openTm;
      day.closeTm = wkday.closeTm;
    } else {
      day.isOn = false;
    }

    return {
      ...day,
      calendarName: props.data.formData?.calendarName ?? "",
    };
  });

  // using useFormik hook from Formik Library
  const {
    handleSubmit,
    errors,
    touched,
    values,
    setFieldValue,
    dirty,
    setFieldTouched,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: { businessDays: daysValue },
    enableReinitialize: true,
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  const formTooltip = {
    timeZone:
      tooltipFlyoutDetails?.tooltipsMap?.get("tmZoneCode")?.tooltip_text_main,
    refCalendar:
      tooltipFlyoutDetails?.tooltipsMap?.get("refCalendar")?.tooltip_text_main,
  };

  return (
    <form
      className={`${styles["financial-calender-business-form"]}`}
      onSubmit={handleSubmit}
      id="finxact-form"
    >
      <CardContainer customClass="app-form-container">
        <Box className="app-form-field-container">
          <Flex alignItems={"center"}>
            <Text fontSize="2xl" fontWeight="bold">
              Time Zone
            </Text>
            {formTooltip.timeZone && (
              <ToolTip
                content={formTooltip.timeZone}
                placement={"left"}
                IconDefault={true}
              />
            )}
          </Flex>

          <Text fontSize="l" fontWeight="light">
            {`UTC-05:00 Eastern Time (${
              props.data.institutionTimezone.find(
                (data: any) => data.value === financialCalendarData?.tmZoneCode
              )?.label ?? "US & Canada"
            })`}
          </Text>
        </Box>
        <Box className="app-form-field-container">
          <Flex alignItems={"center"}>
            <Text fontSize="2xl" fontWeight="bold">
              Reference calendar
            </Text>
            {formTooltip.refCalendar && (
              <ToolTip
                content={formTooltip.refCalendar}
                placement={"left"}
                IconDefault={true}
              />
            )}
          </Flex>

          <Text fontSize="l" fontWeight="light">
            {props.data?.formData?.refCalendar
              ?.map((obj) => obj.refCalendarName)
              .join(", ") ?? "Standard"}
          </Text>
        </Box>
        <Box className="app-form-field-container">
          <Table className="table" variant="unstyled" borderRadius="2xl">
            <Thead className="custom-table-header">
              <Tr
                height={17}
                backgroundColor={styleVars.appColorBgBadge}
                borderColor={styleVars.appRatingColor}
                className="custom-table-tr-fedwire"
              >
                <Th
                  textTransform="none"
                  className="fedwire-left-custom-table-tr"
                >
                  <Text fontSize="xl" className={styles["text-styling-header"]}>
                    Business days
                  </Text>
                </Th>
                <Th textTransform="none">
                  <Text fontSize="xl" className={styles["text-styling-header"]}>
                    From
                  </Text>
                </Th>
                <Th textTransform="none">
                  <Text fontSize="xl" className={styles["text-styling-header"]}>
                    To
                  </Text>
                </Th>
                <Th
                  textTransform="none"
                  className="fedwire-right-custom-table-tr"
                >
                  <Text
                    fontSize="xl"
                    textAlign="center"
                    className={styles["text-styling-header"]}
                  >
                    All day
                  </Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {values.businessDays?.map((row, index) => {
                const error =
                  errors.businessDays &&
                  (errors.businessDays[index] as
                    | FormikErrors<BusinessConfigDaySelection>
                    | undefined);
                const _touched =
                  touched.businessDays &&
                  (touched.businessDays[
                    index
                  ] as FormikTouched<BusinessConfigDaySelection>);

                return (
                  <Tr key={row.day}>
                    <Td width="15%">
                      <Toggle
                        title={row.day}
                        isToggled={row.isOn}
                        aria-label={row.day + " is available"}
                        onChange={(changedValue) => {
                          setFieldValue(
                            `businessDays[${index}].isOn`,
                            changedValue.target.checked
                          );
                          if (!changedValue.target.checked) {
                            setFieldValue(
                              `businessDays[${index}].isAllDay`,
                              false
                            );
                          }
                        }}
                        financialCalendar={true}
                      />
                    </Td>

                    {row.isOn && (
                      <Td>
                        <TimeField
                          value={row.openTm}
                          onChange={(value) => {
                            setFieldValue(
                              `businessDays[${index}].openTm`,
                              value
                            );
                          }}
                          disabled={row.isAllDay}
                          {...(row.closeTm !== "" ? { max: row.closeTm } : {})}
                          ariaLabel="From time field"
                          placeholder="HH:MM:SS"
                        />

                        {error && _touched && error.openTm && (
                          <ErrorAlert>
                            <span>{error.openTm}</span>
                          </ErrorAlert>
                        )}
                      </Td>
                    )}

                    {!row.isOn && (
                      <Td colSpan={2}>
                        <Flex
                          alignItems={"center"}
                          className={`${styles["fin-cal-closed-section"]}`}
                        >
                          <Image
                            className={`${styles["fin-cal-closed-section-img"]}`}
                            src={finxactDarkModeIcon}
                            alt="arrow-down"
                          />
                          <Text className={styles["text-closed-calendar"]}>
                            Closed
                          </Text>
                        </Flex>
                      </Td>
                    )}

                    {row.isOn && (
                      <Td>
                        <TimeField
                          value={row.closeTm}
                          onChange={(value) => {
                            setFieldValue(
                              `businessDays[${index}].closeTm`,
                              value
                            );
                          }}
                          disabled={row.isAllDay}
                          min={row.openTm}
                          ariaLabel="To time field"
                          placeholder="HH:MM:SS"
                        />

                        {error && _touched && error.closeTm && (
                          <ErrorAlert>
                            <span>{error.closeTm}</span>
                          </ErrorAlert>
                        )}
                      </Td>
                    )}
                    <Td width="10%" textAlign="center">
                      <Checkbox
                        isChecked={row.isAllDay}
                        disabled={!row.isOn}
                        aria-label={row.day + " is available for all day"}
                        onChange={(changedValue) => {
                          setFieldValue(
                            `businessDays[${index}].isAllDay`,
                            changedValue.target.checked
                          );
                        }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </CardContainer>
    </form>
  );
};

export default FinancialCalenderBusinessDaysConfig;
