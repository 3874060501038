const INTEREST_DEPOSIT_LABELS = {
  WHAT_IS_CALCULATION_METHOD:
    "What calculation method should be used to calculate the daily accrued interest?",
  BALANCE_LIKE_TO_USE_TO_CALCULATE_ACCURACY:
    "What position balance would you like to use to calculate the interest accrual?",
  WHAT_IS_MINIMUM_BALANCE:
    "What is the minimum balance required to accrue interest on the position?",
  DEPOSIT_NAME: "interest COMPONENT (Deposit)",
  FOUNDATIONAL_INTEREST: "foundational interest",
  FOUNDATIONAL_INTEREST_DETAILS: "Foundational Interest Details",
  INDEXED_RATE: "Indexed rate",
  INDEXED_RATE_FOUNDATIONAL_DETAIL: "Indexed Rate – Foundational Details",
  INDEXED_RATE_DETAILS: "Do you want to add an indexed interest rate?",
  WHAT_IS_INDEX_NAME: "What is the index name?",
  WHAT_IS_INDEX_NOMINAL_RATE: "What is the index rate in nominal rate?",
  LEVERAGE_EXISTING_INDEX: "Select an existing interest rate index",
  ADD_NEW_INDEX: "Add a new interest rate index",
  SELECT_INDEX: "Select index",
  YES: "Yes",
  NO: "No",
  ADD_RATE_OFFSET: "Add rate offset?",
  WHAT_RATE_ADJUSTMENT_TERM: "What is the rate adjustment term?",
  WHAT_IS_RATE_OFFSET_VAL: "What is the rate offset value?",
  WHAT_IS_PROMOTIONAL_RATE_TERM: "What is the promotional rate term?",
  SKIP_SECTION_INTEREST_DEPOSIT_FORM_MSG:
    "You will skip the following sections: Indexed Rate – Rate Adjustment and Ranges, Indexed Rate – Rate Offset, & Rounding, Indexed Rate – Review Frequency",
  SKIP_SECTION_INTEREST_DEPOSIT_PROMOTIONAL_FORM_MSG:
    "You will skip the following sections: Promotional Rate - Rate Adjustments & Ranges, Promotional Rate - Rate Offset & Rounding",
};

const INTEREST_LOAN_LABELS = {
  WHAT_IS_CALCULATION_METHOD:
    "What calculation method should be used to calculate the daily accrued interest?",
  BALANCE_LIKE_TO_USE_TO_CALCULATE_ACCURACY:
    "What position balance would you like to use to calculate the interest accrual?",
  WHAT_IS_MINIMUM_BALANCE:
    "What is the minimum balance required to accrue interest on the position?",
  DEPOSIT_NAME: "interest COMPONENT (loan)",
  FOUNDATIONAL_INTEREST: "foundational interest",
  FOUNDATIONAL_INTEREST_DETAILS: "Foundational Interest Details",
  INDEXED_RATE: "Indexed rate",
  INDEXED_RATE_FOUNDATIONAL_DETAIL: "Indexed Rate – Foundational Details",
  INDEXED_RATE_DETAILS: "Do you want to add an indexed rate detail?",
  WHAT_IS_INDEX_NAME: "What is the index name?",
  WHAT_IS_INDEX_NOMINAL_RATE: "What is the index rate in nominal rate?",
  LEVERAGE_EXISTING_INDEX: "Leverage existing index",
  ADD_NEW_INDEX: "Add new index",
  SELECT_INDEX: "Select index",
  YES: "Yes",
  NO: "No",
  ADD_RATE_OFFSET: "Add rate offset?",
  WHAT_RATE_ADJUSTMENT_TERM: "What is the rate adjustment term?",
  WHAT_IS_RATE_OFFSET_VAL: "What is the rate offset value?",
  WHAT_IS_PROMOTIONAL_RATE_TERM: "What is the promotional rate term?",
};

const LENDING_INTEREST_GENERAL_COMPONENT_SETUP = {
  pageName: "Lending-Interest",
  depositName: "Interest Component (Loan)",
  mainTitle: "General Component Setup",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  textFieldLabel: "What is the component name?",
  textFieldLabelToolTip: "Tool tip description here",
  textFieldPlaceholder: "[Component_name]",
  versionLabel: "Version",
  versionTooltip: "Tool tip desc here",
};

const LENDING_REPAYMENT_GENERAL_COMPONENT_SETUP = {
  pageName: "Lending-Repayment",
  depositName: "Repayment Component (Loan)",
  mainTitle: "General Component Setup",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  textFieldLabel: "What is the component name?",
  textFieldLabelToolTip: "Tool tip description here",
  textFieldPlaceholder: "[Component_Name]",
  versionLabel: "Version",
  versionTooltip: "Tool tip desc here",
};

const LENDING_COLLATERAL_GENERAL_COMPONENT_SETUP = {
  pageName: "Lending-Collateral",
  depositName: "Collateral Component (Loan)",
  mainTitle: "General Component Setup",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  textFieldLabel: "What is the component name?",
  textFieldLabelToolTip: "Tool tip description here",
  textFieldPlaceholder: "[Component_Name]",
  versionLabel: "Version",
  versionTooltip: "Tool tip desc here",
};

const COLLATERAL_SECURITY_SPECIFICATIONS_LABELS = {
  WHAT_IS_LOAN_PERCENTAGE_VALUE:
    "What is the percentage of the loan that is covered by securities?",
  LOAN_PERCENTAGE_IS_REQUIRED: true,
  LOAN_PERCENTAGE_TOOLTIP_DESC: "No Desc",
};

const FEE_COMPONENT_LOAN_GENERAL_COMPONENT_SETUP = {
  pageName: "Lending-Fee",
  depositName: "Fee Component (Loan)",
  mainTitle: "General Component Setup",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  textFieldLabel: "What is the component name?",
  textFieldLabelToolTip: "Tool tip description here",
  textFieldPlaceholder: "[Component_Name]",
  versionLabel: "Version",
  versionTooltip: "Tool tip desc here",
  currencyLabel: "Set the currency for this component",
};

const FEE_CONFIGURATION_DEPOSITE_SETUP = {
  depositName: "FEE COMPONENT (DEPOSIT)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Fee Configuration",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};

const FEE_CONFIGURATION_LOAN_SETUP = {
  depositName: "FEE COMPONENT (LOAN)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Fee Configuration",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};

const FEE_SERVICE_CHARGE_DETAILS_DEPOSITE_SETUP = {
  depositName: "FEE COMPONENT (DEPOSIT)",
  featureName: "",
  version: "VERSION 1",
  mainTitle: "Service Charge Details",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};

const FEE_SERVICE_CHARGE_DETAILS_LOAN_SETUP = {
  depositName: "FEE COMPONENT (LOAN)",
  featureName: "[component name]",
  version: "VERSION 1",
  mainTitle: "Service Charge Details",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};

const FEE_EARNING_ANALYSIS_DEPOSITE_SETUP = {
  depositName: "FEE COMPONENT (DEPOSIT)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Earning Analysis",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};

const FEE_EARNING_ANALYSIS_LOAN_SETUP = {
  depositName: "FEE COMPONENT (LOAN)",
  featureName: "[component name]",
  version: "VERSION 1",
  mainTitle: "Earning Analysis",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};

const TERM_MATURITY_CONFIGURATION = {
  WHAT_IS_PROMOTIONAL_RATE_TERM:
    "How long before the maturity date do you want to alert the customer of the impending maturity?",
  WHAT_RATE_ADJUSTMENT_TERM:
    "How long before the maturity date do you want to alert the customer of the impending maturity?",
  WHAT_IS_THE_MATURITY_OPTION: "What are the available options at maturity?",
};

const LOAN_INTEREST_FOUNDATIONAL_DETAILS = {
  depositName: "INTEREST COMPONENT (LOAN)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Indexed Rate – Foundational Details",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const LOAN_INTEREST_INDEX_RATE_ADJUSTMENTS = {
  depositName: "INTEREST COMPONENT (LOAN)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Indexed Rate - Rate Adjustments and Ranges",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const LOAN_INTEREST_INDEX_RATE_OFFSET = {
  depositName: "INTEREST COMPONENT (LOAN)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Indexed Rate - Rate Offset, Terms & Rounding",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const LOAN_INTRO_FOUNDATIONAL_DETAILS = {
  depositName: "INTEREST COMPONENT (LOAN)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Introduction Rate - Foundational Details",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const LOAN_INTRO_RATE_ADJUSTMENTS = {
  depositName: "INTEREST COMPONENT (LOAN)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Introduction Rate - Rate Adjustments and Ranges",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const LOAN_INTEREST_INTRO_RATE_OFFSET = {
  depositName: "INTEREST COMPONENT (LOAN)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Introduction Rate - Rate Adjustments and Ranges",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const DEPOSIT_INTEREST_FOUNDATIONAL_DETAILS = {
  depositName: "INTEREST COMPONENT (DEPOSIT)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Indexed Rate - Foundational Details",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const DEPOSIT_INTEREST_INDEX_RATE_ADJUSTMENTS = {
  depositName: "INTEREST COMPONENT (DEPOSIT)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Indexed Rate - Rate Adjustments and Ranges",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const DEPOSIT_INTEREST_INDEX_RATE_OFFSET = {
  depositName: "INTEREST COMPONENT (DEPOSIT)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Indexed Rate - Rate Offset, Terms & Rounding",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const DEPOSIT_INTRO_FOUNDATIONAL_DETAILS = {
  depositName: "INTEREST COMPONENT (DEPOSIT)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Indexed Rate - Foundational Details",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const DEPOSIT_INTRO_RATE_ADJUSTMENTS = {
  depositName: "INTEREST COMPONENT (DEPOSIT)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Promotional Rate - Rate Adjustments and Ranges",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const DEPOSIT_INTEREST_INTRO_RATE_OFFSET = {
  depositName: "INTEREST COMPONENT (DEPOSIT)",
  featureName: "[COMPONENT NAME]",
  version: "VERSION 1",
  mainTitle: "Promotional Rate - Rate Offset, Terms & Rounding",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const FIN_ORG_PARTY_LIMIT_ADD_LIMIT = {
  depositName: "Financial Organization ",
  featureName: "",
  version: "",
  mainTitle: "Party limit - 1 of 4",
  subText:
    "Facilitate setting up accumulated transaction limits, offering options to utilize existing components or create new parameters for transactional control",
};
const FIN_ORG_PARTY_LIMIT_ACCUMULATED_TRANSACTION = {
  depositName: "Financial Organization ",
  featureName: "",
  version: "",
  mainTitle: "Party limit - 2 of 4",
  subText:
    "Facilitate setting up accumulated transaction limits, offering options to utilize existing components or create new parameters for transactional control",
};
const FIN_ORG_PARTY_LIMIT_SINGLE_TRANSACTION = {
  depositName: "Financial Organization ",
  featureName: "",
  version: "",
  mainTitle: "Party limit - 3 of 4",
  subText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
};
const FIN_ORG_PARTY_LIMIT_GEN_LIMIT = {
  depositName: "Financial Organization ",
  featureName: "",
  version: "",
  mainTitle: "Party limit - 4 of 4",
  subText:
    "Facilitate setting up accumulated transaction limits, offering options to utilize existing components or create new parameters for transactional control",
};

export {
  INTEREST_DEPOSIT_LABELS,
  LENDING_INTEREST_GENERAL_COMPONENT_SETUP,
  LENDING_REPAYMENT_GENERAL_COMPONENT_SETUP,
  LENDING_COLLATERAL_GENERAL_COMPONENT_SETUP,
  COLLATERAL_SECURITY_SPECIFICATIONS_LABELS,
  FEE_COMPONENT_LOAN_GENERAL_COMPONENT_SETUP,
  FEE_CONFIGURATION_DEPOSITE_SETUP,
  FEE_CONFIGURATION_LOAN_SETUP,
  FEE_SERVICE_CHARGE_DETAILS_DEPOSITE_SETUP,
  FEE_SERVICE_CHARGE_DETAILS_LOAN_SETUP,
  FEE_EARNING_ANALYSIS_DEPOSITE_SETUP,
  FEE_EARNING_ANALYSIS_LOAN_SETUP,
  TERM_MATURITY_CONFIGURATION,
  LOAN_INTEREST_FOUNDATIONAL_DETAILS,
  DEPOSIT_INTEREST_FOUNDATIONAL_DETAILS,
  LOAN_INTEREST_INDEX_RATE_ADJUSTMENTS,
  DEPOSIT_INTEREST_INDEX_RATE_ADJUSTMENTS,
  LOAN_INTEREST_INDEX_RATE_OFFSET,
  DEPOSIT_INTEREST_INDEX_RATE_OFFSET,
  LOAN_INTRO_FOUNDATIONAL_DETAILS,
  DEPOSIT_INTRO_FOUNDATIONAL_DETAILS,
  LOAN_INTRO_RATE_ADJUSTMENTS,
  DEPOSIT_INTRO_RATE_ADJUSTMENTS,
  LOAN_INTEREST_INTRO_RATE_OFFSET,
  DEPOSIT_INTEREST_INTRO_RATE_OFFSET,
  INTEREST_LOAN_LABELS,
  FIN_ORG_PARTY_LIMIT_ADD_LIMIT,
  FIN_ORG_PARTY_LIMIT_ACCUMULATED_TRANSACTION,
  FIN_ORG_PARTY_LIMIT_SINGLE_TRANSACTION,
  FIN_ORG_PARTY_LIMIT_GEN_LIMIT,
};
