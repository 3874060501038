"use client";

import {
  InstructorCourse,
  LearningStatusCardUserInfoWithAchivements,
  RecommendedCourses,
  SavedCourses,
  RequestClassroom,
} from "@/components";
import "./LearningProfile.scss";
import { CardContainer } from "@/components/common";
import { LearningProgress } from "./learning-progress/LearningProgress";
import { Divider, Stack } from "@chakra-ui/react";
import FooterWidgetIframe from "@/components/common/footer/footer-iframe";
import { useState } from "react";
import WelcomeBanner from "@/components/welcome-banner/WelcomeBanner";
import { SVGAcademyBannerImage } from "public/assets";
import { useUserInfo } from "@/app/providers";

const LearningProfile = ({ pageData, auth }: any) => {
  const [reloadSavedCourse, setReloadSavedCourse] = useState<boolean>(false);
  const {
    user: { firstName },
  } = useUserInfo();
  return (
    <>
      <WelcomeBanner
        name={firstName}
        isUserAuthenticated={auth}
        imageSrc={SVGAcademyBannerImage}
        bannerData={{ ...pageData, description: pageData.subhead }}
      />
      <Stack className={`academy-sub-content no-bottom-space`}>
        <CardContainer customClass="learning-status-card-container">
          <LearningStatusCardUserInfoWithAchivements />
          <Divider className="profile-divider" />
          <LearningProgress
            learningPathsOrder={pageData.learning_paths_order}
            headerData={pageData.learning_paths?.[0]}
          />
        </CardContainer>

        <RecommendedCourses
          headerData={pageData.recommended_learnings?.[0]}
          reload={reloadSavedCourse}
          setReload={setReloadSavedCourse}
        />
        <SavedCourses
          headerData={pageData.saved_by_me?.[0]}
          reload={reloadSavedCourse}
          setReload={setReloadSavedCourse}
        />
        {/* CNSL-8747: Instructor Course hidden and unused until this is feature is ready */}
        {/* <InstructorCourse headerData={pageData.instructor_sessions?.[0]} /> */}
        <RequestClassroom headerData={pageData.request_classroom?.[0]} />
      </Stack>
      <FooterWidgetIframe className="academy" />
    </>
  );
};

export default LearningProfile;
