"use client";
import { Box, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  RadioButtonGroup,
  DragDropContainer,
  ErrorAlert,
  SplitBtnInputValType,
  SplitButton,
  CoupleRadioWithDropdown,
} from "@/components/common/index";
import { useState, Fragment, useContext, useEffect, useMemo } from "react";
import {
  PAYMENT_DIRECTION_RADIO_OPTIONS,
  INTERNAL_SCHEMA_TYPE,
} from "@/components/data/form-data";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  getValidationSchema,
  repaymentPaymentDirectionChagesDetails,
  repaymentPaymentDirectionChagesProps,
} from "./RepaymentPaymentDirectionChargesValidation";
import { useFormik } from "formik";
import {
  OptionsSchema,
  PostSchema,
  RepaymentComponentData,
} from "../../../model/types";
import {
  addNewComponent,
  createRepaymentComponent,
} from "../../product-config-client-service";
import {
  getConstructedFeatureNameWithComponent,
  preventMainFormSubmitOnRJSFSubmit,
} from "@/utils/common";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { COMPONENT_CLASS, RjsfData } from "@/utils";
import { getIfxProdType } from "@/components/product-management";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { getFeeCalcDtlUiSchema } from "@finxact/finxact-shared-ui";

interface RepaymentDirectionChargeProps {
  direction: INTERNAL_SCHEMA_TYPE<string>[];
  formData?: RepaymentComponentData | null;
  repayPaymentOptionsData: PostSchema;
  feeCalcDtlOptions: string[];
  feeCalcDtlSchema: OptionsSchema;
  feeCalcDtlEndpoint: string;
}
const RepaymentPaymentDirectionCharges = (props: {
  data: RepaymentDirectionChargeProps;
}) => {
  const repaymentPaymentData = props.data?.formData;
  const toast = useToast();
  const [validationSchema, setValidationSchema] = useState<any>(null);

  const PAYMENT_DIR_FIELD_CONFIG = [
    {
      key: "Priority",
      field: "priority",
    },
    {
      key: "Past",
      field: "past",
    },
    {
      key: "Current",
      field: "current",
    },
  ] as const;

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.repayPaymentOptionsData
      );

      setValidationSchema(yupSchema);
    })();
  }, [props.data.repayPaymentOptionsData]);

  const onSubmit = async (
    values: repaymentPaymentDirectionChagesProps,
    actions: any
  ) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["repay"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    const { formData } = props.data;
    if (formData && productDetails) {
      const res = await createRepaymentComponent({
        productDetails,
        formData: {
          ...formData,
          // PATCH fields
          pmtApplMthd:
            Object.keys(values.pmtApplMthd).length > 0
              ? values.pmtApplMthd
              : null,
        },
        toastOptions: {
          toast,
          toastId: "payment-direction-and-charges",
          successMessage: `${
            tooltipFlyoutDetails?.pageHeaderDetails?.heading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
              : "Payment Direction and Charges"
          } updated`,
        },
        stageName: getWorflowStatusToBeUpdated?.() ?? "",
      });
      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };
  // using useFormik hook from Formik Library
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      pmtApplMthd:
        repaymentPaymentData?.pmtApplMthd &&
        Object.keys(repaymentPaymentData?.pmtApplMthd)?.length > 0
          ? repaymentPaymentData?.pmtApplMthd
          : repaymentPaymentDirectionChagesDetails.pmtApplMthd,

      //Helper varibale to track split buttons.
      past: repaymentPaymentData?.pmtApplMthd
        ? Object.keys(repaymentPaymentData?.pmtApplMthd).includes(
            PAYMENT_DIRECTION_RADIO_OPTIONS[1].label
          )
        : undefined,
      current: repaymentPaymentData?.pmtApplMthd
        ? Object.keys(repaymentPaymentData?.pmtApplMthd).includes(
            PAYMENT_DIRECTION_RADIO_OPTIONS[2].label
          )
        : undefined,
      priority: repaymentPaymentData?.pmtApplMthd
        ? Object.keys(repaymentPaymentData?.pmtApplMthd).includes(
            PAYMENT_DIRECTION_RADIO_OPTIONS[0].label
          )
        : undefined,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    productDetails,
    ifxAcctProductType,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  const _handleChange =
    (
      pmtApplMthdType: "Past" | "Priority" | "Current",
      key: "past" | "priority" | "current" | "dueItemOrder" | "direction"
    ) =>
    (value: string | number | string[] | SplitBtnInputValType) => {
      if (["past", "priority", "current"].includes(key)) {
        if (value) {
          setFieldValue(key, value).then((res) => {
            setFieldValue("pmtApplMthd", {
              ...values.pmtApplMthd,
              [pmtApplMthdType]: null,
            });
          });
        } else {
          delete values.pmtApplMthd[pmtApplMthdType];
          setFieldValue(key, value).then(() => {
            setFieldValue("pmtApplMthd", values.pmtApplMthd);
          });
        }
      } else {
        setFieldValue(`pmtApplMthd.${pmtApplMthdType}.${key}`, value);
      }
    };

  const feeCalcDtlOptionsSchema = useMemo(() => {
    const { actions, name } = props.data.feeCalcDtlSchema;
    return { name, ...actions.POST };
  }, [props.data.feeCalcDtlSchema]);

  const addNewFeeCalcDtl =
    (pmtApplMthdType: "Priority" | "Past" | "Current") =>
    async (rjsfData: RjsfData) => {
      const response = await addNewComponent(
        props.data.feeCalcDtlEndpoint,
        rjsfData.formData,
        toast
      );
      if (!response) {
        throw new Error("Error");
      }
      setFieldValue(`pmtApplMthd.${pmtApplMthdType}.dueItemOrder`, [
        ...(values.pmtApplMthd[pmtApplMthdType]?.dueItemOrder ?? []),
        response.name,
      ]);
    };

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id="finxact-form"
    >
      <Fragment>
        <CommonTitle
          depositName={getConstructedFeatureNameWithComponent(
            COMPONENT_CLASS["repay"],
            getIfxProdType(productDetails),
            true
          )}
          featureName={
            props?.data?.formData?.componentName
              ? props?.data?.formData?.componentName
              : ""
          }
          version={`VERSION ${props.data.formData?.version ?? "1"}`}
          mainTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.heading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
              : "Payment Direction and Charges"
          }
          subText={
            tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              : ""
          }
        />
        <CardContainer styleProps={{ width: "65%" }}>
          {PAYMENT_DIR_FIELD_CONFIG.map(({ field, key }) => (
            <>
              <Box className={"app-form-field-container"}>
                <AppFormLabel
                  labelName={`Add ${key} as a key for the new payment application method?`}
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get(
                      "paymentApplicationMethod"
                    )?.tooltip_text_main
                  }
                />

                <SplitButton
                  leftBtnName="Yes"
                  rightBtnName="No"
                  value={values[field]}
                  onSelect={_handleChange(key, field)}
                />

                {errors[field] && touched[field] && (
                  <ErrorAlert>
                    <span>{errors[field]}</span>
                  </ErrorAlert>
                )}
              </Box>
              {values[field] && (
                <>
                  <Box className="app-form-field-container">
                    <AppFormLabel
                      labelName="What is the payment direction?"
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("direction")
                          ?.tooltip_text_main
                      }
                    />
                    <RadioButtonGroup
                      value={values.pmtApplMthd[key]?.direction ?? undefined}
                      onChange={_handleChange(key, "direction")}
                      radioPropList={props.data.direction}
                      stackDirection="column"
                      spacing="1rem"
                      isBoxedRadio={true}
                    />
                    {(errors.pmtApplMthd?.[key] as any)?.direction &&
                      (touched.pmtApplMthd?.[key] as any)?.direction && (
                        <ErrorAlert>
                          <span>
                            {(errors.pmtApplMthd?.[key] as any).direction}
                          </span>
                        </ErrorAlert>
                      )}
                  </Box>
                  <Box className="app-form-field-container">
                    <AppFormLabel labelName="What order should be added?" />
                    <CoupleRadioWithDropdown
                      id="minimum_balance_fee_dropdown"
                      isMulti
                      multiselectValue={
                        values.pmtApplMthd[key]?.dueItemOrder ?? []
                      }
                      primaryRadioName="Leverage existing fee"
                      secondaryRadioName="Create new fee"
                      handlePrimaryOptionSelect={_handleChange(
                        key,
                        "dueItemOrder"
                      )}
                      placeHolderName="Select existing fees"
                      dropdownList={props.data.feeCalcDtlOptions}
                      onBlur={handleBlur}
                      schema={feeCalcDtlOptionsSchema}
                      uiSchema={getFeeCalcDtlUiSchema()}
                      onAddNewSubmit={addNewFeeCalcDtl(key)}
                      modelViewUrl={API_ROUTE_CONFIGURATION.feeDetails}
                    />
                    {(errors.pmtApplMthd?.[key] as any)?.dueItemOrder &&
                      (touched.pmtApplMthd?.[key] as any)?.dueItemOrder && (
                        <ErrorAlert>
                          <span>
                            {(errors.pmtApplMthd?.[key] as any).dueItemOrder}
                          </span>
                        </ErrorAlert>
                      )}
                  </Box>
                </>
              )}
              {values.pmtApplMthd[key]?.dueItemOrder &&
                (values.pmtApplMthd[key]?.dueItemOrder as string[]).length >
                  1 &&
                values[field] && (
                  <Box className="app-form-field-container">
                    <AppFormLabel
                      labelName="Drag the selections to prioritize the list."
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get(
                          "paymentApplicationMethod"
                        )?.tooltip_text_main
                      }
                    />
                    <DragDropContainer
                      items={values.pmtApplMthd[key]?.dueItemOrder ?? []}
                      setItems={(reorderFn: (key: string[]) => string[]) => {
                        const newOrder = reorderFn(
                          values.pmtApplMthd[key]?.dueItemOrder ?? []
                        );
                        _handleChange(key, "dueItemOrder")(newOrder);
                      }}
                      displayIndex={true}
                    />
                  </Box>
                )}
            </>
          ))}
        </CardContainer>
      </Fragment>
    </form>
  );
};

export default RepaymentPaymentDirectionCharges;
