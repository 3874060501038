"use client";

import {
  AppFormLabel,
  SelectDropdown,
  CardContainer,
  ErrorAlert,
  InputText,
  RadioButtonGroup,
  StaticText,
} from "@/components/common";

import { Box, Heading, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  SystemAccountsProps,
  systemAccountsData,
  getValidationSchema,
} from "./SystemAccountsValidation";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  createSystemAccounts,
  updateSystemAccounts,
} from "../gl-config-api-service";
import { SystemAccountsData } from "../model";

import {
  GL_CONFIG_ORDER,
  GL_ENTITY,
  GL_STEPS,
  NEW_IC_ENTITY_ID,
  getGeneralLedgerAccountGroup,
} from "@/utils";
import { ROUTE_PATH } from "@/route-config/route-path";
import { useSearchParams } from "next/navigation";
import {
  GLWorkflowResponse,
  WorkflowResponseUnion,
  createWorkflowGeneric,
  getAllWorkflowGeneric,
  updateWorkflowGeneric,
} from "@/api-config/api-service";

import Style from "./SystemAccounts.module.scss";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type AccountProps = {
  data: {
    accountGroupsOption: { label: string; value: number }[];
    formData: SystemAccountsData | null;
    systemAccountsData: SystemAccountsData[] | [];
    accountNumbersOption: { label: string; value: number }[];
    systemAccountsOptionsData: PostSchema;
  };
};

const SYSTEM_ACCOUNT_NAME_RADIO_BTN_PROPS = [
  {
    label: "Settlement",
    value: "SETTLEMENT",
    disabled: false,
  },
  {
    label: "Suspense",
    value: "SUSPENSE",
    disabled: false,
  },
];

const SYSTEM_ACCOUNT_DESC_RADIO_BTN_PROPS = [
  {
    label: "System Settlement Account",
    value: "System Settlement Account",
    disabled: false,
  },
  {
    label: "System Suspense Account",
    value: "System Suspense Account",
    disabled: false,
  },
];

const SystemAccounts = (props: AccountProps) => {
  const toast = useToast();
  const toastId = "system-accounts-toast";
  const systemAccountsDatas = props.data?.formData ?? null;
  const [workFlowData, setWorkFlowData] = useState<
    WorkflowResponseUnion[] | null
  >(null);

  const { systemAccountsOptionsData } = props.data;

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    checkFormStatus,
    navigateTo,
    entityWorkflowData,
    getCurrentStageStatusName,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const params = useSearchParams();

  const moveToNextStep = () => {
    if (params?.get("initialFlow")) {
      navigateTo(
        `${ROUTE_PATH.GL_INTERSTITIAL_SCREEN}?entityId=${values.acctName}&entityType=${GL_ENTITY.system_accounts}&initialFlow=true`
      );
    } else {
      navigateTo(
        `${ROUTE_PATH.GL_COMPLETION_SCREEN}?entityId=${values.acctName}&entityType=${GL_ENTITY.system_accounts}`
      );
    }
  };

  // Form Submit Handler
  const onSubmit = async (values: SystemAccountsProps) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required

      if (workFlowData?.some((data) => data.modelKey === values.acctName)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
          key: systemAccountsDatas
            ? systemAccountsDatas.acctName
            : props.data.systemAccountsData[0].acctName,
          stage: GL_ENTITY.system_accounts,
          status: GL_STEPS.system_accounts,
        });
      } else {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
          key: systemAccountsDatas
            ? systemAccountsDatas.acctName
            : props.data.systemAccountsData[0].acctName,
          stage: GL_ENTITY.system_accounts,
          status: GL_STEPS.system_accounts,
        });
      }

      moveToNextStep?.();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId,
    };
    if (!systemAccountsDatas && props.data.systemAccountsData.length < 2) {
      response = await createSystemAccounts({
        formData: {
          acctGroup: values.acctGroup,
          acctNbr: values.acctNbr,
          acctName: values.acctName,
          desc: values.desc,
          posnAcctNbr: values.posnAcctNbr,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `GL system account created.`,
        },
        stageName: GL_STEPS.system_accounts,
      });
    } else {
      response = await updateSystemAccounts({
        formData: {
          _vn: systemAccountsDatas
            ? systemAccountsDatas._vn
            : props.data.systemAccountsData[0]._vn,
          acctGroup: values.acctGroup,
          acctNbr: values.acctNbr,
          acctName: values.acctName,
          desc: values.desc,
          posnAcctNbr: values.posnAcctNbr,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `${configPageTitle} updated.`,
        },

        stageName: GL_STEPS.system_accounts,
        updateWorkFlow: workFlowData?.some(
          (data) => data.modelKey === values.acctName
        )
          ? true
          : false,
      });
    }
    if (response) {
      moveToNextStep();
    }
  };

  const handleOnChange = (key: string) => (value: Date | string | number) => {
    if (key === "acctName" && value === "SETTLEMENT") {
      setFieldValue(key, value).then((res) => {
        setFieldValue("desc", "System Settlement Account");
      });
    } else if (key === "acctName" && value === "SUSPENSE") {
      setFieldValue(key, value).then((res) => {
        setFieldValue("desc", "System Suspense Account");
      });
    } else if (key === "desc" && value === "System Settlement Account") {
      setFieldValue(key, value).then((res) => {
        setFieldValue("acctName", "SETTLEMENT");
      });
    } else if (key === "desc" && value === "System Suspense Account") {
      setFieldValue(key, value).then((res) => {
        setFieldValue("acctName", "SUSPENSE");
      });
    } else if (key === "acctGroup") {
      setFieldValue(key, value).then((res) => {
        setFieldValue("acctNbr", "");
      });
    } else {
      setFieldValue(key, value);
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(systemAccountsOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [systemAccountsOptionsData]);

  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      acctGroup:
        props.data.systemAccountsData.length === 2 && !params?.get("entityType")
          ? props.data.systemAccountsData[0].acctGroup
          : systemAccountsDatas?.acctGroup
            ? systemAccountsDatas.acctGroup
            : systemAccountsDatas?.acctName
              ? 0
              : getGeneralLedgerAccountGroup(
                  props.data.accountGroupsOption,
                  systemAccountsData.acctGroup
                ),
      acctName:
        props.data.systemAccountsData.length === 2 && !params?.get("entityType")
          ? "SETTLEMENT"
          : systemAccountsDatas?.acctName ?? systemAccountsData.acctName,
      desc:
        props.data.systemAccountsData.length === 2 && !params?.get("entityType")
          ? "System Settlement Account"
          : systemAccountsDatas?.desc ?? systemAccountsData.desc,
      acctNbr:
        props.data.systemAccountsData.length === 2 && !params?.get("entityType")
          ? props.data.systemAccountsData[0].acctNbr
          : systemAccountsDatas?.acctNbr ?? systemAccountsData.acctNbr,
      posnAcctNbr:
        props.data.systemAccountsData.length === 2 && !params?.get("entityType")
          ? props.data.systemAccountsData[0].posnAcctNbr
          : systemAccountsDatas?.posnAcctNbr ?? systemAccountsData.posnAcctNbr,
    },
  });
  const { values, errors, handleSubmit, dirty, touched, setFieldValue } =
    formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
      checkFormStatus?.(false);
    }
    // Find the correct entityId from workflow data for going back
    if (isGoingBack) {
      const backEntityId = entityWorkflowData?.find(
        (entity) => entity.stage === GL_ENTITY.system_accounts
      )?.modelKey;
      navigateTo(
        `${ROUTE_PATH.GL_ACCOUNTS}?entityId=${backEntityId ?? NEW_IC_ENTITY_ID}`
      );
    }
  }, [canCheckFormStatus, isGoingBack]);

  useEffect(() => {
    if (systemAccountsDatas?.acctName) {
      SYSTEM_ACCOUNT_NAME_RADIO_BTN_PROPS.forEach((obj) => {
        if (obj.value !== systemAccountsDatas?.acctName) {
          obj.disabled = true;
        }
      });
    }

    if (systemAccountsDatas?.desc) {
      SYSTEM_ACCOUNT_DESC_RADIO_BTN_PROPS.forEach((obj) => {
        if (obj.value !== systemAccountsDatas?.desc) {
          obj.disabled = true;
        }
      });
    }

    if (props.data.systemAccountsData.length === 2) {
      SYSTEM_ACCOUNT_NAME_RADIO_BTN_PROPS.forEach((obj) => {
        if (obj.value !== "SETTLEMENT") {
          obj.disabled = true;
        }
      });
      SYSTEM_ACCOUNT_DESC_RADIO_BTN_PROPS.forEach((obj) => {
        if (obj.value !== "System Settlement Account") {
          obj.disabled = true;
        }
      });
    }

    (async () => {
      // Get all GL workflows to determine what page to display
      const [response] = await Promise.all([
        getAllWorkflowGeneric(API_ROUTE_CONFIGURATION.generalLedgerConfig),
      ]);
      // Sort before storing
      response.sort(
        (entityA: GLWorkflowResponse, entityB: GLWorkflowResponse) => {
          return (
            GL_CONFIG_ORDER[entityA.stage] - GL_CONFIG_ORDER[entityB.stage]
          );
        }
      );
      setWorkFlowData(response);
    })();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} id="finxact-form" noValidate>
      <CardContainer customClass="app-form-container app-form-field-container">
        <Box className="app-form-field-container">
          <Heading
            className={Style["app-form-field-container-system-account"]}
            as="h3"
          >
            General account information
          </Heading>
          <StaticText
            textValue={
              "Establish key account information to create the system account"
            }
          />
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the account group?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("acctGroup")
                ?.tooltip_text_main
            }
            labelFor="account-group"
          />
          <SelectDropdown
            value={values.acctGroup}
            onChange={handleOnChange("acctGroup")}
            dropdownList={props.data.accountGroupsOption}
            id="account-group"
          />
          {errors.acctGroup && touched.acctGroup && (
            <ErrorAlert>
              <span>{errors.acctGroup}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the account number?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("acctNbr")
                ?.tooltip_text_main
            }
            labelFor="account_number"
          />
          <SelectDropdown
            value={values.acctNbr}
            onChange={handleOnChange("acctNbr")}
            dropdownList={props.data.accountNumbersOption.filter(
              (acc) => parseInt(acc.label) === values.acctGroup
            )}
            id="account_number"
            withDescription
          />
          {touched.acctNbr && errors.acctNbr && (
            <ErrorAlert>
              <span>{errors.acctNbr}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
      <CardContainer customClass="app-form-container app-form-field-container">
        <Box className="app-form-field-container">
          <Heading
            as="h3"
            className={Style["app-form-field-container-system-account"]}
          >
            System account settings
          </Heading>
          <StaticText
            textValue={"Establish identifier attributes for the system account"}
          />
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the system account name?"
            isRequired
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("acctName")
                ?.tooltip_text_main
            }
            labelFor="acc_name_input"
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.acctName}
            onChange={handleOnChange("acctName")}
            radioPropList={SYSTEM_ACCOUNT_NAME_RADIO_BTN_PROPS}
            stackDirection={"row"}
            spacing={12}
          />
          {touched.acctName && errors.acctName && (
            <ErrorAlert>
              <span>{errors.acctName}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the system account description?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("desc")?.tooltip_text_main
            }
            labelFor="acc_desc_input"
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.desc}
            onChange={handleOnChange("desc")}
            radioPropList={SYSTEM_ACCOUNT_DESC_RADIO_BTN_PROPS}
            stackDirection={"row"}
            spacing={12}
          />
          {touched.desc && errors.desc && (
            <ErrorAlert>
              <span>{errors.desc}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the position account number?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("posnAcctNbr")
                ?.tooltip_text_main
            }
          />
          <InputText
            value={values.posnAcctNbr}
            onChange={handleOnChange("posnAcctNbr")}
            label="position account number"
          />
          {touched.posnAcctNbr && errors.posnAcctNbr && (
            <ErrorAlert>
              <span>{errors.posnAcctNbr}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default SystemAccounts;
