import React, { createContext, useContext, useState, useEffect } from "react";
import { MultiTieredSideMenuDataModel } from "../../components/common/MultiTieredSideMenu/MultiTieredSideMenuDataModel";
import { docCenterSidemenuIconHome } from "public/assets";
import { getDocumentCenterNavigationItems } from "@/prismic-api/prismic-api-service";
import { checkIfRestricted } from "@/utils/common";
import { useUserAuthenticated } from "@/app/providers";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import { usePathname } from "next/navigation";
import { useSelector } from "react-redux";
import { getAppPrismicClient } from "@/store";

type NavigationDataContextType = {
  docCenterNavigationItems: any[];
  breadCrumbs: any[];
  loading: boolean;
};

const defaultNavigationDataContextVal: NavigationDataContextType = {
  docCenterNavigationItems: [],
  breadCrumbs: [],
  loading: true,
};

// Create a context
const NavigationDataContext = createContext(defaultNavigationDataContextVal);

// Create a provider component
export const NavigationDataProvider = ({ children }: any) => {
  const prismicClient = useSelector(getAppPrismicClient);

  const [data, setData] = useState(defaultNavigationDataContextVal);
  const isAuthenticated = useUserAuthenticated();
  const pathName = usePathname()?.replace(/\/$/, "");

  // Fetch data and set it
  useEffect(() => {
    const breadCrumbs: any = [];
    const buildNavigationItems = (
      topicData: any,
      title: string,
      prevDocBreadCrumb: string
    ) => {
      // console.log(topicData[0].data.topics[17].link.link_type);
      const topicToBuild = topicData.find((td: any) => td.uid === title);

      let navItems: MultiTieredSideMenuDataModel[] = [];
      if (title === "main")
        navItems.push({
          type: "item",
          title: "Home",
          childrens: [],
          path: ROUTE_BASE_URL.DOCUMENT_CENTER,
          isSelected: true,
          uid: ROUTE_BASE_URL.DOCUMENT_CENTER,
        });
      if (topicToBuild && topicToBuild.data.topics) {
        for (let topic of topicToBuild.data.topics) {
          let restricted = false;
          if (!isAuthenticated && checkIfRestricted(topic.document?.tags)) {
            restricted = true;
          }
          if (!restricted) {
            if (topic.child.uid === "test-navigation") {
              continue;
            }

            const title = topic.title[0].text;
            const defaultUID = title.toLowerCase().replaceAll(" ", "-");

            const isWebLink = topic.link.link_type === "Web";

            const docBreadCrumb = topic.child.uid
              ? `${prevDocBreadCrumb},${topic.title[0].text}`
              : prevDocBreadCrumb;

            let navItem: MultiTieredSideMenuDataModel = {
              title: title,
              uid: topic.document.uid ?? defaultUID,
              childrens: topic.child.uid
                ? buildNavigationItems(
                    topicData,
                    topic.child.uid,
                    docBreadCrumb
                  )
                : [],
              path: isWebLink
                ? topic.link.url
                : topic.document.uid
                  ? `${ROUTE_PATH["DOCUMENT_DETAIL"]}/${topic.document.type}/${topic.document.uid}`
                  : "",
              type: "item",
            };
            navItems.push(navItem);

            if (!breadCrumbs[topic.document.type]) {
              breadCrumbs[topic.document.type] = [];
            }
            breadCrumbs[topic.document.type][topic.document.uid] =
              docBreadCrumb;
          }
        }
      }
      return navItems;
    };
    getDocumentCenterNavigationItems(prismicClient).then((response) => {
      if (response) {
        const topObj: MultiTieredSideMenuDataModel = {
          type: "none",
          title: "",
          path: "",
          isSelected: false,
          childrens: [...buildNavigationItems(response, "main", "Docs")],
          uid: "",
        };
        setData({
          docCenterNavigationItems: [topObj],
          breadCrumbs: breadCrumbs,
          loading: false,
        });
      }
    });
  }, [isAuthenticated]);

  return (
    <NavigationDataContext.Provider value={data}>
      {children}
    </NavigationDataContext.Provider>
  );
};

// Custom hook to consume the data
export const useData = () => useContext(NavigationDataContext);
