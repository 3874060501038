"use client";
import { Box, Button, Flex, Text } from "@/components/ChakraUiManager";
import {
  CardContainer,
  CustomPrismicRichText,
  CustomTable,
  SplitBtnInputValType,
  SplitButton,
} from "@/components/common";
import "./ApiCallTab.scss";
import { useEffect, useState, useContext } from "react";
import Link from "next/link";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { ApiCallsProps } from "../ProductReview";
import axios from "axios";
interface ApisDetailsProps {
  postman_upload: any;
  api_content_items: { api_name: string; description: string; item: any }[];
}
const ApiCall = (props: { data: ApiCallsProps | null | undefined }) => {
  const { data } = props;
  const [retailDetails, setRetailDetails] = useState<ApisDetailsProps | null>(
    null
  );
  const [commercialDetails, setCommercialDetails] =
    useState<ApisDetailsProps | null>(null);
  const { ifxAcctProductType } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const [productCatalogType, setProductCatalogType] =
    useState<SplitBtnInputValType>(true);
  const [downloadApiCollection, setDownloadApiCollection] = useState<{
    url: string | null;
    fileName: string | null;
  }>({ url: null, fileName: null });
  
  async function jsonDownloadHandler(url: string, fileName: string) {
    await axios({
      url: url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  useEffect(() => {
    const details: any = data;
    if (!retailDetails) {
      setRetailDetails(
        ifxAcctProductType === "Loan"
          ? details?.retailLoan.data
          : ifxAcctProductType === "Deposit"
            ? details?.retailDeposit.data
            : null
      );
      setDownloadApiCollection(
        ifxAcctProductType === "Loan"
          ? {
              url: details?.retailLoan?.data?.postman_upload?.url,
              fileName: details?.retailLoan?.data?.postman_upload?.name,
            }
          : ifxAcctProductType === "Deposit"
            ? {
                url: details?.retailDeposit?.data?.postman_upload?.url,
                fileName: details?.retailDeposit?.data?.postman_upload?.name,
              }
            : { url: null, fileName: null }
      );
    }
    if (!commercialDetails) {
      setCommercialDetails(
        ifxAcctProductType === "Loan"
          ? details?.commercialLoan.data
          : ifxAcctProductType === "Deposit"
            ? details?.commercialDeposit.data
            : null
      );
    }
  }, [data, ifxAcctProductType]);

 
  interface DataProps {
    [key: string]: any; // You can define the specific structure of your data here
  }
  
  type GenerateLinkType = (data: DataProps) => string;

  const generateLink: GenerateLinkType = (data: DataProps): string => {

    let fullLink = '';
    let uidLink = '';

    if (data.item.uid) {
      uidLink = data.item.uid;
      fullLink = `/apis/detail-page/api_library/${uidLink}`
    } else { 
      fullLink = '#'
    }

    return fullLink;
  };

  const handleProductCatalogTypeSelect = (
    selectedValue: SplitBtnInputValType
  ) => {
    setProductCatalogType(selectedValue);
    if (selectedValue) {
      setDownloadApiCollection({
        url: retailDetails?.postman_upload?.url,
        fileName: retailDetails?.postman_upload?.name,
      });
    } else {
      setDownloadApiCollection({
        url: commercialDetails?.postman_upload?.url,
        fileName: commercialDetails?.postman_upload?.name,
      });
    }
  };
  const tableHeaderConfigList = [
    {
      header: "API name",
      accessorKey: "api_name",
      enableSorting: false,
    },
    {
      header: "Description",
      accessorKey: "description",
      enableSorting: false,
    }
  ];

  const renderDataCell = (value: any, key: string) => {
    return value;
  };
  return (
    <Box className="api-call-tab">
      <Box mb="3rem">
        <Text as="h3" className="explore-title">
          {data?.heading}
        </Text>
        <Text className="apis-description">
          <CustomPrismicRichText field={data?.description} />
        </Text>
      </Box>
      <CardContainer>
        <Flex className="tab-download">
          <SplitButton
            leftBtnName={`Retail${ifxAcctProductType? " - "+ifxAcctProductType : ""}`}
            rightBtnName={`Commercial${ifxAcctProductType? " - "+ifxAcctProductType : ""}`}
            onSelect={handleProductCatalogTypeSelect}
            value={productCatalogType}
          />

          {downloadApiCollection.url && (
            <Button
              className="app-btn-reg-secondary"
              onClick={() =>
                downloadApiCollection.url &&
                downloadApiCollection.fileName &&
                jsonDownloadHandler(
                  downloadApiCollection.url,
                  downloadApiCollection.fileName
                )
              }
            >
              Download API collection
            </Button>
          )}
        </Flex>
        <Box mt="3.2rem" className="configured-products-list">
          <Box
            className={`${productCatalogType === false ? "display-none" : ""}`}
          >

            <CustomTable
              data={retailDetails?.api_content_items || []}
              columns={tableHeaderConfigList}
              renderDataCell={renderDataCell}
              className={`recent-product-table api-calls`}
              isPagination={false}
              getLink={generateLink}
            />
          </Box>
          <Box
            className={`${productCatalogType === true ? "display-none" : ""}`}
          >
            <CustomTable
              data={commercialDetails?.api_content_items || []}
              columns={tableHeaderConfigList}
              renderDataCell={renderDataCell}
              className={`recent-product-table api-calls`}
              isPagination={false}
              getLink={generateLink}
            />
          </Box>
        </Box>
      </CardContainer>
    </Box>
  );
};

export default ApiCall;
