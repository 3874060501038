"use client";
import { FormikErrors, FormikTouched } from "formik";
import {
  AppFormLabel,
  AppNumberInput,
  SelectDropdown,
  CoupleRadioWithDropdown,
  DatePicker,
  ErrorAlert,
  InputText,
  RadioButtonGroup,
  SplitButton,
  TimeField,
} from "@/components/common";
import {
  Box,
  Divider,
  Flex,
  Heading,
  Stack,
} from "@/components/ChakraUiManager";
import { LimitConfigProps } from "./AddLimitValidation";
import {
  getFeeCalcDtlUiSchema,
  trnStatGroupUiSchema,
  feeCalcDtlUnRequire,
} from "@finxact/finxact-shared-ui";
import { useContext } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Option } from "@/utils";

interface AccumulatedTransactionLimitFormProp {
  touched: FormikTouched<{ accumTrnLimits: LimitConfigProps[] }>;
  errors: FormikErrors<{ accumTrnLimits: LimitConfigProps[] }>;
  limit: LimitConfigProps;
  index: any;
  definedByOptions: Option<string>[];
  accumulationGroupOptions: string[];
  violationActionOptions: Option<string>[];
  violationFeeOptions: string[];
  transactionCodes: Option<string>[];
  showLimitType: boolean;
  handleOnChange: (key: string, index: number) => (value: any) => void;
  isTempFlow?: boolean;
  transactionschema: any;
  addNewTransactionOption: any;
  schema: any;
  addNewViolationOption: any;
  accumTrnLimit: any;
}

export const AccumTransRadioBtnProps = [
  {
    label: "Leverage existing accumulated transaction limit",
    value: "leverage_existing",
  },
  {
    label: "Add new accumulated transaction limit",
    value: "add_new",
  },
];

export default function AccumulatedTransactionLimitForm(
  props: AccumulatedTransactionLimitFormProp
) {
  const {
    index,
    limit,
    touched,
    errors,
    definedByOptions,
    accumulationGroupOptions,
    violationActionOptions,
    violationFeeOptions,
    transactionCodes,
    showLimitType = false,
    handleOnChange,
    isTempFlow = false,
    transactionschema,
    addNewTransactionOption,
    accumTrnLimit,
    schema,
    addNewViolationOption,
  } = props;

  const { tooltipFlyoutDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );

  return (
    <>
      {showLimitType ? (
        <Box className="add-limit-bottom-spacing">
          <AppFormLabel
            labelName="How would you like to establish this limit?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("accumFee")
                ?.tooltip_text_main
            }
            labelFor="acc_group_input"
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={limit.selectLimitType}
            onChange={handleOnChange("selectLimitType", index)}
            radioPropList={AccumTransRadioBtnProps}
            stackDirection={"row"}
            spacing={12}
          />
          {touched.accumTrnLimits &&
            touched.accumTrnLimits[index]?.selectLimitType &&
            errors.accumTrnLimits &&
            (errors.accumTrnLimits[index] as any)?.selectLimitType && (
              <ErrorAlert>
                <span>
                  {(errors.accumTrnLimits[index] as any)?.selectLimitType}
                </span>
              </ErrorAlert>
            )}
        </Box>
      ) : (
        ""
      )}
      {showLimitType && limit.selectLimitType === "leverage_existing" ? (
        <Flex direction={"row"}>
          <Divider
            orientation="vertical"
            className="dashed-left-border"
            height={28}
          />
          <Box className="add-limit-accumulated-width">
            <AppFormLabel
              labelName="What is the unique accumulated limit identifier?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("_Id")?.tooltip_text_main
              }
              labelFor="limit_id"
            />
            <SelectDropdown
              value={limit._Id}
              onChange={handleOnChange("_Id", index)}
              dropdownList={accumTrnLimit}
              id="limit_id"
            />
            {touched.accumTrnLimits &&
              touched.accumTrnLimits[index]?._Id &&
              errors.accumTrnLimits &&
              (errors.accumTrnLimits[index] as any)?._Id && (
                <ErrorAlert>
                  <span>{(errors.accumTrnLimits[index] as any)?._Id}</span>
                </ErrorAlert>
              )}
          </Box>
        </Flex>
      ) : limit?.selectLimitType === "add_new" || limit?.needTempLimits ? (
        <Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the limit name?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("accumName")
                  ?.tooltip_text_main
              }
            />
            <InputText
              value={limit.name}
              onChange={handleOnChange("name", index)}
              placeholder="Enter"
            />
            {touched.accumTrnLimits &&
              touched.accumTrnLimits[index]?.name &&
              errors.accumTrnLimits &&
              (errors.accumTrnLimits[index] as any)?.name && (
                <ErrorAlert>
                  <span>{(errors.accumTrnLimits[index] as any)?.name}</span>
                </ErrorAlert>
              )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="Who is the limit defined by?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("accumDefinedBy")
                  ?.tooltip_text_main
              }
            />

            <RadioButtonGroup
              value={limit.definedBy}
              onChange={handleOnChange("definedBy", index)}
              isBoxedRadio={true}
              radioPropList={definedByOptions}
              stackDirection="column"
              spacing={4}
            />
            {touched.accumTrnLimits &&
              touched.accumTrnLimits[index]?.definedBy &&
              errors.accumTrnLimits &&
              (errors.accumTrnLimits[index] as any)?.definedBy && (
                <ErrorAlert>
                  <span>
                    {(errors.accumTrnLimits[index] as any)?.definedBy}
                  </span>
                </ErrorAlert>
              )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the accumulation group?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("statGroup")
                  ?.tooltip_text_main
              }
              labelFor="acc_group"
            />
            <CoupleRadioWithDropdown
              primaryRadioName="Leverage existing accumulation group"
              secondaryRadioName="Add new accumulation group"
              placeHolderName="Select"
              primaryOptionValue={limit.statGroup}
              handlePrimaryOptionSelect={handleOnChange("statGroup", index)}
              dropdownList={accumulationGroupOptions}
              id="acc_group"
              schema={transactionschema}
              uiSchema={trnStatGroupUiSchema}
              onAddNewSubmit={addNewTransactionOption(index)}
            />
            {touched.accumTrnLimits &&
              touched.accumTrnLimits[index]?.statGroup &&
              errors.accumTrnLimits &&
              (errors.accumTrnLimits[index] as any)?.statGroup && (
                <ErrorAlert>
                  <span>
                    {(errors.accumTrnLimits[index] as any)?.statGroup}
                  </span>
                </ErrorAlert>
              )}
          </Box>
          {isTempFlow ? (
            <>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName={"When does this limit expire?"}
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("expDtm")
                      ?.tooltip_text_main
                  }
                />
                <Stack direction="row" spacing={"var(--chakra-space-14)"}>
                  <Box>
                    <AppFormLabel labelName="Date" labelFor="expDtm" />
                    <DatePicker
                      value={limit.expDtm as any}
                      onUpdate={handleOnChange("expDtm", index)}
                      placeholder="Select"
                      id="expDtm"
                      ariaLabel="expDtm"
                    />
                    {touched.accumTrnLimits &&
                      touched.accumTrnLimits[index]?.expDtm &&
                      errors.accumTrnLimits &&
                      (errors.accumTrnLimits[index] as any)?.expDtm && (
                        <ErrorAlert>
                          <span>
                            {(errors.accumTrnLimits[index] as any)?.expDtm}
                          </span>
                        </ErrorAlert>
                      )}
                  </Box>
                  <Box className="add-limit-time-field-width">
                    <AppFormLabel labelName="Time" />
                    <TimeField
                      ariaLabel="expTime"
                      value={limit.expTime}
                      onChange={handleOnChange("expTime", index)}
                    />
                    {touched.accumTrnLimits &&
                      touched.accumTrnLimits[index]?.expTime &&
                      errors.accumTrnLimits &&
                      (errors.accumTrnLimits[index] as any)?.expTime && (
                        <ErrorAlert>
                          <span>
                            {(errors.accumTrnLimits[index] as any)?.expTime}
                          </span>
                        </ErrorAlert>
                      )}
                  </Box>
                </Stack>
              </Box>
            </>
          ) : (
            ""
          )}
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the maximum credit amount for the period?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("crAmt")
                  ?.tooltip_text_main
              }
            />
            <AppNumberInput
              value={limit.crAmt || ""}
              onChange={handleOnChange("crAmt", index)}
              placeholder="Enter"
            />
            {touched.accumTrnLimits &&
              touched.accumTrnLimits[index]?.crAmt &&
              errors.accumTrnLimits &&
              (errors.accumTrnLimits[index] as any)?.crAmt && (
                <ErrorAlert>
                  <span>{(errors.accumTrnLimits[index] as any)?.crAmt}</span>
                </ErrorAlert>
              )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the maximum credit count for the period?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("crCnt")
                  ?.tooltip_text_main
              }
            />
            <AppNumberInput
              value={limit.crCnt || ""}
              onChange={handleOnChange("crCnt", index)}
              placeholder="Enter"
            />
            {touched.accumTrnLimits &&
              touched.accumTrnLimits[index]?.crCnt &&
              errors.accumTrnLimits &&
              (errors.accumTrnLimits[index] as any)?.crCnt && (
                <ErrorAlert>
                  <span>{(errors.accumTrnLimits[index] as any)?.crCnt}</span>
                </ErrorAlert>
              )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the maximum debit amount for the period?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("drAmt")
                  ?.tooltip_text_main
              }
            />
            <AppNumberInput
              value={limit.drAmt || ""}
              onChange={handleOnChange("drAmt", index)}
              placeholder="Enter"
            />
            {touched.accumTrnLimits &&
              touched.accumTrnLimits[index]?.drAmt &&
              errors.accumTrnLimits &&
              (errors.accumTrnLimits[index] as any)?.drAmt && (
                <ErrorAlert>
                  <span>{(errors.accumTrnLimits[index] as any)?.drAmt}</span>
                </ErrorAlert>
              )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the maximum debit count for the period?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("drCnt")
                  ?.tooltip_text_main
              }
            />
            <AppNumberInput
              value={limit.drCnt || ""}
              onChange={handleOnChange("drCnt", index)}
              placeholder="Enter"
            />
            {touched.accumTrnLimits &&
              touched.accumTrnLimits[index]?.drCnt &&
              errors.accumTrnLimits &&
              (errors.accumTrnLimits[index] as any)?.drCnt && (
                <ErrorAlert>
                  <span>{(errors.accumTrnLimits[index] as any)?.drCnt}</span>
                </ErrorAlert>
              )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the maximum total amount for the period?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("totAmt")
                  ?.tooltip_text_main
              }
            />
            <AppNumberInput
              value={limit.totAmt || ""}
              onChange={handleOnChange("totAmt", index)}
              placeholder="Enter"
            />
            {touched.accumTrnLimits &&
              touched.accumTrnLimits[index]?.totAmt &&
              errors.accumTrnLimits &&
              (errors.accumTrnLimits[index] as any)?.totAmt && (
                <ErrorAlert>
                  <span>{(errors.accumTrnLimits[index] as any)?.totAmt}</span>
                </ErrorAlert>
              )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the maximum total transaction count for the period?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("totCnt")
                  ?.tooltip_text_main
              }
            />
            <AppNumberInput
              value={limit.totCnt || ""}
              onChange={handleOnChange("totCnt", index)}
              placeholder="Enter"
            />
            {touched.accumTrnLimits &&
              touched.accumTrnLimits[index]?.totCnt &&
              errors.accumTrnLimits &&
              (errors.accumTrnLimits[index] as any)?.totCnt && (
                <ErrorAlert>
                  <span>{(errors.accumTrnLimits[index] as any)?.totCnt}</span>
                </ErrorAlert>
              )}
          </Box>
          {isTempFlow ? (
            <>
              <Heading className="font-size-18px" mb={6} as="h6">
                Violation action
              </Heading>
              <Box mb={6}>
                Outline protocols for alerting, actions upon violations, and fee
                structures for minimum balance breaches
              </Box>
            </>
          ) : (
            ""
          )}
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="If violated, what is the violation action?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("violationAct")
                  ?.tooltip_text_main
              }
              labelFor="violation_action_input"
            />
            <RadioButtonGroup
              isBoxedRadio={true}
              value={limit.violationAct}
              onChange={handleOnChange("violationAct", index)}
              radioPropList={violationActionOptions}
              stackDirection={"column"}
              spacing={6}
            />
            {touched.accumTrnLimits &&
              touched.accumTrnLimits[index]?.violationAct &&
              errors.accumTrnLimits &&
              (errors.accumTrnLimits[index] as any)?.violationAct && (
                <ErrorAlert>
                  <span>
                    {(errors.accumTrnLimits[index] as any)?.violationAct}
                  </span>
                </ErrorAlert>
              )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="Do you want to charge a fee for violating the accumulated transaction limit?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("feeViolated")
                  ?.tooltip_text_main
              }
            />
            <SplitButton
              value={limit.feeViolated}
              onSelect={handleOnChange("feeViolated", index)}
              leftBtnName="Yes"
              rightBtnName="No"
            />
            {touched.accumTrnLimits &&
              touched.accumTrnLimits[index]?.feeViolated &&
              errors.accumTrnLimits &&
              (errors.accumTrnLimits[index] as any)?.feeViolated && (
                <ErrorAlert>
                  <span>
                    {(errors.accumTrnLimits[index] as any)?.feeViolated}
                  </span>
                </ErrorAlert>
              )}
          </Box>

          {limit.feeViolated && (
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the fee for a violation?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("violationFee")
                    ?.tooltip_text_main
                }
                labelFor="violation_input"
              />
              <CoupleRadioWithDropdown
                primaryRadioName="Use existing violation fee"
                secondaryRadioName="Set new violation fee"
                primaryOptionValue={limit.violationFee}
                handlePrimaryOptionSelect={handleOnChange(
                  "violationFee",
                  index
                )}
                placeHolderName="Select"
                dropdownList={violationFeeOptions}
                id="violation"
                schema={schema}
                uiSchema={getFeeCalcDtlUiSchema()}
                unRequire={feeCalcDtlUnRequire}
                onAddNewSubmit={addNewViolationOption(index)}
              />
              {touched.accumTrnLimits &&
                touched.accumTrnLimits[index]?.violationFee &&
                errors.accumTrnLimits &&
                (errors.accumTrnLimits[index] as any)?.violationFee && (
                  <ErrorAlert>
                    <span>
                      <span>
                        {(errors.accumTrnLimits[index] as any)?.violationFee}
                      </span>
                    </span>
                  </ErrorAlert>
                )}
            </Box>
          )}
        </Box>
      ) : null}
    </>
  );
}
