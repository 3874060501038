"use client";
import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  ModalSelector,
  SplitButton,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Box, Heading, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { getValidationSchema } from "./TaxSpecificationsValidation";
import { ROUTE_PATH } from "@/route-config/route-path";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import { FinancialInstitutionData } from "@/components/institutional-configuration/model";
import { shouldUpdateWorkflowStatus, useQueryParams } from "@/utils/common";
import { FIN_ORG_SUB_STEPS, IC_ENTITY, Option } from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    formData: FinancialInstitutionData;
    taxExemptOptions: Option<string>[];
    partyOrgFininstOptionsData: PostSchema;
  };
};

const TaxSpecifications = ({ data }: Props) => {
  const toast = useToast();
  const toastId = "general-organization-tax-specification";
  const updateQueryParams = useQueryParams();
  const { partyOrgFininstOptionsData } = data;

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateTo(
        `${
          ROUTE_PATH["IC_GENERAL_ORGANIZATION_PARTY_SPECIFICATION"]
        }?${updateQueryParams()}`
      );
    }
    return;
  };

  const onSubmit = async (values: any, actions: any) => {
    const currentStage = FIN_ORG_SUB_STEPS.gen_org_party_tax_spec;
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: data.formData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      moveToNextStep();
      return;
    }

    const commonToastOptions = {
      toast,
      toastId,
    };
    const response = await updateFinOrg({
      formData: {
        _vn: data.formData._vn,
        _Id: data.formData._Id,
        name: data.formData.name,
        isTaxExempt: values.isTaxExempt ?? null,
        taxExemptType: values.isTaxExempt ? values.taxExemptType : null,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      moveToNextStep();
      return;
    }
  };

  const handleOnChange = (key: string) => (value: any | any[]) => {
    setFieldValue(key, value);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(partyOrgFininstOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [partyOrgFininstOptionsData]);

  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      isTaxExempt: data?.formData?.isTaxExempt ?? undefined,
      taxExemptType: data?.formData?.taxExemptType ?? "",
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);
  useEffect(() => {
    if (isGoingBack) {
      navigateTo(
        `${
          ROUTE_PATH["IC_GENERAL_ORGANIZATION_PARTY_IDENTIFICATION"]
        }?${updateQueryParams()}`
      );
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} noValidate id="finxact-form">
      <CardContainer customClass="app-form-container">
        <Heading className="app-form-field-container">
          Tax specifications
        </Heading>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Tax exempt?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isTaxExempt")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            name="isTaxExempt"
            onSelect={handleOnChange("isTaxExempt")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isTaxExempt}
            onBlur={handleBlur}
          />
          {errors.isTaxExempt && touched.isTaxExempt && (
            <ErrorAlert>
              <span>{errors.isTaxExempt}</span>
            </ErrorAlert>
          )}
        </Box>
        {values.isTaxExempt && (
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="What is the tax exempt type?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("taxExemptType")
                  ?.tooltip_text_main
              }
            />
            <ModalSelector
              linkName="+ Select Tax Exempt Type"
              modalTitle="Select Tax Exempt Type"
              optionList={data.taxExemptOptions}
              isClearable
              onChange={handleOnChange("taxExemptType")}
              value={values.taxExemptType}
              showSelection="value - description"
            />
            {errors.taxExemptType && touched.taxExemptType && (
              <ErrorAlert>
                <span>{errors.taxExemptType}</span>
              </ErrorAlert>
            )}
          </Box>
        )}
      </CardContainer>
    </form>
  );
};

export default TaxSpecifications;
