"use client";

import {
  AccordionComponent,
  AddNewButton,
  AppFormLabel,
  SelectDropdown,
  CardContainer,
  ErrorAlert,
  InputText,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { QUERY_PARAM_KEY } from "@/route-config/route-path";
import { FieldArray, FormikErrors, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { DefaultFormDetails } from "@/components/institutional-configuration/model";

import {
  SIDEBAR_STATUS,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS,
  TC_STAGE_STATUS,
  WORKFLOW_STATUS,
  generateYupSchema,
} from "@/utils";
import {
  FastDefaultDropdownProps,
  PostSchema,
} from "@/components/product-management/model/types";
import { Box, useToast } from "@/components/ChakraUiManager";

import {
  constructTcEntryPayload,
  getEntryStageStatus,
  updateTransCode,
} from "../../transaction-code-api-service";
import { TransCodeResponse } from "../../models";
import { useSearchParams } from "next/navigation";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";

interface TcStasticalExprProp {
  data: {
    formData: TransCodeResponse | null;
    transactionStatGroupOptions: FastDefaultDropdownProps[];
    tranCodeOptionsSchema: PostSchema;
  };
}

interface TcStasticalExpressionFormProp {
  idx: number | string;
  statGroup: string;
  expr: string;
}

const initialFormValues: TcStasticalExpressionFormProp = {
  idx: "",
  statGroup: "",
  expr: "",
};

interface stasticalEntries {
  statGroupsExpr: TcStasticalExpressionFormProp[];
}

const stasticalEntriesValues: stasticalEntries = {
  statGroupsExpr: [],
};

export default function StatisticalExpressionEntries(
  props: TcStasticalExprProp
) {
  const {
    data: { formData, transactionStatGroupOptions, tranCodeOptionsSchema },
  } = props;

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    entityId,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    entityWorkflowData,
    sideBarSubMenuIndex,
    tooltipFlyoutDetails,
    configPageTitle
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const toast = useToast();
  const toastId = "tc-statistical-expression-entries";
  const queryParams = useSearchParams();
  const currEntriesIndex =
    queryParams?.get(QUERY_PARAM_KEY.TC_ENTRY_INDEX) || 0;
  const entrySetupStatus = entityWorkflowData?.filter(
    (wfd) =>
      wfd.stage === TC_STAGE_STATUS.entry_setup &&
      wfd.status === WORKFLOW_STATUS.completed
  )?.[0]?.status;

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateToNextPage?.();
    }
  };

  const onSubmit = async (values: stasticalEntries, actions: any) => {
    if (!dirty) {
      updateWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.tcConfig,
        key: formData?.trnCode!,
        stage: TC_STAGE_STATUS.entry_setup,
        status:
          entityId && !entityWorkflowData?.length
            ? ""
            : getEntryStageStatus(
                TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_statistical_group,
                entityWorkflowData
              ),
      });
      moveToNextStep();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };
    let constructedVal = {
      statGroupsExpr: values.statGroupsExpr?.map(({ statGroup, expr }) => ({
        expr: expr,
        statGroup: statGroup,
      })),
    };
    let payload = constructTcEntryPayload(
      formData!,
      constructedVal,
      Number(currEntriesIndex)
    );
    const response = await updateTransCode({
      formData: {
        ...payload,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: TC_STAGE_STATUS.entry_setup,
      stageStatus:
        entityId && !entityWorkflowData?.length
          ? ""
          : entrySetupStatus
            ? entrySetupStatus
            : getEntryStageStatus(
                TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_statistical_group,
                entityWorkflowData
              ),
    });
    if (response) {
      moveToNextStep();
    }
  };

  const savedCurrEntries = formData?.entries?.[currEntriesIndex as any];
  let constructedFormValues = {
    trnCode: savedCurrEntries?.trnCode,
    ...stasticalEntriesValues,
  };

  if (savedCurrEntries?.statGroupsExpr) {
    constructedFormValues = {
      ...savedCurrEntries,
      trnCode: savedCurrEntries.trnCode,
      statGroupsExpr: savedCurrEntries?.statGroupsExpr?.map((entry: any) => ({
        idx: Math.random(),
        expr: entry.expr,
        statGroup: entry?.statGroup,
      })),
    };
  }

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const fields = ["entries"];
      const yupSchema = await generateYupSchema(fields, tranCodeOptionsSchema);
      setValidationSchema(yupSchema.entries.innerType);
    })();
  }, [tranCodeOptionsSchema]);

  //formik configs
  const formikConfigs = useFormik({
    onSubmit,
    initialValues: constructedFormValues,
    validationSchema,
  });

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = formikConfigs;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const handleOnChange = (key: string, index: number) => (value: any) => {
    setFieldValue(`statGroupsExpr[${index}].${key}`, value);
  };

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  useEffect(() => {
    if (
      sideBarSubMenuIndex ===
      TC_ENTRY_SETUP_SUB_STEPS_STAGE_ORDER.entry_statistical_group
    ) {
      updateFormStatus?.(SIDEBAR_STATUS.in_progress);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.in_progress);
    }
  }, [sideBarSubMenuIndex]);

  const _renderError = (
    key: keyof TcStasticalExpressionFormProp,
    index: number
  ) => {
    const error = errors.statGroupsExpr?.[index] as
      | FormikErrors<TcStasticalExpressionFormProp>
      | undefined;
    return (
      error?.[key] &&
      touched.statGroupsExpr?.[index]?.[key] && (
        <ErrorAlert>
          <span>{error[key] as string}</span>
        </ErrorAlert>
      )
    );
  };

  return (
    <FormikProvider value={formikConfigs}>
      <form onSubmit={handleSubmit} noValidate id="finxact-form">
        <Box className="app-form-container">
          <FieldArray
            name="statGroupsExpr"
            render={({ remove, push }) => (
              <Box>
                {values?.statGroupsExpr.map((stastic, index) => (
                  <CardContainer key={`stasticsContainer${stastic.idx}`}>
                    <AccordionComponent
                      key={`stasticsAccContainer${index}`}
                      accordionTitle={`Statistical expression ${index + 1}`}
                      deleteIcon
                      onDeleteHandler={() => remove(index)}
                      isExpand={
                        values.statGroupsExpr.length !== index + 1 ? 1 : 0
                      }
                    >
                      <Box className="app-form-field-container">
                        <AppFormLabel
                          labelName="Select the statistical group"
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get("statGroup")
                              ?.tooltip_text_main
                          }
                          labelFor="statistical_groups_dropdown"
                        />
                        <SelectDropdown
                          id="statistical_groups_dropdown"
                          value={stastic.statGroup}
                          onChange={handleOnChange("statGroup", index)}
                          dropdownList={transactionStatGroupOptions}
                        />
                        {_renderError("statGroup", index)}
                      </Box>
                      <Box className="app-form-field-container">
                        <AppFormLabel
                          labelName="What is the statistical expression?"
                          tooltipDesc={
                            tooltipFlyoutDetails?.tooltipsMap?.get("expr")
                              ?.tooltip_text_main
                          }
                        />
                        <InputText
                          value={stastic?.expr}
                          onChange={handleOnChange("expr", index)}
                          onBlur={handleBlur}
                          label="What is the statistical expression?"
                          name="expr"
                        />
                        {_renderError("expr", index)}
                      </Box>
                    </AccordionComponent>
                  </CardContainer>
                ))}
                <Box className="add-limit-add-new-button">
                  <AddNewButton
                    label={
                      !values.statGroupsExpr?.length
                        ? "Add statistical group expression entry"
                        : "Add another statistical group expression entry"
                    }
                    onClick={() => {
                      push({
                        ...initialFormValues,
                        idx: Math.random(),
                      });
                    }}
                  />
                </Box>
              </Box>
            )}
          ></FieldArray>
        </Box>
      </form>
    </FormikProvider>
  );
}
