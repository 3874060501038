"use client";

import { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Text,
} from "@/components/ChakraUiManager";
import { AppPortal, CardContainer, ProductTitle } from "../common";
import styles from "./InterstialScreen.module.scss";
import Image from "next/image";
import { completeInterstialTopIcon, rightRotateProgress } from "public/assets";
import TitleInterstialScreen from "./title-interstial-screen/TitleInterstialScreen";
import StepperInterstial from "./stepper-interstial/StepperInterstial";
import { useRouter, useSearchParams } from "next/navigation";
import {
  WorkflowResponseUnion,
  createWorkflowGeneric,
  updateWorkflowGeneric,
  TcWorkflowResponse,
  getWorkflowGeneric,
  getAllWorkflowGeneric,
} from "@/api-config/api-service";
import {
  CRT_CONFIG_ORDER,
  DASHBOARD_MODEL_KEY,
  DASHBOARD_ACCORDION_STATUS,
  FOUNDATIONAL_SETUP_STEPS_NAME,
  GL_CONFIG_ORDER,
  IC_CONFIG_ORDER,
  WORKFLOW_STATUS,
  TC_STAGE_ORDER,
  GENERAL_LEDGER,
  TC_CONFIG_ORDER,
  IC_COMPONENT_NAMES,
  GL_COMPONENT_NAMES,
  useQueryParams,
  TRANSCODE_FLOW_NAME_ROUTE_MAP,
  useEntityId,
} from "@/utils";
import {
  FLOW_CONTINUE_BUTTON_TEXT_MAPPING,
  FLOW_TITLE_MAPPING,
} from "@/data/interstital-screen-data";
import {
  QUERY_PARAM_KEY,
  ROUTE_BASE_URL,
  ROUTE_PATH,
} from "@/route-config/route-path";
import { greenStatusDot as Dot } from "public/assets";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "../context-api/product-configuration-context/ProductConfigurationReducer";
type InterstialScreenProps = {
  steps: any;
  flow:
    | "institutional-configuration"
    | "general-ledger"
    | "transaction-code"
    | "customer-relationship-types";
  exitLink: string;
};

const configOrderMapping: Record<string, any> = {
  "institutional-configuration": IC_CONFIG_ORDER,
  "general-ledger": GL_CONFIG_ORDER,
  "transaction-code": TC_CONFIG_ORDER,
  "customer-relationship-types": CRT_CONFIG_ORDER,
};
const getAllWorkflowModelMapping = {
  "institutional-configuration": API_ROUTE_CONFIGURATION.institutionalConfig,
  "general-ledger": API_ROUTE_CONFIGURATION.generalLedgerConfig,
  "transaction-code": API_ROUTE_CONFIGURATION.tcConfig,
  "customer-relationship-types": API_ROUTE_CONFIGURATION.crtConfig,
};

const InterstialScreens = ({
  steps,
  flow,
  exitLink,
}: InterstialScreenProps) => {
  const [workflowStatus, setWorflowStatus] = useState<WorkflowResponseUnion[]>(
    []
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const [isIcCompleted, setIsIcCompleted] = useState(false);
  const [isGlCompleted, setIsGlCompleted] = useState(false);
  const [isTranCodeCompleted, setIsTranCodeCompleted] = useState(false);
  const [isCrtCompleted, setIsCrtCompleted] = useState(false);
  const router = useRouter();
  const entityId = useEntityId();
  const updateQueryParams = useQueryParams();
  const queryParam = useSearchParams();

  //context API
  const { updateSidebarMenu } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );

  useEffect(() => {
    (async () => {
      if (flow === "transaction-code") {
        getWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.tcConfig,
          key: entityId,
        }).then((tcWorkflow) => {
          if (!tcWorkflow.length) {
            setActiveIndex(3);
          } else {
            setWorflowStatus(tcWorkflow);
            tcWorkflow.forEach((entity: TcWorkflowResponse) => {
              if (entity.status.includes(WORKFLOW_STATUS.completed)) {
                setActiveIndex((prev) =>
                  configOrderMapping[flow][entity.stage] > prev
                    ? configOrderMapping[flow][entity.stage]
                    : prev
                );
              }
            });
          }
        });
      } else if (flow === "customer-relationship-types") {
        const res = await getWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.crtConfig,
          key: entityId,
        });
        setWorflowStatus(res);
        res.forEach((entity: any) => {
          if (entity.status.includes(WORKFLOW_STATUS.completed)) {
            setActiveIndex((prev) =>
              configOrderMapping[flow][entity.stage] > prev
                ? configOrderMapping[flow][entity.stage]
                : prev
            );
          }
        });
      } else {
        const res = await getAllWorkflowGeneric(
          getAllWorkflowModelMapping[flow]
        );
        setWorflowStatus(res);
        res.forEach((entity: any) => {
          if (entity.status.includes(WORKFLOW_STATUS.completed)) {
            setActiveIndex((prev) =>
              configOrderMapping[flow][entity.stage] > prev
                ? configOrderMapping[flow][entity.stage]
                : prev
            );
          }
        });
      }

      const data = await getWorkflowGeneric({
        model: API_ROUTE_CONFIGURATION.dashboard,
        key: DASHBOARD_MODEL_KEY.foundationalSetup,
      });
      if (
        data.filter(
          (data: any) =>
            data.stage ===
              FOUNDATIONAL_SETUP_STEPS_NAME.institutionalConfiguration &&
            data.status === DASHBOARD_ACCORDION_STATUS.inProgress
        ).length > 0
      ) {
        setIsIcCompleted(true);
      } else {
        setIsIcCompleted(false);
      }

      if (
        data.filter(
          (data: any) =>
            data.stage === FOUNDATIONAL_SETUP_STEPS_NAME.generalLedger &&
            data.status === DASHBOARD_ACCORDION_STATUS.inProgress
        ).length > 0
      ) {
        setIsGlCompleted(true);
      } else {
        setIsGlCompleted(false);
      }
      if (
        data.filter(
          (data: any) =>
            data.stage ===
              FOUNDATIONAL_SETUP_STEPS_NAME.customerRelationshipTypes &&
            data.status === DASHBOARD_ACCORDION_STATUS.inProgress
        ).length > 0
      ) {
        setIsCrtCompleted(true);
      } else {
        setIsCrtCompleted(false);
      }

      if (
        data.filter(
          (data: any) =>
            data.stage === FOUNDATIONAL_SETUP_STEPS_NAME.transactionCodes &&
            data.status === DASHBOARD_ACCORDION_STATUS.inProgress
        ).length > 0
      ) {
        setIsTranCodeCompleted(true);
      } else {
        setIsTranCodeCompleted(false);
      }
    })();
  }, []);

  const activeItemKey = useMemo(() => {
    const activeItem = workflowStatus?.find(
      (workflow) => configOrderMapping[flow][workflow.stage] === activeIndex
    );
    return activeItem?.modelKey;
  }, [activeIndex, workflowStatus, flow]);

  const totalSteps = steps.length;

  const descriptionText = useMemo(() => {
    if (activeIndex === 0) {
      return `You are about to start ${steps[activeIndex].title}`;
    } else if (activeIndex === totalSteps) {
      return `Nice work! You’ve completed all the steps to set up ${FLOW_TITLE_MAPPING[flow]?.toLowerCase()}`;
    } else {
      return ` Nice work! You’ve completed ${steps[activeIndex - 1].title?.toLowerCase()}.`;
    }
  }, [activeIndex, flow, steps, totalSteps]);

  const changeWorkFlow = async (currentStep: string, nextStep: string) => {
    if (nextStep === "") {
      const [stepUpdateStatus] = await Promise.all([
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.dashboard,
          key: DASHBOARD_MODEL_KEY.foundationalSetup,
          stage: currentStep,
          status: DASHBOARD_ACCORDION_STATUS.completed,
        }),
      ]).then((data) => data);
    } else {
      const [stepUpdateStatus, nextStepUpdateStatus] = await Promise.all([
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.dashboard,
          key: DASHBOARD_MODEL_KEY.foundationalSetup,
          stage: currentStep,
          status: DASHBOARD_ACCORDION_STATUS.completed,
        }),

        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.dashboard,
          key: DASHBOARD_MODEL_KEY.foundationalSetup,
          stage: nextStep,
          status: DASHBOARD_ACCORDION_STATUS.inProgress,
        }),
      ]).then((data) => data);
    }
  };

  //Constructing config page url as per queryparams
  function continueToConfigFlow() {
    const flowName = queryParam?.get(QUERY_PARAM_KEY.FLOW_NAME);
    let flowRoute = flowName ? TRANSCODE_FLOW_NAME_ROUTE_MAP[flowName] : "";
    //setting base path as dashboard if it doesn't exist
    if (!flowRoute.includes(ROUTE_BASE_URL.DASHBOARD_PATH)) {
      flowRoute = `${ROUTE_BASE_URL.DASHBOARD_PATH}${flowRoute}`;
    }
    if (flowRoute) {
      //constructing field name and tran code in param to preselect the target dropdown
      const defaultParam = `&${QUERY_PARAM_KEY.TC_ID}=${queryParam?.get(QUERY_PARAM_KEY.ENTITY_ID)}&${QUERY_PARAM_KEY.FIELD_NAME}=${queryParam?.get(QUERY_PARAM_KEY.FIELD_NAME)}`;
      if (queryParam?.get(QUERY_PARAM_KEY.PRODUCT_NAME_KEY)) {
        //route to product config flow
        router.push(
          `${flowRoute}${ROUTE_PATH.PRODUCT_NAME_QUERY}${queryParam?.get(QUERY_PARAM_KEY.PRODUCT_NAME_KEY)}${defaultParam}`
        );
      } else if (queryParam?.get(QUERY_PARAM_KEY.CONFIG_ID)) {
        //route to IC flow
        const entityType = queryParam?.get(QUERY_PARAM_KEY.ENTITY_TYPE);
        const entityTypeParam = entityType
          ? `&${QUERY_PARAM_KEY.ENTITY_TYPE}=${entityType}`
          : "";
        router.push(
          `${flowRoute}${ROUTE_PATH.ENTITY_ID_QUERY}${queryParam?.get(QUERY_PARAM_KEY.CONFIG_ID)}${entityTypeParam}${defaultParam}`
        );
      }
    }
  }

  function isTcToConfigRouteParamPresent() {
    if (
      queryParam?.get(QUERY_PARAM_KEY.PRODUCT_NAME_KEY) ||
      queryParam?.get(QUERY_PARAM_KEY.CONFIG_ID)
    ) {
      return true;
    }
    return false;
  }

  function onSaveAndExitClick() {
    if (
      flow === "transaction-code" &&
      queryParam?.get(QUERY_PARAM_KEY.FLOW_NAME)
    ) {
      continueToConfigFlow();
    } else {
      if (flow === "institutional-configuration") {
        updateSidebarMenu?.([]);
      }
      router.push(exitLink);
    }
  }

  useEffect(() => {
    switch (true) {
      //If all IC steps are completed then change status in dashboard
      case flow === "institutional-configuration" &&
        isIcCompleted &&
        activeIndex === totalSteps:
        changeWorkFlow(
          FOUNDATIONAL_SETUP_STEPS_NAME.institutionalConfiguration,
          FOUNDATIONAL_SETUP_STEPS_NAME.generalLedger
        );
        break;
      //If all GL steps are completed then change status in dashboard
      case flow === "general-ledger" &&
        isGlCompleted &&
        activeIndex === totalSteps:
        changeWorkFlow(
          FOUNDATIONAL_SETUP_STEPS_NAME.generalLedger,
          FOUNDATIONAL_SETUP_STEPS_NAME.transactionCodes
        );
        break;
      //If all TC steps are completed then change status in dashboard
      case flow === "transaction-code" &&
        isTranCodeCompleted &&
        activeIndex === totalSteps:
        changeWorkFlow(
          FOUNDATIONAL_SETUP_STEPS_NAME.transactionCodes,
          FOUNDATIONAL_SETUP_STEPS_NAME.networks
        );
        break;
      //If all CRT steps are completed then change status in dashboard
      case flow === "customer-relationship-types" &&
        isCrtCompleted &&
        activeIndex === totalSteps:
        changeWorkFlow(
          FOUNDATIONAL_SETUP_STEPS_NAME.customerRelationshipTypes,
          ""
        );
        break;
    }
  }, [
    activeIndex,
    isIcCompleted,
    isGlCompleted,
    isTranCodeCompleted,
    isCrtCompleted,
  ]);

  return (
    <>
      <ProductTitle
        title={FLOW_TITLE_MAPPING[flow]}
        align="off-left"
        isFullScreen={true}
      />
      <Flex gap={1} mt={60}>
        <Box flex="0 0 15%" p={1}>
          <Box mt="300px">
            <Image src={Dot} alt="dot-left" className={styles["dot-image"]} />
          </Box>
        </Box>

        <Box flex="0 0 70%" p={1}>
          <Box className={styles["box-interstial"]}>
            <Box className={styles["image-interstial-screen"]}>
              <Text
                className={`${styles["top-heading-styling-interstial"]} ${activeIndex === totalSteps ? styles["top-heading-styling-interstial-completed"] : ""}`}
              >
                {FLOW_TITLE_MAPPING[flow] === GENERAL_LEDGER.glName
                  ? GENERAL_LEDGER.glUpperCaseName
                  : FLOW_TITLE_MAPPING[flow]}
              </Text>
              {activeIndex === totalSteps ? (
                <Image
                  src={completeInterstialTopIcon}
                  alt={"Right rotate image"}
                />
              ) : (
                <Image src={rightRotateProgress} alt={"Right rotate image"} />
              )}
            </Box>
            <CardContainer
              customClass={styles["intertitial-card-container"]}
              styleProps={{ pb: 40, mt: 0 }}
            >
              <Box className={styles["box-content-interstial"]}>
                <TitleInterstialScreen
                  activeIndex={activeIndex}
                  totalCount={totalSteps}
                  descriptionText={descriptionText}
                  flow={flow}
                />
                <StepperInterstial
                  steps={steps}
                  activeIndex={activeIndex}
                  totalCount={totalSteps}
                />
              </Box>
            </CardContainer>
            <AppPortal domId="#appFooter">
              <Box className={styles["interstial-screen-footer"]}>
                <Button className="app-btn-link" onClick={onSaveAndExitClick}>
                  Save & Exit
                </Button>
                <ButtonGroup
                  className={styles["interstial-screen-btn-container"]}
                >
                  <Button
                    mr="1.5rem"
                    className="app-btn-reg-secondary-transparent"
                    onClick={
                      flow === "institutional-configuration"
                        ? () => {
                            router.back();
                          }
                        : flow === "transaction-code"
                          ? activeIndex !== totalSteps
                            ? () => {
                                router.back();
                              }
                            : () => {
                                router.push(
                                  `${ROUTE_PATH.TC_TRANSACTION_ENTRY_SUMMARY}${updateQueryParams() ? `?${updateQueryParams()}` : ""}`
                                );
                              }
                          : flow === "general-ledger" &&
                              activeIndex !== totalSteps
                            ? () => {
                                router.push(
                                  `${steps[activeIndex].back}${updateQueryParams() ? `?${updateQueryParams()}` : ""}`
                                );
                              }
                            : flow === "general-ledger" &&
                                activeIndex === totalSteps
                              ? () => {
                                  router.push(
                                    `${ROUTE_PATH["GL_SETS"]}${updateQueryParams() ? `?${updateQueryParams()}` : `?entityId=${activeItemKey}`}`
                                  );
                                }
                              : flow === "customer-relationship-types" &&
                                  activeIndex !== totalSteps
                                ? () => {
                                    router.push(
                                      `${steps[activeIndex].back}${updateQueryParams() ? `?${updateQueryParams()}` : ""}`
                                    );
                                  }
                                : flow === "customer-relationship-types" &&
                                    activeIndex === totalSteps
                                  ? () => {
                                      router.push(
                                        `${ROUTE_PATH.CRT_PARTY_RELATIONSHIPS}?entityId=${activeItemKey}&entityType=completed`
                                      );
                                    }
                                  : () => {
                                      router.back();
                                    }
                    }
                  >
                    Back
                  </Button>
                  {activeIndex === totalSteps &&
                  flow === "transaction-code" &&
                  isTcToConfigRouteParamPresent() ? (
                    <Button
                      className="app-btn-inverse-secondary-add-new"
                      onClick={() => {
                        continueToConfigFlow();
                      }}
                    >
                      Return to configuration
                    </Button>
                  ) : (
                    <Button
                      className="app-btn-inverse-secondary-add-new"
                      onClick={() => {
                        if (
                          activeIndex === totalSteps &&
                          flow === "institutional-configuration"
                        ) {
                          router.push(ROUTE_BASE_URL["GENERAL_LEDGER"]);
                        } else if (
                          activeIndex === totalSteps &&
                          flow === "general-ledger"
                        ) {
                          router.push(ROUTE_BASE_URL["TRANS_CODE"]);
                        } else if (
                          activeIndex === totalSteps &&
                          flow === "transaction-code"
                        ) {
                          router.push(`${ROUTE_PATH.TC_FOUNDATIONAL_DETAILS}`);
                        } else if (
                          activeIndex === totalSteps &&
                          flow === "customer-relationship-types"
                        ) {
                          router.push(ROUTE_PATH.PRODUCT_LAUNCHPAD);
                        } else {
                          if (flow === "institutional-configuration") {
                            updateSidebarMenu?.([]);
                            router.push(
                              `${steps[activeIndex].continue}&entityType=${IC_COMPONENT_NAMES[activeIndex]}&initialFlow=true`
                            );
                          } else if (flow === "general-ledger") {
                            router.push(
                              `${steps[activeIndex].continue}&entityType=${GL_COMPONENT_NAMES[activeIndex]}&initialFlow=true`
                            );
                          }
                          // same Primary key for both the steps in CRT
                          else if (flow === "customer-relationship-types") {
                            router.push(
                              `${steps[activeIndex].continue}?entityId=${activeItemKey}`
                            );
                          } else if (flow === "transaction-code") {
                            router.push(
                              `${steps[activeIndex].continue}${updateQueryParams() ? `?${updateQueryParams()}` : ""}`
                            );
                          } else {
                            router.push(`${steps[activeIndex].continue}`);
                          }
                        }
                      }}
                    >
                      {activeIndex === totalSteps &&
                      FLOW_CONTINUE_BUTTON_TEXT_MAPPING?.[flow]
                        ? `${FLOW_CONTINUE_BUTTON_TEXT_MAPPING[flow]}`
                        : "Continue"}
                    </Button>
                  )}
                </ButtonGroup>
              </Box>
            </AppPortal>
          </Box>
        </Box>
        <Box flex="0 0 15%" p={1}>
          <Box mt="300px">
            <Image src={Dot} alt="dot-right" className={styles["dot-image"]} />
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default InterstialScreens;
