"use client";
import { Box, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  CommonTitle,
  CoupleRadioWithDropdown,
  DurationInput,
  ErrorAlert,
  FormFieldMsg,
  SplitButton,
} from "@/components/common";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { INTEREST_DEPOSIT_LABELS } from "@/components/data/component-config-label";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  IndexRateFoundationProps,
  indexRateFoundationDetails,
  getValidationSchema,
} from "./IndexRateFoundationValidation";
import { useFormik } from "formik";
import {
  addNewComponent,
  createInterestComponent,
} from "../../product-config-client-service";
import {
  InterestComponentData,
  OptionsSchema,
  PostSchema,
} from "../../../model/types";
import {
  COMPONENT_CLASS,
  IFX_PRODUCT_TYPE_VAL,
  MAIN_FORM_ID,
  RjsfData,
  SIDEBAR_STATUS,
  preventMainFormSubmitOnRJSFSubmit,
  getConstructedFeatureNameWithComponent,
} from "@/utils";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import { matrixTypeUiSchema } from "@finxact/finxact-shared-ui";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  depositName: string;
  featureName: string;
  version: string;
  mainTitle: string;
  subText: string;
  data: {
    indexNameOptions: string[];
    formData: InterestComponentData | null;
    addNewSchema: OptionsSchema;
    addNewOptionEndpoint: string;
    interestComponentOptionsData: PostSchema;
  };
};

export default function IndexedRateFoundationalDetails(props: Props) {
  const { featureName, mainTitle, subText } = props;
  const {
    formData,
    addNewSchema,
    addNewOptionEndpoint,
    interestComponentOptionsData,
  } = props.data;
  const {
    INDEXED_RATE_DETAILS,
    WHAT_IS_INDEX_NAME,
    WHAT_IS_INDEX_NOMINAL_RATE,
    LEVERAGE_EXISTING_INDEX,
    ADD_NEW_INDEX,
    SELECT_INDEX,
    YES,
    NO,
    SKIP_SECTION_INTEREST_DEPOSIT_FORM_MSG,
  } = INTEREST_DEPOSIT_LABELS;

  const toast = useToast();

  // Form Submit Handler
  const onSubmit = async (values: IndexRateFoundationProps, actions: any) => {
    // Update only if component data exists and form has been touched
    /**
     * If "No" selected for first question in "Indexed Rate - Foundational Details", when continue is clicked go to "Promotional Rate - Foundational Details" (in Interest (deposit))
     * or to "Introduction Rate - Foundational Details" (in Interest (loan)) page
     * */
    if (!dirty && !values.addindexRateDetails) {
      if (getIfxProdType(productDetails) === IFX_PRODUCT_TYPE_VAL.loan) {
        updateStatusInSideBarMenuList?.("COMPLETED");
        const upcomingSubMenuIndex = sideBarMenuList?.[
          sideBarMainMenuIndex
        ]?.subMenuList?.findIndex(
          (item) => item.stageStatus === "promotionalRateFoundationDetails"
        );
        setSideBarCurrentIndex?.(
          sideBarMainMenuIndex,
          upcomingSubMenuIndex ?? 0
        );
        updateTheStatusOfSkippedForm();
        navigateTo(
          `${ROUTE_BASE_URL["DASHBOARD_PATH"]}/${ROUTE_PATH["INTEREST_LOAN_PROMOTIONAL_FOUND_DETAIL_FORM"]}?productName=${productDetails?.name}`
        );

        getWorflowStatusToBeUpdated?.() &&
          updateWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.product,
            key: productDetails?.name ?? "",
            stage: COMPONENT_CLASS["interest"],
            status: getWorflowStatusToBeUpdated?.() ?? "",
          });
      } else {
        updateStatusInSideBarMenuList?.("COMPLETED");
        const upcomingSubMenuIndex = sideBarMenuList?.[
          sideBarMainMenuIndex
        ]?.subMenuList?.findIndex(
          (item) => item.stageStatus === "promotionalRateFoundationDetails"
        );
        setSideBarCurrentIndex?.(
          sideBarMainMenuIndex,
          upcomingSubMenuIndex ?? 0
        );
        updateTheStatusOfSkippedForm();
        navigateTo(
          `${ROUTE_BASE_URL["DASHBOARD_PATH"]}/${ROUTE_PATH["INTEREST_PROMOTIONAL_FOUND_DETAIL_FORM"]}?productName=${productDetails?.name}`
        );
      }
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["interest"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    } else if (!dirty) {
      navigateToNextPage?.();
      updateTheStatusOfSkippedForm();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["interest"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData && dirty) {
      if (productDetails) {
        const res = await createInterestComponent({
          productDetails,
          formData: {
            ...formData,
            // PATCH fields
            index: values.addindexRateDetails
              ? {
                  ...formData.index,
                  indexName: values.index.indexName ?? "",
                  firstDur: values.index.firstDur ?? "",
                }
              : null,
            nomRate: parseFloat(values.nomRate),
            adjTerm: "",
          },
          toastOptions: {
            toast,
            toastId: "index-rate-foundational-detail",
            successMessage: `${
              tooltipFlyoutDetails?.pageHeaderDetails?.heading
                ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
                : mainTitle
            } updated`,
          },
          stageName: getUpdatedStageStatus() ?? "",
        });
        //If "No" selected for first question in "Indexed Rate - Foundational Details" when back is clicked go to "Promotional Rate - Foundational Details" (in Interest (deposit)) or "Introduction Rate - Foundational Details" (in Interest (loan))  page
        if (res && !values.addindexRateDetails) {
          if (getIfxProdType(productDetails) === IFX_PRODUCT_TYPE_VAL.loan) {
            updateStatusInSideBarMenuList?.("COMPLETED");
            const upcomingSubMenuIndex = sideBarMenuList?.[
              sideBarMainMenuIndex
            ]?.subMenuList?.findIndex(
              (item) => item.stageStatus === "promotionalRateFoundationDetails"
            );
            setSideBarCurrentIndex?.(
              sideBarMainMenuIndex,
              upcomingSubMenuIndex ?? 0
            );
            updateTheStatusOfSkippedForm();
            navigateTo(
              `${ROUTE_BASE_URL["DASHBOARD_PATH"]}/${ROUTE_PATH["INTEREST_LOAN_PROMOTIONAL_FOUND_DETAIL_FORM"]}?productName=${productDetails.name}`
            );
          } else {
            updateStatusInSideBarMenuList?.("COMPLETED");
            const upcomingSubMenuIndex = sideBarMenuList?.[
              sideBarMainMenuIndex
            ]?.subMenuList?.findIndex(
              (item) => item.stageStatus === "promotionalRateFoundationDetails"
            );
            setSideBarCurrentIndex?.(
              sideBarMainMenuIndex,
              upcomingSubMenuIndex ?? 0
            );
            updateTheStatusOfSkippedForm();
            navigateTo(
              `${ROUTE_BASE_URL["DASHBOARD_PATH"]}/${ROUTE_PATH["INTEREST_PROMOTIONAL_FOUND_DETAIL_FORM"]}?productName=${productDetails.name}`
            );
          }
          getExistingProduct?.(productDetails.name, false);
        } else if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  // Schema to pass into RJSF (Add New flow)
  const schema = useMemo(() => {
    const { actions, name } = addNewSchema;
    return { name, ...actions.POST };
  }, [addNewSchema]);

  const addNewOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("index", { ...values.index, indexName: response.matrixName });
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(interestComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [interestComponentOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      addindexRateDetails: formData?.index
        ? true
        : formData?.nomRate
          ? false
          : undefined,
      index: {
        indexName:
          formData?.index?.indexName ??
          indexRateFoundationDetails.index.indexName,
        firstDur:
          formData?.index?.firstDur ??
          indexRateFoundationDetails.index.firstDur,
      },
      nomRate:
        formData?.nomRate?.toString() ?? indexRateFoundationDetails.nomRate,
    },
  });

  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
    setTouched,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    getCurrentStageStatusName,
    sideBarSubMenuIndex,
    sideBarMenuList,
    sideBarMainMenuIndex,
    setSideBarCurrentIndex,
    getWorflowStatusToBeUpdated,
    updateSidebarMenu,
    getCurrentWorkflowIndex,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  function getUpdatedStageStatus() {
    if (
      sideBarMainMenuIndex >= 0 &&
      !values?.addindexRateDetails &&
      sideBarMenuList?.length &&
      sideBarMenuList[sideBarMainMenuIndex]?.subMenuList?.length
    ) {
      const workflowIndex = getCurrentWorkflowIndex?.();
      const index = sideBarMenuList[
        sideBarMainMenuIndex
      ].subMenuList?.findIndex((item) => {
        return getIfxProdType(productDetails) === IFX_PRODUCT_TYPE_VAL.loan
          ? item.href ===
              `${ROUTE_PATH.INTEREST_LOAN_PROMOTIONAL_FOUND_DETAIL_FORM}`
          : item.href ===
              `${ROUTE_PATH.INTEREST_PROMOTIONAL_FOUND_DETAIL_FORM}`;
      });
      if (
        index !== undefined &&
        index >= 0 &&
        workflowIndex !== undefined &&
        workflowIndex! >= 0
      ) {
        return workflowIndex! < index
          ? sideBarMenuList[sideBarMainMenuIndex].subMenuList![index - 1]!
              .stageStatus
          : getWorflowStatusToBeUpdated?.();
      }
    }
    return getWorflowStatusToBeUpdated?.() ?? "";
  }

  function updateTheStatusOfSkippedForm() {
    if (
      sideBarMainMenuIndex >= 0 &&
      !values?.addindexRateDetails &&
      sideBarMenuList?.length &&
      sideBarMenuList[sideBarMainMenuIndex]?.subMenuList?.length
    ) {
      const index = sideBarMenuList[
        sideBarMainMenuIndex
      ].subMenuList?.findIndex((item) => {
        return getIfxProdType(productDetails) === IFX_PRODUCT_TYPE_VAL.loan
          ? item.href ===
              `${ROUTE_PATH.INTEREST_LOAN_PROMOTIONAL_FOUND_DETAIL_FORM}`
          : item.href ===
              `${ROUTE_PATH.INTEREST_PROMOTIONAL_FOUND_DETAIL_FORM}`;
      });
      if (index !== undefined && index >= 0) {
        let updatedMainMenuList = sideBarMenuList.map((mainMenu, idx) => {
          if (idx === sideBarMainMenuIndex) {
            let updatedSubmenu = mainMenu?.subMenuList?.map(
              (subMenu, subIdx) => {
                if (
                  sideBarSubMenuIndex &&
                  subIdx < index &&
                  subIdx > sideBarSubMenuIndex
                ) {
                  return {
                    ...subMenu,
                    status: SIDEBAR_STATUS.completed,
                  };
                }
                return subMenu;
              }
            );
            return {
              ...mainMenu,
              subMenuList: updatedSubmenu,
            };
          }
          return mainMenu;
        });
        updateSidebarMenu?.(updatedMainMenuList);
      }
    }
  }

  //Handling Form
  const indexedRateFoundationDetailsFormHandler =
    (key: string) => (value: string | SplitBtnInputValType | string[]) => {
      if (key === "addindexRateDetails") {
        setFieldValue(key, value).then((res) => {
          setFieldValue("index", {
            ...indexRateFoundationDetails.index,
          });
          setFieldValue("nomRate", "");
        });
      } else {
        setFieldValue("index", { ...values.index, [key]: value });
      }
    };
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form
      noValidate
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id={MAIN_FORM_ID}
    >
      <Box className="app-form-container">
        <CommonTitle
          depositName={getConstructedFeatureNameWithComponent(
            "componentInt",
            getIfxProdType(productDetails),
            true
          )}
          featureName={formData?.componentName ?? featureName}
          version={`VERSION ${formData?.version ?? "1"}`}
          mainTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.heading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
              : mainTitle
          }
          subText={
            tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              : subText
          }
        />
        <CardContainer>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={INDEXED_RATE_DETAILS}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("addindexRateDetails")
                  ?.tooltip_text_main
              }
            />
            <SplitButton
              leftBtnName={YES}
              rightBtnName={NO}
              value={values.addindexRateDetails}
              onSelect={indexedRateFoundationDetailsFormHandler(
                "addindexRateDetails"
              )}
              onBlur={handleBlur}
            />
            {errors.addindexRateDetails && (
              <ErrorAlert>
                <span>{errors.addindexRateDetails}</span>
              </ErrorAlert>
            )}
            {values.addindexRateDetails === false ? (
              <FormFieldMsg msg={SKIP_SECTION_INTEREST_DEPOSIT_FORM_MSG} />
            ) : (
              ""
            )}
          </Box>
          {values.addindexRateDetails ? (
            <>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName={WHAT_IS_INDEX_NAME}
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("indexName")
                      ?.tooltip_text_main
                  }
                  labelFor="index_name"
                />
                <CoupleRadioWithDropdown
                  primaryOptionValue={values.index.indexName}
                  primaryRadioName={LEVERAGE_EXISTING_INDEX}
                  secondaryRadioName={ADD_NEW_INDEX}
                  handlePrimaryOptionSelect={indexedRateFoundationDetailsFormHandler(
                    "indexName"
                  )}
                  placeHolderName={SELECT_INDEX}
                  dropdownList={props.data.indexNameOptions}
                  onBlur={handleBlur}
                  id="index_name"
                  schema={schema}
                  uiSchema={matrixTypeUiSchema}
                  onAddNewSubmit={addNewOption}
                  modelViewUrl={API_ROUTE_CONFIGURATION.matrix}
                />
                {errors.index?.indexName && touched.index?.indexName && (
                  <ErrorAlert>
                    <span>{errors.index?.indexName}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box className="app-form-field-container">
                <DurationInput
                  labelName="What is the first duration?"
                  value={values.index.firstDur}
                  onChange={indexedRateFoundationDetailsFormHandler("firstDur")}
                  modalLinkName="Enter code manually"
                  modalTitle="Enter first duration"
                  modalLabelName={"What is the first duration?"}
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("firstDur")
                      ?.tooltip_text_main
                  }
                  modalLabelTooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("firstDur")
                      ?.tooltip_text_main
                  }
                />
                {errors.index?.firstDur && touched.index?.firstDur && (
                  <ErrorAlert>
                    <span>{errors.index?.firstDur}</span>
                  </ErrorAlert>
                )}
              </Box>
            </>
          ) : null}
          {values.addindexRateDetails === false ? (
            <Box>
              <AppNumberInput
                value={values.nomRate}
                labelName={"What is the nominal rate?"}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("nomRate")
                    ?.tooltip_text_main
                }
                valueSuffix="%"
                onChange={(value) => setFieldValue("nomRate", value)}
              />
              {errors.nomRate && touched.nomRate && (
                <ErrorAlert>
                  <span>{errors.nomRate}</span>
                </ErrorAlert>
              )}
            </Box>
          ) : (
            ""
          )}
        </CardContainer>
      </Box>
    </form>
  );
}
