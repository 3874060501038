"use client";
import {
  AppFormLabel,
  AppModal,
  CardContainer,
  StaticText,
  HoverToolTip,
  TopBannerContainer,
  BannerNotification,
  SelectDropdown,
} from "@/components/common";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  ArrowBackIcon,
  AddIcon,
  Box,
  CheckIcon,
  Image,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Divider,
  Button,
  ButtonGroup,
  Text,
  AlertStatus,
  useToast,
  Flex,
  IconButton,
  ArrowForwardIcon,
  ListItem,
  List,
} from "@/components/ChakraUiManager";
import "./AdditionalFeatures.scss";
import { AppPortal } from "@/components/common";
import { ROUTE_PATH, QUERY_PARAM_KEY } from "@/route-config/route-path";
import {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { binIcon } from "public/assets";
import {
  AdditionalTemplateFeatureObject,
  initialState,
  reducer,
} from "./AdditionalFeatureReducer";
import {
  ProductComponentType,
  ProductType,
  fetchExistingProduct,
  getIfxProdType,
  getNewlyAddedComponent,
  getRemovedComponent,
  shouldUpdateMatrix,
  updateProductInSummary,
} from "..";
import {
  DASHBOARD_ACCORDION_STATUS,
  IFX_ACCT_PRODUCT_TYPE,
  IFX_PRODUCT_SUB_TYPE_VAL,
  IFX_PRODUCT_TYPE_VAL,
  PRODUCT_CLASS_NAME,
  RECOMENDED_PRODUCT_FEATURES,
  deepCopy,
  getConstructedFeatureNameWithComponent,
} from "@/utils";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  ADDITIONAL_TEMPLATED_FEATURES_DEPOSIT_MAPPING,
  ADDITIONAL_TEMPLATED_FEATURES_LOAN_MAPPING,
  ProductSummaryType,
  getCompleteAttributeList,
} from "../product-summary/product-summary-config";
import {
  createWorkflowGeneric,
  deleteWorkflowStage,
} from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  addDesiredComponent,
  getDesiredProdFromMatrix,
  updateMatrix,
} from "../product-configuration/product-config-client-service";
import { PRODUCT_CATALOG } from "@/data/product-catalog-data";

type AdditionalFeaturesProps = {
  data: any;
  pageSize?: number;
};

const AdditionalFeatures = ({
  data,
  pageSize = 20,
}: AdditionalFeaturesProps) => {
  const {
    currProductId,
    productDetails,
    productWorkflowData,
    ifxAcctProductSubType,
    navigateWithProductNameParam,
    navigateTo,
    getQueryParam,
    setProductDetails,
    setProdMatrix,
    prodMatrix,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [addedState, setAddedState] = useState({});
  const [dispatchFlag, setDispatchFlag] = useState<string>("");
  const [componentFilters, setComponentFilters] = useState<string[]>([]);
  const [recommendedFeatureList, setRecommendedFeatureList] =
    useState<ProductComponentType[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPageData, setCurrentPageData] = useState([]);
  const currRef1 = useRef<any>();
  const toast = useToast();

  const filteredDataMultiselect = useMemo(() => {
    if (!componentFilters.length) {
      return state.featuresLibrary;
    }
    return state.featuresLibrary.filter(
      (item: AdditionalTemplateFeatureObject) =>
        item?.featureType && componentFilters.includes(item?.featureType)
    );
  }, [componentFilters, state.featuresLibrary]);

  useEffect(() => {
    const generatePayloadToUpdateTemplatedFeaturesDesc = () => {
      return IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!] ===
        IFX_PRODUCT_TYPE_VAL.loan
        ? ADDITIONAL_TEMPLATED_FEATURES_LOAN_MAPPING[
            ifxAcctProductSubType?.toLowerCase() ||
              IFX_PRODUCT_SUB_TYPE_VAL.personal
          ]
        : ADDITIONAL_TEMPLATED_FEATURES_DEPOSIT_MAPPING[
            ifxAcctProductSubType?.toLowerCase() ||
              IFX_PRODUCT_SUB_TYPE_VAL.personal
          ];
    };

    // Fetch list of available components after product details are fetched
    if (productDetails) {
      const payload = generatePayloadToUpdateTemplatedFeaturesDesc();
      dispatch({
        type: "UPDATE_TEMPLATED_FEATURES",
        payload: {
          data: payload,
        },
      });
    }
    if (data) {
      dispatch({
        type: "UPDATE_FEATURE_LIBRARY",
        payload: {
          data: data,
        },
      });
    }
  }, [data, productDetails, ifxAcctProductSubType, dispatch]);

  useEffect(() => {
    const getCurrentPageData = () => {
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      return filteredDataMultiselect?.slice(startIndex, endIndex);
    };

    const totalItems = filteredDataMultiselect.length;
    const totalPageCount = Math.ceil(totalItems / pageSize);
    setTotalPages(totalPageCount);
    setCurrentPageData(getCurrentPageData());
  }, [filteredDataMultiselect, pageSize, currentPage, componentFilters]);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // We are making multiselect data unique so that in dropdown the list will be unique
  const componentsList = state.featuresLibrary?.reduce(
    (accumulator: any, libraryValue: any) => {
      if (
        libraryValue.featureType &&
        !accumulator.includes(libraryValue.featureType)
      ) {
        accumulator.push(libraryValue.featureType);
      }
      return accumulator;
    },
    []
  );
  const showModal = (refType: any, added: any, dispatchFlag: string) => {
    if (refType?.current) {
      setAddedState(added);
      refType.current.openModal();
      setDispatchFlag(dispatchFlag);
    }
  };
  const onClickSubmit = (refType: any) => {
    if (dispatchFlag === "LIBRARY") {
      dispatch({
        type: "REMOVE_FROM_FEATURES",
        payload: addedState,
      });
    } else {
      dispatch({
        type: "REMOVE_FROM_FEATURES",
        payload: {
          key: "Added_Features",
          addedFeatures: addedState,
        },
      });
    }
    setDispatchFlag("");
    setAddedState({});
    if (refType?.current) {
      refType.current.closeModal();
    }
  };
  // we are handling multiselect dropdown
  const handleMultiselectDropdown = (value: string[]) => {
    setCurrentPage(1);
    setComponentFilters(value);
  };
  // we are filtering the data based multiselect dropdwon
  function saveAndExit() {
    if (state.addedFeatures?.length) {
      let uniqueComponentList: ProductComponentType[] = [];
      const productData: ProductType = deepCopy(productDetails)!;
      const newlyAddedComps = state.addedFeatures
        .map(
          (item: AdditionalTemplateFeatureObject) =>
            item?.isTemplated && item.componentClass
        )
        ?.filter(Boolean);
      const constructedAddedFeatureList: ProductComponentType[] =
        state.addedFeatures.map((item: AdditionalTemplateFeatureObject) => {
          return {
            componentClass: item.componentClass,
            ...(!item?.isTemplated && { componentName: item?.componentName }),
            version: item?.version ?? 1,
          };
        });
      if (productData?.components?.length) {
        //compare existing and new feature list and filter duplicates
        const constructedAddedFeatureString: string[] = state.addedFeatures.map(
          (item: AdditionalTemplateFeatureObject) => {
            return item.componentClass;
          }
        );
        const filteredExistingComponents = productData?.components?.length
          ? productData?.components?.filter((item) => {
              return constructedAddedFeatureString.includes(
                item.componentClass
              );
            })
          : [];
        const featureList: any[] = [
          ...constructedAddedFeatureList,
          ...filteredExistingComponents,
        ];
        const newSetter = new Set();
        //remove the duplicates
        uniqueComponentList = featureList.filter((item) => {
          const duplicate = newSetter.has(item.componentClass);
          newSetter.add(item.componentClass);
          return !duplicate;
        });
      } else {
        uniqueComponentList = [...constructedAddedFeatureList];
      }

      productData.components = [...uniqueComponentList];
      updateExistingProduct(productData, newlyAddedComps);
    }
  }

  function updateExistingProduct(
    productData: ProductType,
    newlyAddedComps: string[]
  ) {
    let updatedProductData = productData;
    const compToUpdateMatrix = updatedProductData.components?.map(
      (comp) => comp.componentClass
    )!;

    updatedProductData.components = updatedProductData.components?.filter(
      (comp) => !newlyAddedComps.includes(comp.componentClass)
    );

    if (productData?.name) {
      updateProductInSummary(productData?.name, updatedProductData)
        .then(async (res: ProductType) => {
          if (res?.name) {
            if (prodMatrix) {
              const matrixComps = prodMatrix?.val?.split(",") ?? [];
              if (shouldUpdateMatrix(matrixComps, compToUpdateMatrix)) {
                prodMatrix.val = compToUpdateMatrix.join(",");
                updateMatrix({
                  matrixes: [prodMatrix],
                });
              }
            } else {
              await addDesiredComponent(res.name, compToUpdateMatrix ?? []);
            }
            updateWorkflow(productData, newlyAddedComps);
          }

          showToast(
            "updated-additional-feature-success",
            "Updated the product successfully",
            "success"
          );
        })
        .catch((err) => {
          const errMsg =
            err?.errors?.[0]?.errorDesc || "Unable to update the product";
          showToast("updated-additional-feature-error", errMsg, "error");
        });
    }
  }

  async function updateWorkflow(productData: ProductType, prodComps: string[]) {
    const componentListWithoutName = productData.components?.filter(
      (item) => !item.componentName
    );
    let updatedProdComps =
      productData.components?.map((comp) => comp.componentClass) ?? [];
    updatedProdComps = [...updatedProdComps, ...prodComps];
    // updating/deleting the respective worklfow record incase any component is removed
    if (productWorkflowData?.length) {
      const componentStages = productWorkflowData.filter(
        (item) => PRODUCT_CLASS_NAME[item.stage]
      );

      const removedComponents = getRemovedComponent(
        updatedProdComps,
        componentStages
      );
      if (removedComponents?.length) {
        await Promise.all(
          removedComponents.map(async (component) => {
            const response = await deleteWorkflowStage({
              productName: productData?.name ?? "",
              stage: component.stage,
            });
          })
        );
      }
      const newlyAddedComponents = getNewlyAddedComponent(
        prodComps,
        productWorkflowData,
        productData!
      );
      if (newlyAddedComponents?.length) {
        await Promise.all(
          newlyAddedComponents.map(async ({ componentClass, isCompleted }) => {
            const response = await createWorkflowGeneric({
              model: API_ROUTE_CONFIGURATION.product,
              key: productData?.name ?? "",
              stage: componentClass,
              status: isCompleted
                ? DASHBOARD_ACCORDION_STATUS.completed
                : DASHBOARD_ACCORDION_STATUS.notStarted,
            });
          })
        );
      }
    } else if (componentListWithoutName?.length || prodComps?.length) {
      createWorkflowForNewComponents(productData, prodComps);
      return;
    }
    navigateToProdummary(productData);
  }

  /**
   *
   * @param productData
   * For completed products worflow will not be there so upding the workflow incase new components without name is added
   */
  async function createWorkflowForNewComponents(
    productData: ProductType,
    prodComps: string[]
  ) {
    let updatedProductAttributeData = deepCopy(
      getCompleteAttributeList(productData?.ifxAcctType ?? "")
    );
    updatedProductAttributeData = updatedProductAttributeData.map(
      (attributeData: ProductSummaryType) => ({
        ...attributeData,
        status: DASHBOARD_ACCORDION_STATUS.completed,
      })
    );

    productData.components?.forEach((item) => {
      item.status = DASHBOARD_ACCORDION_STATUS.completed;
      if (!item.componentName) {
        item.status = DASHBOARD_ACCORDION_STATUS.notStarted;
      }
    });
    if (updatedProductAttributeData?.length) {
      let componentList = [
        ...(productData.components?.length ? productData.components : []),
        ...updatedProductAttributeData,
      ];

      prodComps?.forEach((item) => {
        !componentList.some((comp) => comp.componentClass === item) &&
          componentList.push({
            componentClass: item,
            status: DASHBOARD_ACCORDION_STATUS.notStarted,
          });
      });
      await Promise.all(
        componentList.map(async (component) => {
          await createWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.product,
            key: productData?.name ?? "",
            stage: component?.componentClass ?? "",
            status: component.status ?? DASHBOARD_ACCORDION_STATUS.notStarted,
          });
        })
      )
        .then(() => {
          navigateToProdummary(productData);
        })
        .catch((error: any) => {
          navigateToProdummary(productData);
        });
    } else {
      navigateToProdummary(productData);
    }
  }

  function navigateToProdummary(productData: ProductType) {
    navigateWithProductNameParam(
      `${ROUTE_PATH["PRODUCT_SUMMARY_FULLPATH"]}`,
      productData.name
    );
  }

  function showToast(toastId: string, msg: string, status: AlertStatus) {
    if (!toast.isActive(toastId)) {
      toast({
        id: toastId,
        description: msg,
        status: status,
        duration: 5000,
      });
    }
  }

  async function checkForExistingComponent(prodDetails: ProductType) {
    const ADDITIONAL_TEMPLATED_FEATURES =
      IFX_ACCT_PRODUCT_TYPE[prodDetails?.ifxAcctType!] ===
      IFX_PRODUCT_TYPE_VAL.loan
        ? ADDITIONAL_TEMPLATED_FEATURES_LOAN_MAPPING[
            ifxAcctProductSubType?.toLowerCase() ||
              IFX_PRODUCT_SUB_TYPE_VAL.personal
          ]
        : ADDITIONAL_TEMPLATED_FEATURES_DEPOSIT_MAPPING[
            ifxAcctProductSubType?.toLowerCase() ||
              IFX_PRODUCT_SUB_TYPE_VAL.personal
          ];
    const addedFeatures: any[] = prodDetails?.components
      ? prodDetails?.components
      : [];
    prodMatrix?.val?.split(",").forEach((comp) => {
      !addedFeatures.some((feature) => feature.componentClass === comp) &&
        addedFeatures.push({
          componentClass: comp,
          componentName:
            ADDITIONAL_TEMPLATED_FEATURES?.find(
              (feature) => feature.componentClass === comp
            )?.componentName ?? "",
          isTemplated: true,
        });
    });
    return addedFeatures;
  }

  async function initAdditionalFeature(prodDetails: ProductType) {
    const existingFeature = await checkForExistingComponent(prodDetails);
    if (prodDetails.ifxAcctType) {
      const featureList: ProductComponentType[] =
        RECOMENDED_PRODUCT_FEATURES[prodDetails.ifxAcctType];
      setRecommendedFeatureList(featureList);
    }
    if (existingFeature?.length) {
      dispatch({
        type: "UPDATE_EXISTING_FEATURES",
        payload: {
          data: existingFeature,
        },
      });
    }
  }

  const getRecomProdTooltipDesc = (componentClass: string) => {
    const product =
      ifxAcctProductSubType === IFX_PRODUCT_SUB_TYPE_VAL.personal
        ? PRODUCT_CATALOG.retailProducts
        : PRODUCT_CATALOG.commercialProducts;

    const productDetail = product.productList.find(
      (item) =>
        item.ifxAcctType === productDetails?.ifxAcctType &&
        item.prodSubType === ifxAcctProductSubType
    );

    return productDetail?.componentConfigurationList.find(
      (comp: any) => comp.componentClass === componentClass
    )?.configDesc;
  };

  function navigateToSummary() {
    if (getQueryParam(QUERY_PARAM_KEY["PRODUCT_NAME_KEY"])) {
      navigateWithProductNameParam(`${ROUTE_PATH["PRODUCT_SUMMARY_FULLPATH"]}`);
    } else {
      navigateTo(`${ROUTE_PATH["PRODUCT_SUMMARY_FULLPATH"]}`);
    }
  }

  useEffect(() => {
    async function getProductDetails() {
      if (currProductId) {
        await fetchExistingProduct(currProductId)
          .then(async (res) => {
            const prodDetails: ProductType = res?.[0];
            await getDesiredProdFromMatrix(prodDetails.name)
              .then((prodFromMatrix) => {
                prodFromMatrix && setProdMatrix?.(prodFromMatrix.data?.[0]);
                return prodFromMatrix;
              })
              .catch((e: any) => {
                setProdMatrix?.(null);
              });

            if (prodDetails?.name) {
              setProductDetails?.(prodDetails);
              initAdditionalFeature(prodDetails);
            }
          })
          .catch((err: any) => {
            const errMsg =
              err?.errors?.[0]?.errorDesc ||
              "Unable to fetch the product details";
            showToast("add-feature-product-detail-init", errMsg, "error");
          });
      }
    }
    if (currProductId) {
      getProductDetails();
    }
  }, [currProductId]);

  const PaginationPanel = () => {
    return (
      totalPages > 1 && (
        <Flex justifyContent="space-between" className="pagination-panel">
          <IconButton
            isRound={true}
            aria-label="Done"
            fontSize={"var(--chakra-fontSizes-5xl)"}
            icon={<ArrowBackIcon />}
            variant="outline"
            p={"var(--chakra-space-2)"}
            size={"xl"}
            isDisabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className="pag-nav-btn"
          />
          <Box>{constructPageNumberElem()}</Box>
          <IconButton
            isRound={true}
            variant="outline"
            aria-label="Done"
            fontSize={"var(--chakra-fontSizes-5xl)"}
            icon={<ArrowForwardIcon />}
            p={"var(--chakra-space-2)"}
            size={"xl"}
            onClick={() => handlePageChange(currentPage + 1)}
            isDisabled={currentPage === totalPages}
            className="pag-nav-btn"
          />
        </Flex>
      )
    );
  };

  const constructPageNumberElem = () => {
    let content = [];
    if (totalPages <= 10) {
      for (let i = 1; i <= totalPages; i++) {
        content.push(
          <ListItem
            onClick={() => setCurrentPage(i)}
            className={currentPage === i ? "active" : ""}
            key={i}
          >
            {i}
          </ListItem>
        );
      }
    } else {
      for (let i = 1; i <= 5; i++) {
        content.push(
          <ListItem
            onClick={() => setCurrentPage(i)}
            className={currentPage === i ? "active" : ""}
            key={i}
          >
            {i}
          </ListItem>
        );
      }

      if (currentPage > 5 && currentPage < totalPages - 4) {
        content.push(<ListItem>..........</ListItem>);
        content.push(
          <ListItem
            onClick={() => setCurrentPage(currentPage)}
            className={currentPage === currentPage ? "active" : ""}
            key={currentPage}
          >
            {currentPage}
          </ListItem>
        );
        content.push(<ListItem>..........</ListItem>);
      } else {
        content.push(<ListItem>....................</ListItem>);
      }
      for (let i = totalPages - 4; i <= totalPages; i++) {
        content.push(
          <ListItem
            onClick={() => setCurrentPage(i)}
            className={currentPage === i ? "active" : ""}
            key={i}
          >
            {i}
          </ListItem>
        );
      }
    }
    return (
      <List spacing={5} className="pagination-number-panel">
        {content}
      </List>
    );
  };

  // Storing AppModal in const "ConfirmationAppModal" JSX format
  const confirmationAppModal = (
    <AppModal
      customClass="frequency-module-modal-selector"
      ref={currRef1}
      modalTitle="Are you sure you want to remove this component from your product?"
      primaryBtnProp={{
        name: "Confirm",
        btnClassName: "app-btn-inverse-secondary",
      }}
      primaryBtnSelect={onClickSubmit.bind(null, currRef1)}
      secondaryBtnProp={{
        name: "Cancel",
        btnClassName: "app-btn-reg-secondary",
        style: { marginRight: "var(--chakra-sizes-4)" },
        enableCloseBtn: true,
      }}
    >
      <div className="code-input">
        <StaticText textValue="Any customizations will be lost." />
      </div>

      <Divider width="100%" className="bottom-divider" />
    </AppModal>
  );

  const footer = (
    <AppPortal domId="#appFooter">
      <Box className="configured-product-footer">
        {(state?.addedFeatures?.length > 0 ||
          state?.addedLibrary?.length > 0) && (
          <ButtonGroup className="configured-product-btn-container footer-btn-configuration">
            <Button
              onClick={saveAndExit}
              className={`app-btn-inverse-secondary-add-new ${
                state.addedFeatures?.length ? "" : "disabled"
              }`}
            >
              Save & return
            </Button>
          </ButtonGroup>
        )}
      </Box>
    </AppPortal>
  );
  return (
    <>
      <Box className="notification-banner-wrapper-add-features">
        <BannerNotification />
      </Box>
      <Box className="additional-features-outer-container">
        <TopBannerContainer domId="#consoleTopBanner" backgroundImage={true}>
          <div className="title-container">
            <div className="title">
              <Button className="app-btn-link" onClick={navigateToSummary}>
                <ArrowBackIcon
                  width="2.4rem"
                  height="2.4rem"
                  aria-label="back-arrow"
                />
              </Button>
              <Text ml={"var(--chakra-space-4)"} as={"span"}>
                Add additional components
              </Text>
            </div>
          </div>
        </TopBannerContainer>
        <div className="add-features-container">
          {/* Card container will render two JSX format data i.e "featureListing" and "featureDetails" */}
          <CardContainer customClass="features-listing">
            <Box>
              <h3>Choose the following components to add to your product</h3>
              <h4>Build on templated components to further customization</h4>
              <Tabs>
                <TabList>
                  <Tab>Additional templated components</Tab>
                  <Tab>
                    <Text as="label" htmlFor="filter-component-type_input">
                      My component library
                    </Text>
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <h6 className="features-list">Component</h6>
                    {state?.templatedFeatures?.map(
                      (feature: AdditionalTemplateFeatureObject) => (
                        <Accordion allowMultiple key={feature.componentName}>
                          <AccordionItem className="fx-accordion">
                            <AccordionButton className="feature-container flex-acenter">
                              <div className="feature-title">
                                <AccordionIcon
                                  className="fx-accordion-icon"
                                  width={"var(--chakra-space-8)"}
                                  height={"var(--chakra-space-8)"}
                                />
                                <Box
                                  as="span"
                                  flex="1"
                                  textAlign="left"
                                  className="feature-name"
                                >
                                  {feature.componentName}
                                </Box>
                              </div>
                              {state?.addedFeatures?.some(
                                (data: AdditionalTemplateFeatureObject) =>
                                  data?.componentClass ===
                                    feature.componentClass &&
                                  data?.componentName === feature.componentName
                              ) ? (
                                <Box
                                  flex="1"
                                  textAlign="right"
                                  className="btn-added-feature"
                                >
                                  <CheckIcon
                                    width={"var(--chakra-space-4)"}
                                    height={"var(--chakra-space-4)"}
                                  />
                                  <span className="btn-added">Selected</span>
                                </Box>
                              ) : (
                                <Box
                                  as="button"
                                  flex="1"
                                  textAlign="right"
                                  className="btn-add-feature"
                                  disabled={
                                    state?.addedFeatures?.findIndex(
                                      (item: AdditionalTemplateFeatureObject) =>
                                        item?.componentClass ===
                                        feature?.componentClass
                                    ) > -1
                                  }
                                  onClick={(event: any) => {
                                    event.stopPropagation();
                                    dispatch({
                                      type: "ADDED_TO_FEATURES",
                                      payload: {
                                        key: "Added_Features",
                                        addedFeatures: feature,
                                      },
                                    });
                                  }}
                                >
                                  <AddIcon
                                    width={"var(--chakra-space-4)"}
                                    height={"var(--chakra-space-4)"}
                                  />
                                  <span>Add</span>
                                </Box>
                              )}
                            </AccordionButton>
                            <AccordionPanel
                              pb={4}
                              className="accordian-description"
                            >
                              <Flex direction="column" rowGap={5}>
                                DESCRIPTION
                                <Box>{feature.featureDesc}</Box>
                              </Flex>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      )
                    )}
                  </TabPanel>
                  <TabPanel className="no-margin">
                    <div className="filter-container">
                      <SelectDropdown
                        isMulti
                        hideDashedBorder
                        dropdownList={componentsList}
                        value={componentFilters}
                        onChange={handleMultiselectDropdown}
                        placeholder={"Filter component type"}
                        id="filter-component-type"
                      />
                    </div>
                    <div className="flex-acenter features-list">
                      <h6 className="">Component</h6>
                      <h6 className="">Version</h6>
                      <h6 className="">Component Type</h6>
                      <Box className="w-15"></Box>
                    </div>
                    {currentPageData.map(
                      (feature: AdditionalTemplateFeatureObject) => (
                        <Accordion
                          allowMultiple
                          key={`${feature.componentName} - ${feature.version} - ${feature.featureType}`}
                        >
                          <AccordionItem className="fx-accordion">
                            <AccordionButton className="feature-container flex-acenter">
                              {/* <div className="feature-title"> */}
                              <AccordionIcon
                                className="fx-accordion-icon"
                                width={"var(--chakra-space-8)"}
                                height={"var(--chakra-space-8)"}
                              />
                              <Box
                                as="span"
                                flex="1"
                                textAlign="left"
                                className="feature-name"
                                fontWeight={
                                  "var(--chakra-fontWeights-semibold)"
                                }
                              >
                                {feature.componentName}
                              </Box>
                              <Box
                                as="span"
                                flex="1"
                                textAlign="left"
                                className="feature-name"
                                fontWeight={
                                  "var(--chakra-fontWeights-semibold)"
                                }
                              >
                                {feature.version}
                              </Box>
                              <Box
                                as="span"
                                flex="1"
                                textAlign="left"
                                className="feature-name"
                              >
                                {feature.featureType}
                              </Box>
                              {state?.addedFeatures?.some(
                                (data: AdditionalTemplateFeatureObject) =>
                                  data?.componentClass ===
                                    feature?.componentClass &&
                                  data?.componentName ===
                                    feature?.componentName &&
                                  data?.version === feature?.version
                              ) ? (
                                <>
                                  <Box className="wrapper-add-btn">
                                    <Box
                                      flex="1"
                                      textAlign="right"
                                      className="btn-added-feature"
                                    >
                                      <CheckIcon
                                        width={"var(--chakra-space-4)"}
                                        height={"var(--chakra-space-4)"}
                                      />
                                      <span className="btn-added">
                                        Selected
                                      </span>
                                    </Box>
                                  </Box>
                                </>
                              ) : (
                                <Box className="wrapper-add-btn">
                                  <Box
                                    as="button"
                                    flex="1"
                                    textAlign="right"
                                    className="btn-add-feature"
                                    disabled={
                                      state?.addedFeatures?.findIndex(
                                        (
                                          item: AdditionalTemplateFeatureObject
                                        ) =>
                                          item?.componentClass ===
                                          feature.componentClass
                                      ) > -1
                                    }
                                    onClick={(event: any) => {
                                      event.stopPropagation();
                                      dispatch({
                                        type: "ADDED_TO_FEATURES",
                                        payload: {
                                          key: "Added_Features",
                                          addedFeatures: feature,
                                        },
                                      });
                                    }}
                                  >
                                    <AddIcon
                                      width={"var(--chakra-space-4)"}
                                      height={"var(--chakra-space-4)"}
                                    />
                                    <span>Add</span>
                                  </Box>
                                </Box>
                              )}
                            </AccordionButton>
                            <AccordionPanel
                              pb={4}
                              className="accordian-description"
                            >
                              <Flex direction="column" rowGap={5}>
                                DESCRIPTION
                                <Box>{feature.featureDesc}</Box>
                              </Flex>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      )
                    )}
                    {totalPages > 1 && <PaginationPanel />}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </CardContainer>
          <div className="added-features-details">
            {recommendedFeatureList?.length && productDetails?.name ? (
              <div className="required-features">
                <h3>{productDetails.name}</h3>
                <h5>Recommended components</h5>
                <div className="required-features-listing">
                  {recommendedFeatureList.map((feature, index) => (
                    <div key={`recommendedFeature${index}`} className="flex">
                      <CheckIcon width="2rem" height="1.5rem" />
                      <AppFormLabel
                        labelName={getConstructedFeatureNameWithComponent(
                          feature.componentClass,
                          getIfxProdType(productDetails)
                        )}
                        tooltipDesc={getRecomProdTooltipDesc(
                          feature.componentClass
                        )}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="added-features">
              <h5>Additional components</h5>
              <div className="desc">
                The following components are being added to your product:
              </div>
              <div className="added-features-container">
                {state?.addedFeatures?.map(
                  (added: AdditionalTemplateFeatureObject) => (
                    <Flex
                      gap={2}
                      className="feature"
                      key={added?.componentName}
                    >
                      <Box className="feature-name">{added?.componentName}</Box>
                      <div
                        onClick={showModal.bind(
                          null,
                          currRef1,
                          added,
                          "FEATURE"
                        )}
                        className="action cp"
                      >
                        <HoverToolTip placement="top" label="Remove component">
                          {<Image src={binIcon.src} alt="Delete" />}
                        </HoverToolTip>
                      </div>
                    </Flex>
                  )
                )}
                {state?.addedLibrary?.map((added) => (
                  <Flex gap={2} className="feature" key={added.componentName}>
                    <div className="feature-name">{added.componentName}</div>
                    <div
                      onClick={showModal.bind(null, currRef1, added, "LIBRARY")}
                      className="action cp"
                    >
                      <Image src={binIcon.src} alt="Delete" />
                    </div>
                  </Flex>
                ))}
              </div>
              {!state?.addedFeatures?.length && !state?.addedLibrary?.length ? (
                <Box className="no-feature-added">No new components added</Box>
              ) : (
                ""
              )}
            </div>
          </div>
          {footer}
        </div>
        {/* We are rendering AppModal here for confirmation when user will click on delete icon */}
        {confirmationAppModal}
      </Box>
    </>
  );
};
export default AdditionalFeatures;
