import {
  Box,
  Flex,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import MainContentWithoutStep from "../main-content-without-step/MainContentWithoutStep";
import "./TestAndDeploy.scss";

import { Badges } from "@/components/common";
import { getBadgeByState } from "@/components/academy/learning-util-service";
import { useRouter } from "next/navigation";
import { TestDeployProps } from "../model";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
interface TestAndDeployProps {
  title: string;
  testAndDeployDetails: TestDeployProps;
  disabledState: boolean;
  completed: number | null;
}

export default function TestAndDeploy(props: TestAndDeployProps) {
  const { title, testAndDeployDetails, completed, disabledState } = props;
  const testingChecklistDetails = testAndDeployDetails?.testing_checklist;
  const diagnosticsDashboardDetails =
    testAndDeployDetails?.diagnostics_dashboard;
  const router = useRouter();
  const handleOnClick = (isLegacy: boolean, e: any) => {
    e.stopPropagation();

    if (isLegacy) {
      window.location.href = ROUTE_BASE_URL.DIAGNOSTIC_DASHBOARD;
    } else {
      router.push(
        `${ROUTE_PATH["DOCUMENT_DETAIL"]}/${testAndDeployDetails?.view_link?.type}/${testAndDeployDetails?.view_link?.uid}`
      );
    }
  };

  return (
    <Box>
      <Flex className="test-and-deploy-content ">
        <Heading as={"h4"}>{title}</Heading>
        <Text className="test-and-deploy-status">2 available steps </Text>
      </Flex>
      {completed && (
        <Box className="badge-container">
          <Box>
            <Badges
              size="lg"
              {...getBadgeByState({
                status: "COMPLETED",
                label: `${completed} PRODUCTS DEPLOYED`,
                showState: true,
              })}
            />
          </Box>
        </Box>
      )}

      <MainContentWithoutStep
        btnLink
        btnName="View"
        title={testingChecklistDetails?.sectionTitle}
        desc={testingChecklistDetails?.sectionSubTitle}
        overviewHeader={testingChecklistDetails?.header}
        overview={testingChecklistDetails?.description}
        helpfullRes={testingChecklistDetails?.resourcesLinks}
        btnDisabled={disabledState}
        onClick={handleOnClick.bind(null, false)}
      />
      <MainContentWithoutStep
        btnLink
        btnName="View"
        title={diagnosticsDashboardDetails?.sectionTitle}
        desc={diagnosticsDashboardDetails?.sectionSubTitle}
        overviewHeader={diagnosticsDashboardDetails?.header}
        overview={diagnosticsDashboardDetails?.description}
        helpfullRes={diagnosticsDashboardDetails?.resourcesLinks}
        btnDisabled={disabledState}
        onClick={handleOnClick.bind(null, true)}
        isLastItem
      />
    </Box>
  );
}
