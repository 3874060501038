"use client";

import {
  CardContainer,
  ArrowForwardIcon,
  IconLinkButton,
} from "../../../common";
import {
  Card,
  CardBody,
  CardFooter,
  Text,
  Heading,
  SimpleGrid,
  Stack,
  Image,
  Show,
  Hide,
} from "@chakra-ui/react";
import "./DocCenterExplore.scss";
import { ROUTE_PATH } from "@/route-config/route-path";
import { exploreImage } from "public/assets";

import styleVars from "@/styles/_export.module.scss";

type DocumentCenterModalProps = {
  title: string;
  subTitle: string;
  docList: any[];
};

const DocCenterExplore = (props: DocumentCenterModalProps) => {
  const { title, subTitle, docList } = props;
  return (
    <CardContainer customClass="doc-explore-container">
      <CardBody padding={2}>
        <Stack className="inner-container">
          <Stack gap={3.2}>
            <Heading className="doc-heading">{title}</Heading>
            <Text className="doc-sub-heading">{subTitle}</Text>
          </Stack>

          <SimpleGrid gap={10} columns={{ base: 1, md: 3 }}>
            {docList.map((page: any, index) => {
              return (
                <Card key={index} className="card">
                  <CardBody padding={3}>
                    <Stack spacing="7">
                      <Hide above="md">
                        <Image
                          src={page.mobile_card_image?.url}
                          alt={page.card_image?.alt || "explore card"}
                          width="32px"
                          height="32px"
                        />
                      </Hide>
                      <Show above="md">
                        <Image
                          src={
                            page.card_image?.url ||
                            page.link?.data?.meta_image?.url ||
                            exploreImage.src
                          }
                          alt={page.card_image?.alt || "explore card"}
                          borderRadius="lg"
                        />
                      </Show>
                      <Heading as="h5" fontSize={styleVars.fontSizeMed}>
                        {page.link?.data?.article_main_title?.length > 0
                          ? page.link?.data?.article_main_title[0]?.text
                          : page.link?.data?.meta_title || page.title}
                      </Heading>
                      <Text size="sm" className="featured-doc-desc">
                        {page.description || page.link?.data?.meta_description}
                      </Text>
                    </Stack>
                  </CardBody>
                  <CardFooter padding={3}>
                    <IconLinkButton
                      link={
                        page.link?.link_type === "Web"
                          ? page.link?.url
                          : `${ROUTE_PATH["DOCUMENT_DETAIL"]}/${page.link?.type}/${page.link?.uid}`
                      }
                      rightIcon={<ArrowForwardIcon />}
                      text={page.anchor_text}
                    />
                  </CardFooter>
                </Card>
              );
            })}
          </SimpleGrid>
        </Stack>
      </CardBody>
    </CardContainer>
  );
};

export default DocCenterExplore;
