"use client";
import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  InputText,
  SplitButton,
  SelectDropdown,
} from "@/components/common";
import { FormikErrors, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Box, Text, useToast } from "@chakra-ui/react";
import {
  OtherIdentificationDetailsValueProps,
  otherIdentificationDetails,
  getValidationSchema,
} from "./OtherIdentificationDetailsValidation";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import "./OtherIdentificationDetails.scss";
import { ROUTE_PATH } from "@/route-config/route-path";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import {
  FIN_ORG_SUB_STEPS,
  IC_ENTITY,
  shouldUpdateWorkflowStatus,
  useQueryParams,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type OtherIdentificationDetailsProps = {
  data: any;
};

const OtherIdentificationDetails = (props: OtherIdentificationDetailsProps) => {
  const {
    data: { formData },
  } = props;
  const toast = useToast();
  const toastId = "fosp-finOrg-identification";
  const updateQueryParams = useQueryParams();

  const { partyOrgFininstOptionsData } = props.data;

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateTo(
        `${
          ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_FIN_ORG_SPECIFICATIONS"]
        }?${updateQueryParams()}`
      );
    }
    return;
  };

  const onSubmit = async (
    values: OtherIdentificationDetailsValueProps,
    actions: any
  ) => {
    const currentStage = FIN_ORG_SUB_STEPS.fin_org_other_identification;
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      moveToNextStep();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };
    const response = await updateFinOrg({
      formData: {
        _vn: formData?._vn,
        _Id: formData?._Id,
        name: formData?.name,
        otherIds: values.addOtherIdentifications ? values.otherIds : [],
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      moveToNextStep();
      return;
    }
  };
  const handleOnChange = (key: string) => (value: any) => {
    let selectedObject: any;
    if (key === "addOtherIdentifications") {
      setFieldValue(key, value);
    }
    switch (key) {
      case "otherIdType":
        selectedObject =
          props.data?.otherIdTypeOptions?.find(
            (obj: { label: string; value: string }) => obj.label === value
          ) || [];
        break;
    }
    if (selectedObject?.value) {
      setFieldValue(`otherIds.[${0}].${key}`, selectedObject?.value || "");
    } else {
      setFieldValue(`otherIds.[${0}].${key}`, value);
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(partyOrgFininstOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [partyOrgFininstOptionsData]);
  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: formData
      ? {
          addOtherIdentifications: formData?.otherIds?.length
            ? true
            : formData?.otherIds
              ? false
              : undefined,
          otherIds:
            formData?.otherIds?.map((item: any) => ({ ...item })) ??
            otherIdentificationDetails.otherIds,
        }
      : otherIdentificationDetails,
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);
  useEffect(() => {
    if (isGoingBack) {
      navigateTo(
        `${
          ROUTE_PATH["IC_FIN_ORG_SPEC_PARAM_FIN_ORG_IDENTIFICATION"]
        }?${updateQueryParams()}`
      );
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  const formikErrors = (
    errors.otherIds as FormikErrors<
      OtherIdentificationDetailsValueProps["otherIds"]
    >
  )?.[0];
  const touchedFields = touched.otherIds?.[0];
  const dispalyOtherIdError = formikErrors?.otherId &&
    touchedFields?.otherId && (
      <ErrorAlert>
        <span>{formikErrors.otherId}</span>
      </ErrorAlert>
    );

  const dispalyOtherIdTypeError = formikErrors?.otherIdType &&
    touchedFields?.otherIdType && (
      <ErrorAlert>
        <span>{formikErrors.otherIdType}</span>
      </ErrorAlert>
    );

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer customClass="app-form-container">
        <Box className="app-form-field-container">
          <Text className="content-box-title" as="h3">
            Other identification details
          </Text>
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Add other identifications?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("addOtherIdentifications")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            name="addOtherIdentifications"
            onSelect={handleOnChange("addOtherIdentifications")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.addOtherIdentifications}
          />
          {errors.addOtherIdentifications &&
            touched.addOtherIdentifications && (
              <ErrorAlert>
                <span>{errors.addOtherIdentifications}</span>
              </ErrorAlert>
            )}
        </Box>
        {values.addOtherIdentifications && (
          <>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the other ID type?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("otherIds")
                    ?.tooltip_text_main
                }
                labelFor="other-id-type_input"
              />
              <SelectDropdown
                value={
                  props?.data?.otherIdTypeOptions?.find(
                    (obj: { label: string; value: string }) =>
                      obj.value === values.otherIds[0].otherIdType
                  )?.label || ""
                }
                onChange={handleOnChange("otherIdType")}
                dropdownList={
                  props?.data?.otherIdTypeOptions?.map(
                    (d: { label: string }) => d.label
                  ) || []
                }
                onBlur={handleBlur}
                id="other-id-type"
                placeholder={"Select Type"}
              />
              {dispalyOtherIdTypeError}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the other ID?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("otherId")
                    ?.tooltip_text_main
                }
              />
              <InputText
                value={values.otherIds[0].otherId}
                onChange={handleOnChange("otherId")}
                placeholder="000000010G1"
                onBlur={handleBlur}
              />
              {dispalyOtherIdError}
            </Box>
          </>
        )}
      </CardContainer>
    </form>
  );
};
export default OtherIdentificationDetails;
