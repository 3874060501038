import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import { MultiTieredSideMenuDataModel } from "@/components/common/MultiTieredSideMenu/MultiTieredSideMenuDataModel";

export const ACADEMY_SIDEBAR_MENU_MULTI: MultiTieredSideMenuDataModel[] = [
  {
    type: "none",
    title: "",
    path: "",
    isSelected: true,
    uid: "",
    childrens: [
      {
        title: "My Learning",
        uid: ROUTE_BASE_URL.ACADEMY,
        childrens: [],
        path: ROUTE_BASE_URL.ACADEMY,
        type: "item",
      },
      {
        title: "Academy Overview",
        uid: ROUTE_PATH.ACADEMY_OVERVIEW,
        childrens: [],
        path: ROUTE_PATH.ACADEMY_OVERVIEW,
        type: "item",
      },
      {
        title: "Catalog",
        uid: ROUTE_PATH.ACADEMY_CATALOG,
        childrens: [],
        path: ROUTE_PATH.ACADEMY_CATALOG,
        type: "item",
      },
      {
        title: "Badges & certifications",
        uid: ROUTE_PATH.ACADEMY_BADGES_CERTIFICATION,
        childrens: [],
        path: ROUTE_PATH.ACADEMY_BADGES_CERTIFICATION,
        type: "item",
      },
    ],
  },
];
