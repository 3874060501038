"use client";
import { CardContainer } from "@/components/common";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import Image from "next/image";
import { solidRightArrow } from "public/assets";
import React from "react";

import "./ResourceSection.scss";
import { ArrowForwardIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import Link from "next/link";
import { ResourceProps } from "../model";
import { buildURL } from "@/prismic-api/common-service";
interface ResourceSectionProps {
  resourceData: ResourceProps;
}

const ResourceSection = (props: ResourceSectionProps) => {
  const { resourceData } = props;

  return (
    <Box className="dashboard-resource-section">
      <Text as="h3">
        {resourceData?.sectionTitle}
      </Text>
      <Text className="dashboard-resouce-desc">
        {resourceData?.sectionSubTitle}
      </Text>
      {resourceData?.resourcesLinks &&
        resourceData?.resourcesLinks.map((data: any, index: number) => (
          <CardContainer key={index} customClass="dashboard-resource-container">
            <Box>
              <Text className="resource-title">{data.link_title}</Text>
              <Text className="resource-caption">{data.link_caption}</Text>
            </Box>
            <Box className="dashboard-resource-img">
              {data.link_type === "prismic link" ? (
                <Link
                  href={buildURL(
                    data?.prismic_link?.type,
                    data?.prismic_link?.uid
                  )}
                >
                  <Image src={solidRightArrow} alt="Right arrow" />
                </Link>
              ) : data.link_type === "academy" ? (
                <Link
                  href={
                    `${ROUTE_PATH.ACADEMY_CATALOG_COURSE}/?id=` +
                    `${data?.academy_course_link}`
                  }
                >
                  <Image src={solidRightArrow} alt="Right arrow" />
                </Link>
              ) : (
                <a
                  href={
                    data?.external_link?.link_type === "Document"
                      ? buildURL(data?.external_link?.type, data?.external_link?.uid)
                      : data?.external_link?.url
                  }
                >
                  <Image src={solidRightArrow} alt="Right arrow" />
                </a>
              )}
            </Box>
          </CardContainer>
        ))}

      <Link href={ROUTE_BASE_URL.ACADEMY}>
        <Button className="app-btn-link academy-link">
          Visit the Academy to learn more{" "}
          <ChevronRightIcon className="academy-link-arrow" />
        </Button>
      </Link>
    </Box>
  );
};

export default ResourceSection;
