"use client";

import {
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  ErrorAlert,
} from "@/components/common";
import "./FinancialSpecifications.scss";
import { Box, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import {
  FinancialSpecificationProps,
  financialSpecification,
  getValidationSchema,
} from "./FinancialSpecificationsValidation";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useContext, useEffect, useState } from "react";
import { AssetCatalogData } from "../../model";
import { updateAssetCatalog } from "../../ic-config-api-service";
import { IC_ENTITY, shouldUpdateWorkflowStatus } from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type AssetCatalogConfigurationProps = {
  data: {
    formData: AssetCatalogData | null;
    assetCatalogueOptionsData: PostSchema;
  };
};

const FinancialSpecifications = (props: AssetCatalogConfigurationProps) => {
  const toast = useToast();
  const assetCatalogData = props.data?.formData ?? null;
  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.assetCatalogueOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.assetCatalogueOptionsData]);
  const onSubmit = async (
    values: FinancialSpecificationProps,
    actions: any
  ) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: `${props.data.formData?.assetClass}~${props.data.formData?.assetId}`,
          stage: IC_ENTITY.asset_catalog,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    const res = await updateAssetCatalog({
      formData: {
        _vn: assetCatalogData?._vn,
        assetClass: assetCatalogData?.assetClass,
        assetId: assetCatalogData?.assetId,
        // PATCH Fields
        maxPledge: parseFloat(values.maxPledge),
      },
      toastOptions: {
        toast: toast,
        toastId: "financial-specifications",
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (res) {
      navigateToNextPage?.();
    }
  };
  const handleOnChange = (key: string) => (value: string) => {
    setFieldValue(key, value);
  };
  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    dirty,
    touched,
    setFieldValue,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      maxPledge:
        assetCatalogData?.maxPledge?.toString() ??
        financialSpecification.maxPledge,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer customClass="app-form-container">
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the maximum amount pledged?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("maxPledge")
                ?.tooltip_text_main
            }
            labelFor="amount-pledged"
          />
          <AppNumberInput
            value={values.maxPledge}
            onChange={handleOnChange("maxPledge")}
            onBlur={handleBlur}
          />

          {errors.maxPledge && touched.maxPledge && (
            <ErrorAlert>
              <span>{errors.maxPledge}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default FinancialSpecifications;
