"use client";
import {
  Box,
  Stack,
  Heading,
  Text,
  Breadcrumb,
  BreadcrumbItem,
} from "@/components/ChakraUiManager";
import "./OverviewHeader.scss";

type Props = {
  documentTitle: string;
  overview?: string;
  breadcrumbs: string[];
};

export default function OverviewHeader(props: Props) {
  const { documentTitle, overview, breadcrumbs: breadcrumb } = props;
  return (
    <Box className="overview-header">
      <Stack className="text-stack">
        {breadcrumb?.length > 0 && (
          <Breadcrumb separator="|" className="breadcrumb">
            {breadcrumb.map((data, key) => (
              <BreadcrumbItem key={key}>
                <Text className="doc-upper-text">{data.toUpperCase()}</Text>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        )}
        <Heading className="headline" as="h1">
          {documentTitle}
        </Heading>
        {overview && <Text className="subheadline">{overview}</Text>}
      </Stack>
    </Box>
  );
}
