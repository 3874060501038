"use client";

import {
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  ErrorAlert,
  InputText,
} from "@/components/common";

import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Box, useToast } from "@chakra-ui/react";

import {
  AccountTypeFoundationalDetailsValueProps,
  accountTypeFoundationDetails,
  getValidationSchema,
} from "./AccountTypeFoundationalDetailsValidation";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { AccountTypeData } from "../../model";
import {
  createAccountType,
  updateAccountType,
} from "../../ic-config-api-service";
import {
  ACCOUNT_TYPE_STAGE_ORDER,
  DUPLICATE_CONFIG,
  IC_ENTITY,
  NEW_IC_ENTITY_ID,
  SIDEBAR_STATUS,
  useEntityId,
  useQueryParams,
} from "@/utils";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";
import {
  checkIfICWorflowNeedsToBeCreated,
  getIcCopyFlagInStorage,
  setIcCopyFlagInStorage,
} from "../../ic-utils-service";
import { createWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useSearchParams } from "next/navigation";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type AccountTypeFoundationalDetailsProps = {
  data: {
    formData?: AccountTypeData | null;
    acctTypeOptionsData: PostSchema;
  };
};

const AccountTypeFoundationalDetails = (
  props: AccountTypeFoundationalDetailsProps
) => {
  const accountTypeData = props.data?.formData ?? null;
  const toast = useToast();
  const toastId = "account-type-toast";
  const params = useSearchParams();
  const updateQueryParams = useQueryParams();
  const entityId = useEntityId();
  const entityType = params?.get("entityType");
  const isDuplicateConfig = getIcCopyFlagInStorage();

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.acctTypeOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.acctTypeOptionsData]);

  const onSubmit = async (
    values: AccountTypeFoundationalDetailsValueProps,
    actions: any
  ) => {
    if (!dirty) {
      if (
        checkIfICWorflowNeedsToBeCreated(
          entityId,
          entityWorkflowData ?? [],
          IC_ENTITY.account_type
        )
      ) {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: entityId,
          stage: IC_ENTITY.account_type,
          status: getCurrentStageStatusName?.() ?? "",
        });
      }
      routeToNextPage(encodeURIComponent(entityId)); //Encoding entityId because it might have UTF-8 character
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId,
    };
    if (!accountTypeData || isDuplicateConfig) {
      response = await createAccountType({
        formData: {
          ...props.data?.formData,
          acctType: values.acctType,
          desc: values.desc,
          cardLimit: values.cardLimit,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: "Account type created.",
        },

        stageName: isDuplicateConfig
          ? DUPLICATE_CONFIG
          : getCurrentStageStatusName?.() ?? "",
      });
    } else {
      response = await updateAccountType({
        formData: {
          _vn: accountTypeData._vn,
          acctType: values.acctType,
          // PATCH Fields
          desc: values.desc,
          cardLimit: values.cardLimit,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `${configPageTitle} updated.`,
        },
        // Don't udpate workflow for first step of each entity as it will be handled while creating the Entity
        stageName: "",
      });
    }
    if (response) {
      if (
        checkIfICWorflowNeedsToBeCreated(
          entityId,
          entityWorkflowData ?? [],
          IC_ENTITY.account_type
        )
      ) {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: entityId,
          stage: IC_ENTITY.account_type,
          status: getCurrentStageStatusName?.() ?? "",
        });
      }
      setIcCopyFlagInStorage(false);
      routeToNextPage(encodeURIComponent(response.acctType)); //Encoding entityId because it might have UTF-8 character
    }
  };

  /**
   * If Acct type has multiple rel records it Navigate to rel summary otherwise rel foundation
   * @param entityId
   */
  function routeToNextPage(entityId: string) {
    // User navigates to respective page on sidebar click
    if (selectedBtnType === "sidebar") {
      navigateToNextPage?.("sidebar");
      return;
    }
    const isMulRelRecord =
      accountTypeData?.rels?.length && accountTypeData?.rels?.length > 1
        ? true
        : false;
    const baseUrl = `${isMulRelRecord ? ROUTE_PATH.IC_ACCOUNT_TYPES_RELATIONSHIP_SUMMARY : ROUTE_PATH.IC_ACCOUNT_TYPES_RELATIONSHIP_FOUNDATIONAL_DETAILS}`;

    let queryParam;
    if (params?.get("initialFlow")) {
      queryParam = `?${QUERY_PARAM_KEY.ENTITY_ID}=${entityId}&entityType=${IC_ENTITY.account_type}&initialFlow=true`;
    } else {
      queryParam = `?${QUERY_PARAM_KEY.ENTITY_ID}=${entityId}&entityType=${IC_ENTITY.account_type}`;
    }
    const url = `${baseUrl}${queryParam}${!isMulRelRecord ? `&${QUERY_PARAM_KEY.ACCT_TYPE_REL_INDEX}=0` : ""}`;
    updateFormStatus?.(SIDEBAR_STATUS.completed);
    updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
    setSideBarCurrentIndex?.(
      sideBarMainMenuIndex,
      isMulRelRecord
        ? ACCOUNT_TYPE_STAGE_ORDER.acct_type_summary
        : ACCOUNT_TYPE_STAGE_ORDER.acc_type_reln_foundation_detail
    );
    navigateTo(url);
  }

  const handleOnChange = (key: string) => (value: string) => {
    setFieldValue(key, value);
  };

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      acctType: isDuplicateConfig
        ? accountTypeFoundationDetails.acctType
        : accountTypeData?.acctType ?? accountTypeFoundationDetails.acctType,
      desc: accountTypeData?.desc ?? accountTypeFoundationDetails.desc,
      cardLimit:
        accountTypeData?.cardLimit ?? accountTypeFoundationDetails?.cardLimit,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    sideBarMainMenuIndex,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    getCurrentStageStatusName,
    setSideBarCurrentIndex,
    entityWorkflowData,
    tooltipFlyoutDetails,
    selectedBtnType,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
    if (isGoingBack) {
      const backEntityId = entityWorkflowData?.find(
        (entity) => entity.stage === IC_ENTITY.asset_catalog
      )?.modelKey;
      navigateTo(
        `${ROUTE_PATH.IC_ASSET_GENERAL_LEDGER_DETAILS}?entityId=${backEntityId ?? NEW_IC_ENTITY_ID}`
      );
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer customClass="app-form-container">
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the account type name?"
            isRequired={true}
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("acctType")
                ?.tooltip_text_main
            }
          />
          <InputText
            value={values.acctType}
            onChange={handleOnChange("acctType")}
            onBlur={handleBlur}
            label="Account type name"
            name="account type name"
            disabled={Boolean(
              props.data.formData?.acctType && !isDuplicateConfig
            )}
          />
          {errors.acctType && touched.acctType && (
            <ErrorAlert>
              <span>{errors.acctType}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Describe the account type"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("desc")?.tooltip_text_main
            }
          />
          <InputText
            value={values.desc}
            onChange={handleOnChange("desc")}
            onBlur={handleBlur}
            label="Acount type description"
            name="account type description"
          />
          {errors.desc && touched.desc && (
            <ErrorAlert>
              <span>{errors.desc}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppNumberInput
            labelName="What is the active card limit for the account type?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("cardLimit")
                ?.tooltip_text_main
            }
            value={values.cardLimit}
            onChange={handleOnChange("cardLimit")}
            onBlur={handleBlur}
            label="Card limit"
          />
          {errors.cardLimit && touched.cardLimit && (
            <ErrorAlert>
              <span>{errors.cardLimit}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default AccountTypeFoundationalDetails;
