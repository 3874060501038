"use client";
import { useContext, useEffect, useMemo, useState } from "react";
import PostFrequencyStyle from "./PostFrequencyRounding.module.scss";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  CoupleRadioWithDropdown,
  ErrorAlert,
  FrequencyModule,
  RadioButtonGroup,
} from "@/components/common";
import { Box, CardBody, useToast } from "@/components/ChakraUiManager";
import SplitButton, {
  SplitBtnInputValType,
} from "@/components/common/split-button/SplitButton";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  PromotionalRateFrequencyProps,
  promotionalRateFrequency,
  getValidationSchema,
} from "./PostFrequencyValidation";
import { useFormik } from "formik";
import {
  ERROR_MESSAGE,
  INTERNAL_SCHEMA_TYPE,
} from "@/components/data/form-data";
import {
  InterestComponentData,
  OptionsSchema,
  PostSchema,
} from "../../../model/types";

import {
  addNewComponent,
  createInterestComponent,
} from "../../product-config-client-service";
import {
  getConstructedFeatureNameWithComponent,
  preventMainFormSubmitOnRJSFSubmit,
} from "@/utils/common";
import { COMPONENT_CLASS, RjsfData } from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import { matrixTypeUiSchema } from "@finxact/finxact-shared-ui";

interface PostFrequencyProps {
  postingRoundOption: INTERNAL_SCHEMA_TYPE<string>[];
  formData: InterestComponentData | null;
  interestComponentOptionsData: PostSchema;
  matrixDDOptions: string[];
  addNewMatrixSchema: OptionsSchema;
  addNewMatrixEndpoint: string;
}
const PostFrequencyRounding = (props: { data: PostFrequencyProps }) => {
  const {
    formData,
    addNewMatrixEndpoint,
    addNewMatrixSchema,
    interestComponentOptionsData,
  } = props.data;
  const toast = useToast();

  // Form Submit Handler
  const onSubmit = async (
    values: PromotionalRateFrequencyProps,
    actions: any
  ) => {
    // Update only if component data exists and form has been touched
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["interest"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (formData && dirty) {
      if (productDetails) {
        const res = await createInterestComponent({
          productDetails,
          formData: {
            ...formData,
            // PATCH fields
            postRoundOpt: values.postRoundOpt,
            isPostNetInt: values.isPostNetInt,
            postFreq: values.postFreq,
            postFreqMatrix: values.postFreqMatrix,
          },
          toastOptions: {
            toast,
            toastId: "index-rate-post-frequency-roundingoff-detail",
            successMessage: `${
              tooltipFlyoutDetails?.pageHeaderDetails?.heading
                ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
                : "Introduction Rate - Posting Frequency & Rounding"
            } updated`,
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    }
  };

  // Schema to pass into RJSF (Add New flow)
  const schema = useMemo(() => {
    const { actions, name } = addNewMatrixSchema;
    return { name, ...actions.POST };
  }, [addNewMatrixSchema]);

  const addNewMatrix = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewMatrixEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("postFreqMatrix", response.matrixName);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(interestComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [interestComponentOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      isPostNetInt: formData?.isPostNetInt ?? undefined,
      postFreq: formData?.postFreq ?? promotionalRateFrequency.postFreq,
      postFreqMatrix:
        formData?.postFreqMatrix ?? promotionalRateFrequency.postFreqMatrix,
      postRoundOpt: formData?.postRoundOpt ?? null,
    },
  });
  // using useFormik hook from Formik Library
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
    setTouched,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    sideBarMainMenuIndex,
    sideBarMenuList,
    navigateTo,
    navigateToNextPage,
    updateFormStatus,
    setSideBarCurrentIndex,
    updateStatusInSideBarMenuList,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  //Form_Data_Handler
  const postFrequencyRoundingHandler =
    (key: string) =>
    (value: string | number | SplitBtnInputValType | boolean) => {
      if (key === "isPostNetInt") {
        setFieldValue(key, value).then((res) => {
          setFieldValue("postRoundOpt", null);
        });
      } else {
        setFieldValue(key, value);
      }
    };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    // If no promoDtl nominal rate is given in "Promo Rate - Foundational Details" when back is clicked go to "Promo Rate - Foundational Details" page
    if (isGoingBack && props.data.formData?.promoDtl?.nomRate === undefined) {
      navigateToNextPage?.();
      return;
    } else if (isGoingBack) {
      const upcomingSubMenuIndex = sideBarMenuList?.[
        sideBarMainMenuIndex
      ]?.subMenuList?.findIndex(
        (item) => item.stageStatus === "promotionalRateFoundationDetails"
      );
      setSideBarCurrentIndex?.(sideBarMainMenuIndex, upcomingSubMenuIndex ?? 0);
      navigateTo(
        `${ROUTE_BASE_URL["DASHBOARD_PATH"]}${ROUTE_PATH["INTEREST_LOAN_PROMOTIONAL_FOUND_DETAIL_FORM"]}?productName=${productDetails?.name}`
      );
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id="finxact-form"
    >
      <CommonTitle
        depositName={getConstructedFeatureNameWithComponent(
          "componentInt",
          getIfxProdType(productDetails),
          true
        )}
        featureName={`${formData?.componentName ?? "[COMPONENT_NAME"}`}
        version={`VERSION ${formData?.version ?? "1"}`}
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading
            ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
            : "Introduction Rate - Posting Frequency & Rounding"
        }
        subText={
          tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
            ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
            : ""
        }
      />
      <Box className={"app-form-container"}>
        <CardContainer
          customClass={
            PostFrequencyStyle["post-frequency-and-rounding-container"]
          }
        >
          <CardBody>
            <Box>
              <AppFormLabel
                labelName="Is there interest posting?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("isPostNetInt")
                    ?.tooltip_text_main
                }
              />
            </Box>
            <SplitButton
              onSelect={postFrequencyRoundingHandler("isPostNetInt")}
              leftBtnName="Yes"
              rightBtnName="No"
              value={values.isPostNetInt}
            />
            {errors.isPostNetInt && (
              <ErrorAlert>
                <span>{errors.isPostNetInt}</span>
              </ErrorAlert>
            )}

            {/* Below section will render conditionally
             * based on user selection Yes or No
             * frequency module section contain frequency molecule and RadioGroup Molecule
             */}
            <Box className={PostFrequencyStyle["frequency-module-container"]}>
              <AppFormLabel
                labelName="What is the posting frequency?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("postFreq")
                    ?.tooltip_text_main
                }
              />

              <CoupleRadioWithDropdown
                id="promotional_rate_offset"
                primaryOptionValue={values.postFreqMatrix}
                tertiaryOptionValue={values.postFreq}
                tertiaryFieldType="frequency"
                primaryRadioName={"Leverage existing matrix"}
                secondaryRadioName={"Add new matrix"}
                tertiaryRadioName={"Enter a single frequency"}
                handlePrimaryOptionSelect={
                  postFrequencyRoundingHandler("postFreqMatrix") as any
                }
                handleTertiaryOption={postFrequencyRoundingHandler("postFreq")}
                monthDDTooltip={
                  tooltipFlyoutDetails?.tooltipsMap?.get("postFreqMon")
                    ?.tooltip_text_main
                }
                businessDayTooltip={
                  tooltipFlyoutDetails?.tooltipsMap?.get("postFreqNotBusiness")
                    ?.tooltip_text_main
                }
                placeHolderName={"Select matrix"}
                dropdownList={props.data.matrixDDOptions}
                schema={schema}
                uiSchema={matrixTypeUiSchema}
                onAddNewSubmit={addNewMatrix}
                modelViewUrl={API_ROUTE_CONFIGURATION.matrix}
              />
              {errors.postFreq && touched.postFreq && (
                <ErrorAlert>
                  <span>{errors.postFreq}</span>
                </ErrorAlert>
              )}
              {errors.postFreqMatrix && touched.postFreqMatrix && (
                <ErrorAlert>
                  <span>{errors.postFreqMatrix}</span>
                </ErrorAlert>
              )}
            </Box>
            {values.isPostNetInt && (
              <Box className={PostFrequencyStyle["frequency-module-container"]}>
                <Box className={PostFrequencyStyle["app-label-seperator"]}>
                  <AppFormLabel
                    labelName="Select an interest posting round option."
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("postRoundOpt")
                        ?.tooltip_text_main
                    }
                  />
                </Box>
                <RadioButtonGroup
                  isBoxedRadio={true}
                  value={values.postRoundOpt ?? ""}
                  onChange={postFrequencyRoundingHandler("postRoundOpt")}
                  radioPropList={props.data.postingRoundOption}
                  stackDirection={"row"}
                  spacing={"2rem"}
                  onBlur={handleBlur}
                />
                {errors.postRoundOpt && touched.postRoundOpt && (
                  <ErrorAlert>
                    <span>{errors.postRoundOpt}</span>
                  </ErrorAlert>
                )}
              </Box>
            )}
          </CardBody>
        </CardContainer>
      </Box>
    </form>
  );
};
// Exporting Interface
export type { PromotionalRateFrequencyProps };
export default PostFrequencyRounding;
