"use client";

import React, { useState, useEffect } from "react";
import {
  Checkbox,
  VStack,
  Divider,
  Text,
  Box,
  useBreakpointValue, 
  IconButton,
  HStack
} from "@/components/ChakraUiManager";
import { CloseIcon } from "@chakra-ui/icons";
import { getTutorialFilterGroups } from "@/prismic-api/tutorial-service";
import { useSelector } from "react-redux";
import { getAppPrismicClient } from "@/store";

interface FilterGroup {
  name: string;
  tags: string[];
}

interface FilterData {
  groups: FilterGroup[];
}

interface TutorialsTagsFilterProps {
  selectedTags: string[];
  handleTagChange: (tag: string) => void;
  clearFilters: () => void;
  page: string;
  setShowFilters: () => void;
}

const TutorialsTagsFilter: React.FC<TutorialsTagsFilterProps> = ({
  selectedTags,
  handleTagChange,
  clearFilters,
  setShowFilters
}) => {
  const prismicClient = useSelector(getAppPrismicClient);
  const [filters, setFilters] = useState<FilterData | null>(null);

  // Fetch filters
  const getFilters = async () => {
    const filtersData = await getTutorialFilterGroups(prismicClient);
    setFilters(filtersData);
  };

  useEffect(() => {
    getFilters();
  }, []);

  // Determine the width based on the screen size (full width for base/sm and default width for larger screens)
  const filterWidth = useBreakpointValue({ base: "100%", md: "auto" });
  const isMobile = useBreakpointValue({ base: true, md: false }); // Detect mobile


  return (
    <Box 
      className="tutorials-filter"
      width={filterWidth} // Apply the responsive width
      p={4} // Optional padding for better spacing
    >
      {filters && (
        <VStack
          className="full-group"
          gap={0}
          alignItems="flex-start"
          divider={<Divider className="divider" />}
          width="100%" // Ensure the content stretches full width inside its container
        >
       <HStack justifyContent="space-between" width="100%">
            <Text className="header" as="h5">
              Tag Filters
            </Text>

            {isMobile && (
              <IconButton
                aria-label="Close filters"
                icon={<CloseIcon />}
                size="sm"
                onClick={() => setShowFilters()}  // Close filters when clicked
                variant="ghost"
              />
            )}
          </HStack>
          {filters.groups.map((group, key) => (
            <Box key={key} width="100%">
              <Text className="group-name">{group.name}</Text>
              <VStack alignItems="flex-start" width="100%">
                {group.tags.map((tag, tagKey) => (
                  <Box key={tagKey} mt="4" className="label" width="100%">
                    <Checkbox
                      isChecked={selectedTags.includes(tag)}
                      onChange={() => handleTagChange(tag)}
                      width="100%" // Ensure the checkbox spans full width if necessary
                    >
                      {tag}
                    </Checkbox>
                  </Box>
                ))}
              </VStack>
            </Box>
          ))}
          <Box alignItems="flex-start" width="100%">
            <button onClick={clearFilters} className="clear">
              Clear Filters
            </button>
          </Box>
        </VStack>
      )}
    </Box>
  );
};

export default TutorialsTagsFilter;
