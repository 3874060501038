import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils";
import * as Yup from "yup";

//Defining Interface For Index_Rate_Foundation_Details
interface IndexRateFoundationProps {
  addindexRateDetails: SplitBtnInputValType;
  index: {
    indexName: string;
    firstDur: string;
  };
  nomRate: string;
}
// Defining Intitial Value For Index_Rate_Foundation_Details
const indexRateFoundationDetails: IndexRateFoundationProps = {
  addindexRateDetails: undefined,
  index: {
    indexName: "",
    firstDur: "",
  },
  nomRate: "",
};
// YUP LIBRARY :Defining Schema For validation of Index_Rate_Foundation_Details
const indexRateFoundationDetailSchema = async (optionsSchema: PostSchema) => {
  const fields = ["index", "nomRate"];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);

  return Yup.object().shape(shapeConfig);
};
// Exporting Interface
export type { IndexRateFoundationProps };
// Exporting Validation Schema and Intial value
export {
  indexRateFoundationDetails,
  indexRateFoundationDetailSchema as getValidationSchema,
};
