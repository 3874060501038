"use client";
import DocumentList from "./landing-page/doc-list/DocumentList";
import DocCenterExplore from "./landing-page/doc-center-explore/DocCenterExplore";

import { useUserAuthenticated, useUserInfo } from "@/app/providers";
import { FooterNotLoggedInUser, FooterWidgetIframe } from "@/components/common";
import { docCenterConfigType } from "@/prismic-api/document-service";
import { Content } from "@prismicio/client";
import { Box, Show } from "@chakra-ui/react";
import WelcomeBanner from "@/components/welcome-banner/WelcomeBanner";
import { SVGDocumentCenterBannerImage } from "public/assets";

function DocumentCenter(props: {
  recentlyViewed: Content.AllDocumentTypes[];
  docCenterConfig: docCenterConfigType;
}) {
  const isUserAuthenticated = useUserAuthenticated();
  const { user } = useUserInfo();

  const recentlyViewedHeaderData =
    props.docCenterConfig?.recently_viewed_docs?.[0] || null;
  const recentlyViewedList =
    props.recentlyViewed?.map((document: any) => ({
      title: document.data?.article_main_title[0]?.text,
      description: document.data?.flyout_subhead,
      url: document.uid,
      type: document.type,
    })) ?? [];

  return (
    <>
      <WelcomeBanner
        name={isUserAuthenticated ? user.firstName : ""}
        bannerData={props.docCenterConfig?.doc_center_hero[0] ?? {}}
        isUserAuthenticated={isUserAuthenticated}
        imageSrc={SVGDocumentCenterBannerImage}
      />
      {props.docCenterConfig?.three_up_featured_documents && (
        <DocCenterExplore
          {...props.docCenterConfig.three_up_featured_documents}
        />
      )}
      {recentlyViewedList?.length > 0 && (
        <DocumentList
          title={recentlyViewedHeaderData?.headline || "Recently viewed docs"}
          subTitle={
            recentlyViewedHeaderData?.subhead ||
            "Refer back to the latest docs you've taken a look at"
          }
          docList={recentlyViewedList}
        />
      )}
      {props.docCenterConfig?.recommendedDocs && (
        <DocumentList {...props.docCenterConfig.recommendedDocs} />
      )}
      {/* Hide for mobile */}
      <Show above="md">
        <FooterWidgetIframe />
        {!isUserAuthenticated && <FooterNotLoggedInUser />}
      </Show>
    </>
  );
}
export default DocumentCenter;
