"use client";
import {
  getEnvSpecificCoreRoles,
  resetUserPassword,
} from "@/api-config/api-service";
import {
  AccordionComponent,
  AddNewButton,
  AppFormLabel,
  AppModal,
  SelectDropdown,
  Badges,
  CardContainer,
  ErrorAlert,
  InputText,
  MultiSelectCheckBox,
  RadioButtonGroup,
  StaticText,
} from "@/components/common";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import { MAIN_FORM_ID, TAB_NAMES } from "@/utils";
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import Link from "next/link";
import { useContext, useRef, useState } from "react";
import {
  createNewUser,
  deleteUser,
  editUser,
} from "../access-management-api-service";
import "./CreateOrEditTeam.scss";
import { useRouter, useSearchParams } from "next/navigation";
import Image from "next/image";
import { accordionDeleteIcon } from "public/assets";
import {
  CONSOLE_ROLE_LIST_TABLE_COLUMN_DEF,
  CORE_ROLES_ENV_TABLE_COLUMN_DEF,
  CORE_ROLES_TABLE_COLUMN_DEF,
  NEW_ORG_OVERIDE_MAPPING,
  ORG_PROFILE_TEMPLATE_COLUMN_DEF,
} from "@/data/access-management-data";
import { REMOVE_USER_RADIO_LIST } from "@/components/data/data";
import { validationSchema } from "./CreateOrEditTeamValidation";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useSelector } from "react-redux";
import { getOrgID } from "@/store";

type DropdownOptionsType = {
  label: string;
  value: string;
  orgId?: string;
};
interface TeamFormProps {
  data: {
    profileTemplateOptions: DropdownOptionsType[];
    formData: any;
    profileTemplates: any;
    organizations: any;
    organizationsOptionsList: DropdownOptionsType[];
    consoleRoles: any[];
    supplementalRoles: any[];
    environments: DropdownOptionsType[];
  };
}

type Environment = {
  envId: number | null;
  coreRoles: string[];
  supplementalRoles: any[];
};

type Organization = {
  orgId: number | null;
  profileTemplates: any[];
  consoleRoles: any[];
  environments: any[];
};

const initialEnvironmentValues: Environment = {
  envId: null,
  coreRoles: [],
  supplementalRoles: [],
};

const initialOrganizationsValues: Organization = {
  orgId: null,
  profileTemplates: [],
  consoleRoles: [],
  environments: [initialEnvironmentValues],
};

const CreateOrEditTeam = (props: TeamFormProps) => {
  const [envsCoreRoles, setEnvsCoreRoles] = useState<any>({});
  const [templateToBeEdited, setTemplateToBeEdited] = useState("");
  const [currentModal, setCurrentModal] = useState("");
  const [radioValue, setRadioValue] = useState<any>("");

  const { data } = props;
  const toast = useToast();
  const params = useSearchParams();
  const router = useRouter();
  const userId = params?.get("id")!;
  const toastId = "user";
  const modalRef = useRef<any>();
  const orgId = useSelector(getOrgID);
  const { tooltipFlyoutDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const onSubmit = async (values: any, action: any) => {
    if (!dirty && userId) {
      router.push(`${ROUTE_BASE_URL.ACCESS_MANAGEMENT}`);
      return;
    }

    const payload = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      login: values.email,
    };

    const profileTemplate = constructProfileTemplatePayload();
    let response;
    if (!userId) {
      response = await createNewUser({
        payload,
        profileTemplate,
        userData: data?.formData,
        toastOptions: {
          toast,
          toastId,
          successMessage: "User created successfully",
        },
      });
    } else {
      response = await editUser({
        orgId,
        payload,
        profileTemplate,
        userData: data?.formData,
        toastOptions: {
          toast,
          toastId,
          successMessage: "User edited successfully",
        },
      });
    }

    if (response) {
      router.push(`${ROUTE_BASE_URL.ACCESS_MANAGEMENT}`);
    }
  };

  const constructInitialValuesForOrg = () => {
    let organizations: any[] = [];
    if (data?.formData?.profileTemplates) {
      const orgs = Object.keys(data?.formData?.profileTemplates);
      orgs.forEach((org: any) => {
        const orgSpecificProfileTemplates =
          data?.formData?.profileTemplates[org];
        if (orgSpecificProfileTemplates?.length) {
          let organization = { ...initialOrganizationsValues };
          orgSpecificProfileTemplates.forEach((profileTemplate: any) => {
            if (profileTemplate?.type?.toLowerCase() === "user") {
              profileTemplate.environments.forEach(async (env: any) => {
                if (!envsCoreRoles[env.envID]) {
                  const res = await getEnvSpecificCoreRoles({
                    id: env.envID,
                  });
                  if (res) {
                    setEnvsCoreRoles((precState: any) => {
                      let stateToBeUpdated = precState;
                      stateToBeUpdated[env.envID] = res?.data;
                      return stateToBeUpdated;
                    });
                  }
                }
              }),
                (organization = {
                  ...organization,
                  consoleRoles: profileTemplate.roles.map((role: any) =>
                    role?.id?.toString()
                  ),
                  environments: profileTemplate.environments.map(
                    (env: any) => ({
                      envId: env.envID,
                      coreRoles: env.coreRoles,
                      supplementalRoles: env.roles.map((role: any) =>
                        role?.id?.toString()
                      ),
                    })
                  ),
                });
            } else {
              organization = {
                ...organization,
                orgId: Number(org),
                profileTemplates: [
                  ...organization.profileTemplates,
                  profileTemplate?.id?.toString(),
                ],
              };
            }
          });
          organizations.push(organization);
        }
      });
    }

    return organizations?.length ? organizations : [initialOrganizationsValues];
  };

  const formikConfigs = useFormik({
    onSubmit,
    initialValues: {
      firstName: data?.formData?.firstName ?? "",
      lastName: data?.formData?.lastName ?? "",
      email: data?.formData?.email ?? "",

      password: data?.formData?.password ?? "password",
      status: data?.formData?.Status ?? "",

      organizations: constructInitialValuesForOrg(),
    },
    validationSchema,
  });

  const {
    handleSubmit,
    values,
    setFieldValue,
    handleBlur,
    dirty,
    errors,
    touched,
  } = formikConfigs;

  const constructProfileTemplatePayload = () => {
    let profileTemplateToBeModified = data?.formData?.profileTemplates;
    if (profileTemplateToBeModified) {
      let keys = Object.keys(profileTemplateToBeModified);
      keys = keys.filter((key) =>
        values.organizations.some((org) => org.orgId !== Number(key))
      );
      keys.forEach((key) => {
        if (profileTemplateToBeModified.hasOwnProperty(key)) {
          delete profileTemplateToBeModified[key];
        }
      });
    }

    values.organizations?.forEach((org: any) => {
      if (profileTemplateToBeModified?.[org.orgId]?.length) {
        profileTemplateToBeModified[org.orgId] = [
          ...(updateProfileTemplates(
            profileTemplateToBeModified?.[org.orgId] ?? [],
            org.profileTemplates
          ) ?? []),
        ];
        if (org?.consoleRoles?.length || org?.environments?.length) {
          const index = profileTemplateToBeModified[org.orgId]?.findIndex(
            (item: any) => item?.type?.toLowerCase() === "user"
          );
          if (index > -1) {
            let updatedIndexData =
              profileTemplateToBeModified[org.orgId][index];
            const updatedRoles = updateOverrideConsoleRoles(org.consoleRoles);

            let orgEnvToBeUpdated = org.environments;

            orgEnvToBeUpdated = orgEnvToBeUpdated.map((env: any) => {
              return {
                envID: env.envId,
                coreRoles: env.coreRoles,
                roles: updateEnvSpecificCoreRoles(env.supplementalRoles),
              };
            });
            updatedIndexData = {
              ...updatedIndexData,
              roles: updatedRoles,
              environments: orgEnvToBeUpdated,
            };
            profileTemplateToBeModified[org.orgId][index] = updatedIndexData;
          }
        }
      } else {
        const updatedRoles = updateOverrideConsoleRoles(org.consoleRoles);
        let orgEnvToBeUpdated = org.environments;

        orgEnvToBeUpdated = orgEnvToBeUpdated.map((env: any) => {
          return {
            envID: env.envId,
            coreRoles: env.coreRoles,
            roles: updateEnvSpecificCoreRoles(env.supplementalRoles),
          };
        });
        let profileTemplatesForOrg =
          updateProfileTemplates([], org.profileTemplates) ?? [];
        profileTemplateToBeModified[org.orgId] = [
          ...profileTemplatesForOrg,
          {
            ...NEW_ORG_OVERIDE_MAPPING,
            orgId: org.orgId,
            roles: updatedRoles,
            environments: orgEnvToBeUpdated,
          },
        ];
      }
    });
    return profileTemplateToBeModified;
  };

  const updateEnvSpecificCoreRoles = (addedRoles: any) => {
    return addedRoles.map((role: any) =>
      data?.supplementalRoles.find((supRole) => supRole.id === Number(role))
    );
  };

  const updateProfileTemplates = (
    currentTemplates: any[],
    addedProfileTemplates: any[]
  ) => {
    let profileTemplateToBeUpdated = currentTemplates;
    if (profileTemplateToBeUpdated?.length) {
      addedProfileTemplates.forEach((template: any) => {
        if (
          !profileTemplateToBeUpdated?.some(
            (item: any) => item.id === Number(template)
          )
        ) {
          const currentTemplate = data?.profileTemplates?.find(
            (profileTemplate: any) => profileTemplate.id === Number(template)
          );
          if (currentTemplate) profileTemplateToBeUpdated.push(currentTemplate);
        }
      });
    } else {
      addedProfileTemplates.forEach((template: any) => {
        const currentTemplate = data?.profileTemplates?.find(
          (profileTemplate: any) => profileTemplate.id === Number(template)
        );

        if (currentTemplate) profileTemplateToBeUpdated?.push(currentTemplate);
      });
    }
    return profileTemplateToBeUpdated;
  };

  const updateOverrideConsoleRoles = (addedRoles: any[]) => {
    let rolesToBeUpdate: any = [];
    addedRoles?.forEach((role) => {
      let roleToBePushed = [];
      roleToBePushed = data?.consoleRoles?.find((item) => {
        return item.id === Number(role);
      });
      if (roleToBePushed) rolesToBeUpdate.push(roleToBePushed);
    });
    return rolesToBeUpdate;
  };

  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };

  const handleOrganizationChange =
    (key: string, index: number) => async (value: any) => {
      if (
        key?.includes("envId") &&
        values.organizations[index]?.orgId &&
        !envsCoreRoles[values?.organizations[index].orgId]
      ) {
        const res = await getEnvSpecificCoreRoles({ id: value });
        if (res) {
          setEnvsCoreRoles((prevState: any) => {
            let stateToBeUpdated = prevState;
            stateToBeUpdated[value] = res?.data;
            return stateToBeUpdated;
          });
        }
      }

      setFieldValue(`organizations[${index}].${key}`, value);
    };

  const showEditTemplateModal = (row: any) => {
    setTimeout(() => {
      if (modalRef?.current) {
        modalRef.current.openModal();
        setCurrentModal("edit");
        setTemplateToBeEdited(row?.id);
      }
    });
  };

  const showResetPasswordModal = () => {
    setTimeout(() => {
      if (modalRef?.current) {
        modalRef.current.openModal();
        setCurrentModal("reset");
      }
    });
  };

  const closeModal = () => {
    setTimeout(() => {
      if (modalRef?.current) {
        modalRef.current.closeModal();
        setRadioValue("");
        setTemplateToBeEdited("");
      }
    });
  };

  const handleDeletUserPrimaryButtonClick = () => {
    deleteUser({
      id: userId,
      forceDelete: radioValue === 2 ? true : false,
      toastOptions: {
        toast,
        toastId,
        successMessage: "User deleted successfully",
      },
    }).then((res) => {
      router.push(`${ROUTE_BASE_URL.ACCESS_MANAGEMENT}?tab=${TAB_NAMES.team}`);
    });
  };

  const handleEditPrimaryButtonClick = () => {
    router.push(`${ROUTE_PATH.AM_PROFILE_TEMPLATE}?id=${templateToBeEdited}`);
    closeModal();
  };

  const handleResetPrimaryButtonClick = () => {
    resetUserPassword({ id: userId ?? 0 })
      .then((res) => {
        toast({
          id: toastId,
          description: "Password has been reset",
          status: "success",
        });
      })
      .catch((err) => {
        toast({
          id: toastId,
          description: "Password reset was unsuccessful",
          status: "error",
        });
      });
    closeModal();
  };

  const showDeleteUserModal = () => {
    setTimeout(() => {
      if (modalRef?.current) {
        modalRef.current.openModal();
        setCurrentModal("delete");
      }
    });
  };

  const formTitle = userId ? "Edit user" : "Create user";

  const userName = userId ? (
    <Flex justifyContent="space-between" mb={8}>
      <Heading>{`${data?.formData?.firstName} ${data?.formData?.lastName}`}</Heading>
      <Button className="icon-button" onClick={showDeleteUserModal}>
        <Image src={accordionDeleteIcon} alt="delete icon" />
      </Button>
    </Flex>
  ) : (
    ""
  );

  const MODALS: any = {
    edit: {
      modalTitle: "This profile template may currently be in use",
      decription:
        "Any updates to this profile template’s settings will be applied globally to any Team member it is assigned to.",
      primaryButtonName: "Proceed",
      secondaryButtonName: "Cancel",
      primaryButtonClick: handleEditPrimaryButtonClick,
      secondaryButtonClick: closeModal,
    },
    reset: {
      modalTitle:
        "When resetting a user's password, an email will be sent to this user with a one time link which will allow them to complete the reset password workflow.",
      decription: (
        <Flex direction="column" rowGap={8} fontSize={16}>
          <Text>
            Please note, once initiated, the user will be unable to initiate the
            forgotten password workflow. Additionally, the generated link is
            only valid for one hour.
          </Text>{" "}
          <Text>Are you sure you want to perform the password reset?</Text>
        </Flex>
      ),
      primaryButtonName: "Reset",
      secondaryButtonName: "Cancel",
      primaryButtonClick: handleResetPrimaryButtonClick,
      secondaryButtonClick: closeModal,
    },
    delete: {
      modalTitle: `Are you sure you want to remove ${data?.formData?.firstName} ${data?.formData?.lastName}?`,
      decription: (
        <RadioButtonGroup
          value={radioValue}
          onChange={setRadioValue}
          radioPropList={REMOVE_USER_RADIO_LIST}
          stackDirection={"column"}
          spacing={3.5}
          isBoxedRadio={true}
          customClass="radio-button"
        />
      ),
      primaryButtonName: "Yes, remove",
      secondaryButtonName: "Cancel",
      primaryButtonClick: handleDeletUserPrimaryButtonClick,
      secondaryButtonClick: closeModal,
    },
  };

  const profileTemplateTableFooterComponent = (
    <span className="navigation-link">
      Don’t see the right profile template?{" "}
      <Link className="app-btn-link" href={`${ROUTE_PATH.AM_PROFILE_TEMPLATE}`}>
        Create a new one
      </Link>{" "}
      or edit one of the profile templates above via the pencil icon.
    </span>
  );

  return (
    <Box className="app-form-container">
      <FormikProvider value={formikConfigs}>
        <form onSubmit={handleSubmit} noValidate id={MAIN_FORM_ID}>
          <Stack gap={12.8}>
            <Text as="h3">{formTitle}</Text>
            <CardContainer>
              {userName}
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="First name"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("firstName")
                      ?.tooltip_text_main
                  }
                />
                <InputText
                  value={values.firstName}
                  onChange={handleOnChange("firstName")}
                  onBlur={handleBlur}
                  label="First name"
                  name="firstName"
                />
                {errors.firstName && touched.firstName && (
                  <ErrorAlert>
                    <span>{ERROR_MESSAGE}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="Last name"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("lastName")
                      ?.tooltip_text_main
                  }
                />
                <InputText
                  value={values.lastName}
                  onChange={handleOnChange("lastName")}
                  onBlur={handleBlur}
                  label="Last name"
                  name="lastName"
                />
                {errors.lastName && touched.lastName && (
                  <ErrorAlert>
                    <span>{ERROR_MESSAGE}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="Email"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("email")
                      ?.tooltip_text_main
                  }
                />
                <InputText
                  value={values.email}
                  onChange={handleOnChange("email")}
                  onBlur={handleBlur}
                  label="Email"
                  name="email"
                />
                {errors.email && touched.email && (
                  <ErrorAlert>
                    <span>{errors.email.toString()}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Flex
                className="app-form-field-container"
                direction="column"
                rowGap={6.4}
              >
                <Heading>
                  {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
                    ?.section_title || "Security"}
                </Heading>
                <Flex columnGap={64} alignItems="center">
                  {values.status && (
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="Status"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("status")
                            ?.tooltip_text_main
                        }
                      />
                      {userId ? (
                        <Badges
                          label={values.status}
                          type={values.status.toLowerCase()}
                        />
                      ) : (
                        "No status available"
                      )}
                    </Box>
                  )}
                  <Box className="app-form-field-container">
                    <AppFormLabel
                      labelName="Password"
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("password")
                          ?.tooltip_text_main
                      }
                    />
                    <Flex
                      columnGap={3.2}
                      alignItems="flex-end"
                      justifyContent="center"
                    >
                      <Text>{"*".repeat(values.password?.length)}</Text>
                      <Button
                        className="app-btn-link reset-button"
                        onClick={showResetPasswordModal}
                      >
                        Reset
                      </Button>
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
            </CardContainer>
            <Box>
              <FieldArray
                name="organizations"
                render={({ insert, remove, push }) => (
                  <>
                    {values.organizations.map(
                      (organization: any, index: number) => (
                        <CardContainer key={`organizations${index}`}>
                          <AccordionComponent
                            accordionTitle={`Organization ${index + 1}`}
                            deleteIcon
                            onDeleteHandler={() => remove(index)}
                            isExpand={
                              values.organizations.length !== index + 1 ? 1 : 0
                            }
                          >
                            <Box className="app-form-field-container">
                              <AppFormLabel
                                labelName="Organization"
                                isRequired={true}
                                labelFor={`Organiazation${index}_input`}
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "orgId"
                                  )?.tooltip_text_main
                                }
                              />
                              <SelectDropdown
                                dropdownList={data?.organizationsOptionsList}
                                value={organization.orgId}
                                onChange={handleOrganizationChange(
                                  "orgId",
                                  index
                                )}
                                placeholder="Select"
                                id={`Organization${index}`}
                                clearable={false}
                              />
                            </Box>
                            {organization.orgId && (
                              <>
                                <Box className="app-form-field-container">
                                  <AppFormLabel
                                    labelName="Select the profile template(s) for this organization"
                                    isRequired={true}
                                    tooltipDesc={
                                      tooltipFlyoutDetails?.tooltipsMap?.get(
                                        "profileTemplates"
                                      )?.tooltip_text_main
                                    }
                                  />
                                  <MultiSelectCheckBox
                                    data={data?.profileTemplates.filter(
                                      (template: any) =>
                                        template.orgId === organization.orgId
                                    )}
                                    selectedIds={organization.profileTemplates}
                                    setSelectedIds={handleOrganizationChange(
                                      "profileTemplates",
                                      index
                                    )}
                                    keyName="id"
                                    name="profileTemplate"
                                    columnDef={ORG_PROFILE_TEMPLATE_COLUMN_DEF}
                                    onEditButtonClick={showEditTemplateModal}
                                    footerComponent={
                                      profileTemplateTableFooterComponent
                                    }
                                  />
                                </Box>
                                <Flex
                                  className="override-container"
                                  direction="column"
                                  rowGap={6.4}
                                >
                                  <StaticText textValue="Overrides" />
                                  <Box className="app-form-field-container">
                                    <AppFormLabel
                                      labelName="Select any Console role overrides"
                                      isRequired={true}
                                      tooltipDesc={
                                        tooltipFlyoutDetails?.tooltipsMap?.get(
                                          "consoleRoles"
                                        )?.tooltip_text_main
                                      }
                                    />
                                    <MultiSelectCheckBox
                                      data={data?.consoleRoles}
                                      selectedIds={organization.consoleRoles}
                                      setSelectedIds={handleOrganizationChange(
                                        "consoleRoles",
                                        index
                                      )}
                                      keyName="id"
                                      columnDef={CORE_ROLES_TABLE_COLUMN_DEF}
                                    />
                                  </Box>
                                </Flex>
                              </>
                            )}

                            {/*  this is the field array for environment this should be rendered inside the organization field array*/}
                            <Box className="app-form-field-container">
                              <AppFormLabel
                                labelName="Environment overrides"
                                isRequired={true}
                              />
                              <FieldArray
                                name={`organizations[${index}].environments`}
                                render={({ insert, remove, push }) => (
                                  <>
                                    {organization.environments?.map(
                                      (env: any, idx: number) => (
                                        <Box
                                          key={idx}
                                          className="environment-container"
                                        >
                                          <Flex className="environment-heading">
                                            <Heading as={"h3"}>
                                              Environment {idx + 1}{" "}
                                            </Heading>
                                            <Image
                                              onClick={() => remove(idx)}
                                              src={accordionDeleteIcon}
                                              alt="delete icon"
                                              style={{ cursor: "pointer" }}
                                            />
                                          </Flex>
                                          <Box className="app-form-field-container">
                                            <AppFormLabel
                                              labelName="Select environment"
                                              isRequired={true}
                                              labelFor={`environmentName${idx}_input`}
                                              tooltipDesc={
                                                tooltipFlyoutDetails?.tooltipsMap?.get(
                                                  "environments"
                                                )?.tooltip_text_main
                                              }
                                            />
                                            <SelectDropdown
                                              dropdownList={data?.environments?.filter(
                                                (envList) =>
                                                  envList.orgId ===
                                                  organization.orgId
                                              )}
                                              value={env.envId}
                                              onChange={handleOrganizationChange(
                                                `environments[${idx}].envId`,
                                                index
                                              )}
                                              placeholder="Select"
                                              id={`environmentName${idx}`}
                                              clearable={false}
                                            />
                                          </Box>
                                          {env.envId && (
                                            <>
                                              <Box className="app-form-field-container">
                                                <AppFormLabel
                                                  labelName="Select any Core user role type(s) associated with this environment override"
                                                  isRequired={true}
                                                  tooltipDesc={
                                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                                      "coreRoles"
                                                    )?.tooltip_text_main
                                                  }
                                                />
                                                <MultiSelectCheckBox
                                                  data={
                                                    envsCoreRoles?.[
                                                      env?.envId
                                                    ] ?? []
                                                  }
                                                  setSelectedIds={handleOrganizationChange(
                                                    `environments[${idx}].coreRoles`,
                                                    index
                                                  )}
                                                  selectedIds={env.coreRoles}
                                                  keyName={"userRole"}
                                                  columnDef={
                                                    CORE_ROLES_ENV_TABLE_COLUMN_DEF
                                                  }
                                                />
                                              </Box>
                                              <Box className="app-form-field-container">
                                                <AppFormLabel
                                                  labelName="Select any supplemental role type(s) associated with this environment override"
                                                  isRequired
                                                  tooltipDesc={
                                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                                      "supplementalRoles"
                                                    )?.tooltip_text_main
                                                  }
                                                />
                                                <MultiSelectCheckBox
                                                  data={data?.supplementalRoles}
                                                  setSelectedIds={handleOrganizationChange(
                                                    `environments[${idx}].supplementalRoles`,
                                                    index
                                                  )}
                                                  selectedIds={
                                                    env.supplementalRoles
                                                  }
                                                  keyName="id"
                                                  columnDef={
                                                    CONSOLE_ROLE_LIST_TABLE_COLUMN_DEF
                                                  }
                                                />
                                              </Box>
                                            </>
                                          )}
                                        </Box>
                                      )
                                    )}
                                    {organization.environments?.length <
                                      data?.environments?.filter(
                                        (envList) =>
                                          envList.orgId === organization.orgId
                                      ).length && (
                                      <AddNewButton
                                        label={
                                          organization.environments?.length
                                            ? "Add another environment"
                                            : "Add environment"
                                        }
                                        onClick={() => {
                                          push({ ...initialEnvironmentValues });
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              ></FieldArray>
                            </Box>
                          </AccordionComponent>
                        </CardContainer>
                      )
                    )}
                    <Box className="add-limit-add-new-button">
                      {values.organizations?.length <
                        data?.organizationsOptionsList.length && (
                        <AddNewButton
                          label={
                            !values.organizations?.length
                              ? "Add organization"
                              : "Add another organization"
                          }
                          onClick={() => {
                            push({
                              ...initialOrganizationsValues,
                              idx: values.organizations.length + 1,
                            });
                          }}
                        />
                      )}
                    </Box>
                  </>
                )}
              ></FieldArray>
            </Box>
          </Stack>
        </form>
      </FormikProvider>
      <AppModal
        ref={modalRef}
        customClass="access-management-remove-app-modal"
        modalTitle={MODALS[currentModal]?.modalTitle}
        description={MODALS[currentModal]?.decription}
        primaryBtnSelect={MODALS[currentModal]?.primaryButtonClick}
        secondaryBtnSelect={MODALS[currentModal]?.secondaryButtonClick}
        primaryBtnProp={{
          name: `${MODALS[currentModal]?.primaryButtonName}`,
          btnClassName: "app-btn-inverse-secondary",
        }}
        secondaryBtnProp={{
          name: `${MODALS[currentModal]?.secondaryButtonName}`,
          btnClassName: "app-btn-reg-secondary",
        }}
        isCentered
        showCloseIcon={false}
      />
    </Box>
  );
};

export default CreateOrEditTeam;
