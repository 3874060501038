"use client";
import { Box, Heading, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  ErrorAlert,
  SplitButton,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  GeneralPartySpecificationsProps,
  getValidationSchema,
} from "./GeneralPartySpecificationsValidation";
import "./GeneralPartySpecifications.scss";
import { ROUTE_PATH } from "@/route-config/route-path";
import { FinancialInstitutionData } from "@/components/institutional-configuration/model";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import {
  FIN_ORG_SUB_STEPS,
  IC_ENTITY,
  shouldUpdateWorkflowStatus,
  useQueryParams,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type PartySpsfctionProps = {
  data: {
    formData: FinancialInstitutionData;
    partyOrgFinistOptionsData: PostSchema;
  };
};

const GeneralPartySpecifications = (props: PartySpsfctionProps) => {
  const toast = useToast();
  const toastId = "gpp-party-specification-details";
  const updateQueryParams = useQueryParams();

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_LOCATION_CONTACT_INFORMATION"]
        }?${updateQueryParams()}`
      );
    }
    return;
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.partyOrgFinistOptionsData
      );
      const nestedValidationSchema = yupSchema.fields.emails;
      setValidationSchema(nestedValidationSchema);
    })();
  }, [props.data.partyOrgFinistOptionsData]);

  const onSubmit = async (
    values: GeneralPartySpecificationsProps,
    actions: any
  ) => {
    const currentStage = FIN_ORG_SUB_STEPS.gen_party_specification;
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      moveToNextStep();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };
    const response = await updateFinOrg({
      formData: {
        _vn: props.data.formData._vn,
        _Id: props.data.formData._Id,
        name: props.data.formData.name,
        isDataShare: values.isDataShare,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      moveToNextStep();
      return;
    }
  };
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      isDataShare: props.data.formData?.isDataShare ?? undefined,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (isGoingBack) {
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_TAX_SPECIFICATION"]
        }?${updateQueryParams()}`
      );
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };

  return (
    <form onSubmit={handleSubmit} id="finxact-form" noValidate>
      <Box className="app-form-container">
        <CardContainer>
          <Heading as="h2" fontSize={"24px"} mt={"1.5rem"} mb={"3rem"}>
            General party specifications
          </Heading>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"Would you like to share data?"}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("isShareData")
                  ?.tooltip_text_main
              }
            />
            <SplitButton
              name="isDataShare"
              onSelect={handleOnChange("isDataShare")}
              leftBtnName="Yes"
              rightBtnName="No"
              value={values.isDataShare}
            />
            {errors.isDataShare && touched.isDataShare && (
              <ErrorAlert>
                <span>{errors.isDataShare}</span>
              </ErrorAlert>
            )}
          </Box>
        </CardContainer>
      </Box>
    </form>
  );
};

export default GeneralPartySpecifications;
