import {
  AppFormLabel,
  AppModal,
  SelectDropdown,
  Badges,
  CustomTable,
  RadioButtonGroup,
} from "@/components/common";
import { TABS, TAB_NAMES } from "@/utils";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from "@chakra-ui/react";
import Image from "next/image";
import Link from "next/link";
import { kebabIcon } from "public/assets";
import { useRef, useState } from "react";
import "./TabComponent.scss";
import {
  deleteRole,
  deleteUser,
  revokeUser,
} from "../../access-management-api-service";
import { REMOVE_USER_RADIO_LIST } from "@/components/data/data";
import { useRouter, useSearchParams } from "next/navigation";
import { useSelector } from "react-redux";
import { getSelectedOrg } from "@/store";

interface TabComponentProps {
  editButtonLink: string;
  removeItemKey: string;
  heading: "Team" | "Profile templates" | "Custom roles";
  subHeading: string;
  createButtonLink: string;
  createButtonName: string;
  tableColumns: any;
  appendModalTitle?: string;
  tableData: any;
  myAccData?: any;
}

const TabComponent = (props: TabComponentProps) => {
  const {
    editButtonLink,
    removeItemKey,
    heading,
    subHeading,
    createButtonLink,
    createButtonName,
    tableColumns,
    appendModalTitle,
    tableData,
  } = props;

  const modalRef = useRef<any>();
  const [removeUser, setRemoveUser] = useState("");
  const [removalId, setRemovalId] = useState("");
  const [radioValue, setRadioValue] = useState<any>("");
  const [currnetModal, setCurrentModal] = useState<string>("");
  const toast = useToast();
  const toastId = heading;
  const router = useRouter();

  const params = useSearchParams();
  const getCurrentTabVal = params?.get("tab") as string;
  const tab =
    TABS.indexOf(getCurrentTabVal) < 0 ? TAB_NAMES.team : getCurrentTabVal;

  const [currentTableData, setCurrentTableData] = useState(tableData);
  const selectedOrg = useSelector(getSelectedOrg);

  const showModal = (row: any, modal: string) => {
    setTimeout(() => {
      if (modalRef?.current) {
        modalRef.current.openModal();
        setRemoveUser(
          heading === "Team"
            ? `${row.firstName} ${row.lastName}`
            : row[removeItemKey]
        );
        setRemovalId(row?.id);
        setCurrentModal(modal);
      }
    });
  };

  const closeModal = () => {
    setTimeout(() => {
      if (modalRef?.current) {
        modalRef.current.closeModal();
        setRemoveUser("");
        setRemovalId("");
        setRadioValue("");
      }
    });
  };

  const handlePrimaryButtonClick = async () => {
    let res;
    if (heading === "Team") {
      if (currnetModal === "removeUser") {
        res = await deleteUser({
          id: removalId,
          forceDelete: radioValue === 2 ? true : false,
          toastOptions: {
            toast,
            toastId,
            successMessage: "User deleted successfully",
          },
        });
      } else {
        res = await revokeUser({
          id: removalId,
          toastOptions: {
            toast,
            toastId,
            successMessage: "User revoked successfully",
          },
        });
      }
    } else if (heading === "Custom roles") {
      res = await deleteRole({
        id: removalId,
        toastOptions: {
          toast,
          toastId,
          successMessage: `${removeUser} has been deleted`,
        },
      });
    }
    if (res) {
      setCurrentTableData(
        currentTableData.filter((row: any) => row.id !== removalId)
      );
    }
    setRadioValue("");
    closeModal();
  };

  const renderDataCell = (value: any, key: string, row: any) => {
    switch (key) {
      case "action": {
        return custructActionCell(row);
      }
      case "name": {
        return value;
      }
      case "profileTemplate": {
        return constructProfileTemplateCell(row);
      }
      case "roleType": {
        return constructRoleCell(row);
      }
      case "type": {
        return value === "cnsl" ? "Console" : "Core";
      }
      case "status":
      case "Status": {
        return value ? (
          <Badges label={value} type={value?.toLowerCase()} />
        ) : (
          <Badges label="Fedrated" type="success" />
        );
      }
      case "organization":
        return value ? selectedOrg.name : "Default";
      default: {
        return value;
      }
    }
  };

  const renderRemoveMenuItem = (row: any) => {
    switch (heading) {
      case "Custom roles": {
        return (
          row?.organization === Number(selectedOrg.id) ?? (
            <MenuItem
              className="remove"
              onClick={() => showModal(row, "removeRole")}
            >
              Remove
            </MenuItem>
          )
        );
      }
      case "Team": {
        return row?.organization === Number(selectedOrg.id) ? (
          <MenuItem
            className="remove"
            onClick={() => showModal(row, "removeUser")}
          >
            Remove
          </MenuItem>
        ) : (
          <MenuItem
            className="remove"
            onClick={() => showModal(row, "revokeUser")}
          >
            Revoke
          </MenuItem>
        );
      }
      default: {
        return;
      }
    }
  };

  const renderEditMenuItem = (row: any) => {
    switch (heading) {
      case "Custom roles": {
        return row.organization ? (
          <MenuItem onClick={() => showModal(row, "editRole")}>Edit</MenuItem>
        ) : (
          <MenuItem onClick={() => handleViewButtonClick(row)}>View</MenuItem>
        );
      }
      case "Profile templates": {
        return (
          <MenuItem onClick={() => showModal(row, "editTemplate")}>
            Edit
          </MenuItem>
        );
      }
      case "Team": {
        return (
          <MenuItem>
            <Link href={`${editButtonLink}?id=${row.id}&tab=${TAB_NAMES.team}`}>
              Edit
            </Link>
          </MenuItem>
        );
      }
      default: {
        return;
      }
    }
  };

  const handleEditButtonClick = () => {
    router.push(`${editButtonLink}?id=${removalId}&tab=${tab}`);
  };
  const handleViewButtonClick = (row: any) => {
    router.push(`${editButtonLink}?id=${row.id}&view&tab=${tab}`);
  };

  const custructActionCell = (row: any) => {
    return (
      <Menu>
        <MenuButton
          border={"0px"}
          className="kebab-btn"
          as={IconButton}
          aria-label="Options"
          icon={<Image src={kebabIcon} alt="image" />}
          variant="outline"
        />
        <MenuList className="menu-list-container">
          {renderEditMenuItem(row)}
          {renderRemoveMenuItem(row)}
        </MenuList>
      </Menu>
    );
  };

  const constructProfileTemplateCell = (row: any) => {
    const profileTemplates = row?.profileTemplates;
    let profileTemplatesName: [] = [];
    profileTemplates &&
      Object.keys(profileTemplates)?.forEach((key) => {
        profileTemplatesName = profileTemplates[key].map(
          (template: any) => template.name
        );
      });
    return profileTemplatesName?.join(", ");
  };

  const constructRoleCell = (row: any) => {
    return row?.roles.map((role: any) => role.name)?.join(", ");
  };

  const MODALS: any = {
    removeRole: {
      title: `Are you sure you want to remove ${removeUser} role?`,
      description: "",
      primaryButtonClick: handlePrimaryButtonClick,
      secondaryButtonClick: closeModal,
      primaryButtonName: "Yes, remove",
      secondaryButtonName: "Cancel",
      component: null,
    },
    removeUser: {
      title: `Are you sure you want to remove ${removeUser}?`,
      description: "",
      primaryButtonClick: handlePrimaryButtonClick,
      secondaryButtonClick: closeModal,
      primaryButtonName: "Yes, remove",
      secondaryButtonName: "Cancel",
      component: (
        <RadioButtonGroup
          value={radioValue}
          onChange={setRadioValue}
          radioPropList={REMOVE_USER_RADIO_LIST}
          stackDirection={"column"}
          spacing={3.5}
          isBoxedRadio={true}
          customClass="radio-button"
        />
      ),
    },
    revokeUser: {
      title: `Are you sure you want to revoke ${removeUser}?`,
      description: "",
      primaryButtonClick: handlePrimaryButtonClick,
      secondaryButtonClick: closeModal,
      primaryButtonName: "Yes, revoke",
      secondaryButtonName: "Cancel",
      component: null,
    },
    removeRoleInUse: {
      title: `This role is currently in use.`,
      description:
        "This role is actively assigned to some members of your team. Please select another role to assign to those members.",
      primaryButtonClick: handlePrimaryButtonClick,
      secondaryButtonClick: closeModal,
      primaryButtonName: "Reassign & delete",
      secondaryButtonName: "Cancel",
      component: (
        <Box>
          <AppFormLabel
            labelName="Reassign to"
            tooltipDesc=""
            labelFor="reassign"
          />
          <SelectDropdown
            dropdownList={[]}
            value=""
            onChange={(e: any) => {
              e.preventDefault();
            }}
            placeholder="Select"
            id="reassign-role"
            clearable={false}
          />
        </Box>
      ),
    },
    editRole: {
      title: "This role may currently be in use.",
      description:
        "Any updates to this role’s settings will be applied globally to any Team member it is assigned to.",
      primaryButtonClick: handleEditButtonClick,
      secondaryButtonClick: closeModal,
      primaryButtonName: "Proceed",
      secondaryButtonName: "Cancel",
      component: null,
    },
    editTemplate: {
      title: "This profile template may currently be in use.",
      description:
        "Any updates to this profile template’s settings will be applied globally to any Team member it is assigned to.",
      primaryButtonClick: handleEditButtonClick,
      secondaryButtonClick: closeModal,
      primaryButtonName: "Proceed",
      secondaryButtonName: "Cancel",
      component: null,
    },
  };

  return (
    <Flex direction="column" rowGap={10}>
      <Flex direction="column" rowGap={4}>
        <Heading>{heading}</Heading>
        <Text>{subHeading}</Text>
      </Flex>
      <Box flexBasis="18%">
        <Link href={createButtonLink}>
          <Button className="app-btn-inverse-secondary-add-new">
            {createButtonName}
          </Button>
        </Link>
      </Box>
      <CustomTable
        data={currentTableData}
        columns={tableColumns}
        className="tab-component-table"
        renderDataCell={renderDataCell}
        isPagination={true}
      />
      <AppModal
        ref={modalRef}
        customClass="access-management-remove-app-modal"
        modalTitle={MODALS[currnetModal]?.title}
        description={MODALS[currnetModal]?.description}
        primaryBtnSelect={MODALS[currnetModal]?.primaryButtonClick}
        secondaryBtnSelect={MODALS[currnetModal]?.secondaryButtonClick}
        primaryBtnProp={{
          name: `${MODALS[currnetModal]?.primaryButtonName}`,
          btnClassName: "app-btn-inverse-secondary-add-new",
        }}
        primaryBtnDisabled={currnetModal === "removeUser" && radioValue === ""}
        secondaryBtnProp={{
          name: `${MODALS[currnetModal]?.secondaryButtonName}`,
          btnClassName: "app-btn-reg-secondary",
        }}
        isCentered
        showCloseIcon={false}
      >
        {MODALS[currnetModal]?.component}
      </AppModal>
    </Flex>
  );
};

export default TabComponent;
