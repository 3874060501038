"use client";

import React, { useContext, useEffect, useState } from "react";
import {
  getAllEnvironments,
  getAllProjects,
} from "@/api-config/api-service";
import { getCookie, setCookie } from "cookies-next";
import { useDispatch, useSelector } from "react-redux";
import {
  getEnvId,
  setEnvId,
  setEnvType,
  setOrgId,
  AppDispatch,
  setAllowConfigChangeState,
  setEnvStatus,
} from "@/store/index";
import {
  OrgEnvDropdownMenuItemProject,
  OrgEnvDropdownMenuItemEnvironment,
  OrgEnvDropdownMenuItemBase,
} from "./Models";
import localforage from "localforage";
import {
  ORGANIZATION_COOKIE,
  ENV_ID_KEY,
  ENV_TYPE_KEY,
} from "@/utils";
import { UserContext } from "@/app/providers";
import { getOrgsAsArray, getSelectedOrg } from "@/store/slices/organization";
import { OrgEnvDropdown } from "@finxact/finxact-shared-ui";

const OrgEnvDropdownWrapper = () => {
  const userOrgs = useSelector(getOrgsAsArray);
  const org = useSelector(getSelectedOrg)
  const [proj, setProject] = useState<OrgEnvDropdownMenuItemProject | undefined>()
  const [projects, setProjects] = useState<OrgEnvDropdownMenuItemProject[]>([])
  const [env, setEnv] = useState<OrgEnvDropdownMenuItemEnvironment | undefined>()
  const [envs, setEnvs] = useState<OrgEnvDropdownMenuItemEnvironment[]>([])

  const dispatch = useDispatch<AppDispatch>();
  const orgCookie = getCookie(ORGANIZATION_COOKIE);
  const selectedOrgFromCookie = orgCookie ? Number(orgCookie) : null;
  const envId = useSelector(getEnvId);
  
  const { user } = useContext(UserContext);

  // If fxcOrg cookie not found, use user's primary org
  useEffect(() => {
    if (selectedOrgFromCookie == null) {
      setCookie(ORGANIZATION_COOKIE, user.organization);
    }
  }, [user, selectedOrgFromCookie]);

  const removeEnv = () => {
    localforage.setItem(ENV_ID_KEY, undefined);
    localforage.setItem(ENV_TYPE_KEY, undefined);
    dispatch(setEnvType(null));
    dispatch(setEnvId(null));
    dispatch(setEnvStatus([]));
  };

  useEffect(() => {
    if (org) {
      fetchEnvsAndProjs().then((envProjResponse) => {
        const conv_envs = envProjResponse[0];
        const conv_projs = envProjResponse[1];
        setProjects(conv_projs)
        setEnvs(conv_envs)

        // If there is only one env and no projects, then set the env automatically
        if (userOrgs.length === 1 && conv_projs.length === 0 && conv_envs.length === 1) {
          onOrgItemSelect(conv_envs[0], "env")
        }
      });
    }
  }, [org]);

  const fetchEnvsAndProjs = async () => {
    const resEnvsAndProjs = await Promise.all([
      getAllEnvironments(),
      getAllProjects(),
    ]);
    const envs = resEnvsAndProjs[0].environments;
    const projs = resEnvsAndProjs[1];
    const conv_envs: OrgEnvDropdownMenuItemEnvironment[] = Object.values(
      envs
    ).map((env: any) => {
      const _env = {
        id: env.id,
        name: env.name,
        isSelected: env.id === envId,
        ignoreConfigAsCodeChecks: env.ignoreConfigAsCodeChecks,
      };
      if (env.id === envId) {
        setEnv(_env);
      }
      return _env;
    });
    const conv_projs: OrgEnvDropdownMenuItemProject[] = Object.values(
      projs
    ).map((proj: any) => {
      const _proj = {
        id: proj.id,
        name: proj.name,
        isSelected: proj.id === envId,
      };
      if (proj.id === envId) {
        setProject(_proj);
      }
      return _proj;
    });
    return [conv_envs, conv_projs];
  };

  const onOrgItemSelect = (envOrProj: OrgEnvDropdownMenuItemBase, envType: "env" | "proj") => {
      Promise.all([
        localforage.setItem(ENV_ID_KEY, envOrProj.id),
        localforage.setItem(ENV_TYPE_KEY, envType),
      ]).then(() => {
        dispatch(setEnvType(envType));
        dispatch(setEnvId(envOrProj.id!));
        if (envType === "env") {
          setEnv(envOrProj);
        } else {
          setProject(envOrProj)
        }
      });
  }

  const onProjSelect = (projId: number) => {
    const proj = projects.find(project => project.id === projId)

    if(proj) {
      onOrgItemSelect(proj, "proj")
    }
  };

  const onEnvSelect = (envId: number) => {
    const env = envs.find(env => env.id === envId)
    if(env) {
      onOrgItemSelect(env, "env")
    }
  };

  return (
    <OrgEnvDropdown
          env={env ? env : undefined}
          envs={envs}
          onEnvSelect={onEnvSelect}
          onOrgSelect={(orgId: number) => {
            setCookie(ORGANIZATION_COOKIE, `${orgId}`);
            dispatch(setOrgId(orgId));
            removeEnv();
            // reset allowConfigChanges when org changes
            dispatch(setAllowConfigChangeState(null));
          }}
          onProjSelect={onProjSelect}
          org={org ? org : undefined}
          orgs={userOrgs}
          proj={proj ? proj : undefined}
          projs={projects}
        />
  )
};

export default OrgEnvDropdownWrapper;
