"use client";
import React, { useContext, useEffect, useState } from "react";
import { Box, CardBody, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  CardContainer,
  CommonTitle,
  CoupleRadioWithDropdown,
  ErrorAlert,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  TransactionCodeSpecificationsProps,
  getValidationSchema,
  transactionCodeSpecificationsDetails,
} from "./TransactionCodeSpecificationsValidation";
import { useFormik } from "formik";
import { createTransactionDepositComponent } from "../../product-config-client-service";
import {
  COMPONENT_CLASS,
  TRANSCODE_FLOW_NAME,
  getConstructedFeatureNameWithComponent,
  useEntityIdFromParam,
  useFieldNameFromParam,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { QUERY_PARAM_KEY, ROUTE_PATH } from "@/route-config/route-path";

const TransactionCodeSpecifications = (props: { data: any }) => {
  const toast = useToast();

  const [validationSchema, setValidationSchema] = useState<any>(null);
  const entityId = useEntityIdFromParam();
  const fieldName = useFieldNameFromParam();

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props?.data?.transactionCodespecification
      );
      setValidationSchema(yupSchema);
    })();
  }, [props?.data?.transactionCodespecification]);

  const onSubmit = async (
    values: TransactionCodeSpecificationsProps,
    actions: any
  ) => {
    if (!dirty && !entityId) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["transactionDeposit"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }
    if (productDetails) {
      const res = await createTransactionDepositComponent({
        productDetails,
        formData: {
          ...props.data.formData,
          // PATCH fields
          roundUpTrnCode: values.roundUpTrnCode,
          inclRoundUpTrnCodes: values.inclRoundUpTrnCodes,
        },
        toastOptions: {
          toast,
          toastId: "nfs-transaction-code-specification",
          successMessage: `${
            tooltipFlyoutDetails?.pageHeaderDetails?.heading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
              : "Transaction Code Specifications"
          } updated`,
        },
        stageName: getWorflowStatusToBeUpdated?.() ?? "",
      });
      if (res) {
        getExistingProduct?.(productDetails.name, true);
      }
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      roundUpTrnCode:
        fieldName === "roundUpTrnCode" && entityId
          ? entityId
          : props?.data?.formData?.roundUpTrnCode ?? "",
      inclRoundUpTrnCodes:
        fieldName === "inclRoundUpTrnCodes"
          ? [entityId]
          : props?.data?.formData?.inclRoundUpTrnCodes ?? [],
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    productDetails,
    getCurrentStageStatusName,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);
  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);
  //Handling Form
  const transactionsCodeSpecificationFormHandler =
    (key: string) => (value: any) => {
      setFieldValue(key, value);
    };
  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <Box className="app-form-container">
        <CommonTitle
          depositName={getConstructedFeatureNameWithComponent(
            COMPONENT_CLASS["transactionDeposit"],
            getIfxProdType(productDetails),
            true
          )}
          featureName={props.data.formData?.componentName ?? ""}
          version={`VERSION ${props.data.formData?.version || 1}`}
          mainTitle={
            tooltipFlyoutDetails?.pageHeaderDetails?.heading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
              : "Transaction Code Specifications"
          }
          subText={
            tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
              : ""
          }
        />
        <CardContainer>
          <CardBody>
            <Box className={"app-form-field-container"}>
              <AppFormLabel
                labelName={
                  "What is the transaction code that defines the round up functionality?"
                }
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("roundUpTrnCode")
                    ?.tooltip_text_main
                }
                labelFor="transaction_tran_code_roudup"
              />
              <CoupleRadioWithDropdown
                withDescription
                primaryRadioName={"Select from existing transaction code"}
                primaryOptionValue={values.roundUpTrnCode}
                secondaryRadioName={"Create new transaction code"}
                handlePrimaryOptionSelect={
                  transactionsCodeSpecificationFormHandler(
                    "roundUpTrnCode"
                  ) as any
                }
                dropdownList={props.data.transactionCodes}
                placeHolderName="Select transaction code"
                onBlur={handleBlur}
                addNewFieldType="routeToTransCode"
                addNewFieldQueryParam={`${QUERY_PARAM_KEY["PRODUCT_NAME_KEY"]}=${productDetails?.name}&${ROUTE_PATH.FLOW_NAME_QUERY}${TRANSCODE_FLOW_NAME.transCodeSpec}&${QUERY_PARAM_KEY.FIELD_NAME}=roundUpTrnCode`}
                modelViewUrl={ROUTE_PATH.TC_FOUNDATIONAL_DETAILS}
                id="transaction_tran_code_roudup"
              />
              {errors.roundUpTrnCode && touched.roundUpTrnCode && (
                <ErrorAlert>
                  <span>{errors.roundUpTrnCode as any}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box>
              <AppFormLabel
                labelName={
                  "What are the transaction code(s) eligible for round up?"
                }
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("inclRoundUpTrnCodes")
                    ?.tooltip_text_main
                }
                labelFor="transaction_tran_code_inclu_roudup"
              />
              <CoupleRadioWithDropdown
                isMulti
                withDescription
                primaryRadioName={"Select from existing transaction code"}
                multiselectValue={values.inclRoundUpTrnCodes}
                secondaryRadioName={"Create new transaction code"}
                handlePrimaryOptionSelect={
                  transactionsCodeSpecificationFormHandler(
                    "inclRoundUpTrnCodes"
                  ) as any
                }
                dropdownList={props.data.transactionCodes}
                placeHolderName="Select transaction codes"
                onBlur={handleBlur}
                addNewFieldType="routeToTransCode"
                addNewFieldQueryParam={`${QUERY_PARAM_KEY["PRODUCT_NAME_KEY"]}=${productDetails?.name}&${ROUTE_PATH.FLOW_NAME_QUERY}${TRANSCODE_FLOW_NAME.transCodeSpec}&${QUERY_PARAM_KEY.FIELD_NAME}=inclRoundUpTrnCodes`}
                id="transaction_tran_code_inclu_roudup"
                modelViewUrl={ROUTE_PATH.TC_FOUNDATIONAL_DETAILS}
              />
              {errors.inclRoundUpTrnCodes && touched.inclRoundUpTrnCodes && (
                <ErrorAlert>
                  <span>{errors.inclRoundUpTrnCodes as any}</span>
                </ErrorAlert>
              )}
            </Box>
          </CardBody>
        </CardContainer>
      </Box>
    </form>
  );
};

export default TransactionCodeSpecifications;
