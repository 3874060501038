"use client";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tr,
  useToast,
  Text,
  Th,
  Tbody,
  Td,
} from "@/components/ChakraUiManager";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { REGEX_PATTERN } from "@/utils";
import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import {
  GeneralLimitComponentDetailsProps,
  generalLimitComponentDetails,
  validationSchema,
} from "./GeneralLimitDetailsValidation";
import {
  AppFormLabel,
  AppNumberInput,
  SelectDropdown,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  InputText,
  RadioButtonGroup,
  SplitButton,
  StaticText,
} from "@/components/common";
import {
  DefaultFormDetails,
  FinancialInstitutionData,
} from "@/components/institutional-configuration/model";
import "./GeneralLimitDetails.scss";
import { ROUTE_PATH } from "@/route-config/route-path";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";

interface GeneralLimitDetailsProp extends DefaultFormDetails {
  data: {
    minimumBalanceOptions: { label: string; value: string }[];
    minimumBalanceFee: string[];
    formData: FinancialInstitutionData | null;
  };
}

const GeneralLimitDetails = (props: GeneralLimitDetailsProp) => {
  const { depositName, featureName, version, mainTitle, subText, data } = props;
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    getCurrentStageStatusName,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };
  const toast = useToast();
  const toastId = "financial-organization";

  const onSubmit = async (
    values: GeneralLimitComponentDetailsProps,
    actions: any
  ) => {
    // party limit payload
    const payload = {
      componentName: values.componentName,
      deminimisAmt: parseFloat(values.deminimisAmount),
      maxBal: parseFloat(values.maximumBalance),
      minBal: parseFloat(values.minimumRequiredBalance),
      minBalFee: values.minimumBalanceFee,
      minBalOpt: values.minimumBalanceOption,
      minToOpen: values.minimumBalanceOption,
      restrictCr: values.restrictCredits,
      restrictCrFundExp: values.restrictCreditsAfterFundingExpiration,
      restrictDr: values.restrictDebits,
    };
    if (!dirty) {
      navigateTo?.(ROUTE_PATH.IC_INTERSTITIAL_SCREEN);
      return;
    }
  };

  const generalLimitComponentDetailsInitialValues = {
    componentName:
      props.data.formData?.partyLimit?.componentName ??
      generalLimitComponentDetails.componentName,
    acctMinimumBalance:
      props.data.formData?.partyLimit?.minBal !== 0 ??
      generalLimitComponentDetails.acctMinimumBalance,
    minimumRequiredBalance:
      props.data.formData?.partyLimit?.minBal?.toString() ??
      generalLimitComponentDetails.minimumRequiredBalance,
    minimumAmountToOpen:
      props.data.formData?.partyLimit?.minToOpen?.toString() ??
      generalLimitComponentDetails.minimumAmountToOpen,
    maximumBalance:
      props.data.formData?.partyLimit?.maxBal?.toString() ??
      generalLimitComponentDetails.maximumBalance,
    deminimisAmount:
      props.data.formData?.partyLimit?.deminimisAmt?.toString() ??
      generalLimitComponentDetails.deminimisAmount,
    restrictCredits:
      props.data.formData?.partyLimit?.restrictCr ??
      generalLimitComponentDetails.restrictCredits,
    restrictCreditsAfterFundingExpiration:
      props.data.formData?.partyLimit?.restrictCrFundExp ??
      generalLimitComponentDetails.restrictCreditsAfterFundingExpiration,
    restrictDebits:
      props.data.formData?.partyLimit?.restrictDr ??
      generalLimitComponentDetails.restrictDebits,
    minimumBalanceFee:
      props.data.formData?.partyLimit?.minBalFee ??
      generalLimitComponentDetails.minimumBalanceFee,
    minimumBalanceOption:
      props.data.formData?.partyLimit?.minBalOpt ??
      generalLimitComponentDetails.minimumBalanceOption,
  };

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: generalLimitComponentDetailsInitialValues,
  });

  const {
    values,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    errors,
    dirty,
  } = formik;

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  const formTitle = (
    <>
      <CommonTitle
        depositName={depositName}
        featureName={featureName}
        version={version}
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading || mainTitle
        }
        subText={tooltipFlyoutDetails?.pageHeaderDetails?.subHeading || subText}
      />
    </>
  );

  return (
    <form onSubmit={formik.handleSubmit} id="finxact-form">
      {formTitle}
      <Box className="fedwire-box-default-width">
        <CardContainer>
          <Box className="app-form-field-heading-limit-container">
            <Heading as="h3" className="heading-styles-add-limit-h3">
              {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
                ?.section_title || "General limit component details"}
            </Heading>
            <StaticText
              textValue={
                tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
                  ?.section_subhead ||
                "Define the foundational elements of limit components, beginning with naming the component for easy identification"
              }
            />
          </Box>
          <Box>
            <Heading as="h5" className="heading-styles-component-h5">
              {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[1]
                ?.section_title || "General component setup"}
            </Heading>
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"What is the component name?"}
              isRequired={true}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("name")
                  ?.tooltip_text_main
              }
            />
            <InputText
              name="Component Name"
              value={values.componentName}
              onChange={handleOnChange("componentName")}
              onBlur={handleBlur}
              label="Component Name"
              placeholder="Savings_Limits_Business"
            />
            {errors.componentName && touched.componentName && (
              <ErrorAlert>
                <span>{errors.componentName}</span>
              </ErrorAlert>
            )}
          </Box>

          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"Version"}
              isRequired={true}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("version")
                  ?.tooltip_text_main
              }
            />
            <StaticText textValue="1" />
          </Box>
          <Box className="app-form-field-container-sub-heading">
            <Heading as="h4" className="heading-styles-general-limit-h4">
              {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[2]
                ?.section_title || "Account balance configuration"}
            </Heading>
            <StaticText
              textValue={
                tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[2]
                  ?.section_subhead ||
                "Set parameters around account balances, specifying minimum requirements, opening amounts, maximum limits, and deminimis thresholds."
              }
            />
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"Do you want a minimum balance?"}
              isRequired={true}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("acctMinimumBalance")
                  ?.tooltip_text_main
              }
            />
            <SplitButton
              name="acctMinimumBalance"
              onSelect={handleOnChange("acctMinimumBalance")}
              leftBtnName="Yes"
              rightBtnName="No"
              value={values.acctMinimumBalance}
            />
            {errors.acctMinimumBalance && touched.acctMinimumBalance && (
              <ErrorAlert>
                <span>{errors.acctMinimumBalance}</span>
              </ErrorAlert>
            )}
          </Box>
          {values.acctMinimumBalance && (
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName={"What is the minimum required balance?"}
                isRequired={true}
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("minBal")
                    ?.tooltip_text_main
                }
              />
              <Box className="input-width-small">
                <AppNumberInput
                  label="minimum-required-balance"
                  value={values.minimumRequiredBalance}
                  onChange={handleOnChange("minimumRequiredBalance")}
                  onBlur={handleBlur}
                  placeholder="Enter Balance"
                />
              </Box>
              {errors.minimumRequiredBalance &&
                touched.minimumRequiredBalance && (
                  <ErrorAlert>
                    <span>{errors.minimumRequiredBalance}</span>
                  </ErrorAlert>
                )}
            </Box>
          )}
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"What is the minimum amount to open?"}
              isRequired={true}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("minAmt")
                  ?.tooltip_text_main
              }
            />
            <Box className="input-width-small">
              <AppNumberInput
                label="minimum-amount-to-open"
                value={values.minimumAmountToOpen}
                onChange={handleOnChange("minimumAmountToOpen")}
                onBlur={handleBlur}
                placeholder="Enter amount"
              />
            </Box>
            {errors.minimumAmountToOpen && touched.minimumAmountToOpen && (
              <ErrorAlert>
                <span>{errors.minimumAmountToOpen}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"What is the maximum balance?"}
              isRequired={true}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("maxBal")
                  ?.tooltip_text_main
              }
            />
            <Box className="input-width-small">
              <AppNumberInput
                label="maximum-balance"
                value={values.maximumBalance}
                onChange={handleOnChange("maximumBalance")}
                onBlur={handleBlur}
                placeholder="Enter balance"
              />
            </Box>
            {errors.maximumBalance && touched.maximumBalance && (
              <ErrorAlert>
                <span>{errors.maximumBalance}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"What is the de minimis amount?"}
              isRequired={true}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("deminimisAmt")
                  ?.tooltip_text_main
              }
            />
            <Box className="input-width-small">
              <AppNumberInput
                label="deminimis-amount"
                value={values.deminimisAmount}
                onChange={handleOnChange("deminimisAmount")}
                onBlur={handleBlur}
                placeholder="Enter amount"
              />
            </Box>
            {errors.deminimisAmount && touched.deminimisAmount && (
              <ErrorAlert>
                <span>{errors.deminimisAmount}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container-sub-heading">
            <Heading as="h4" className="heading-styles-general-limit-h4">
              {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[3]
                ?.section_title || "Restriction configuration"}
            </Heading>
            <StaticText
              textValue={
                tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[3]
                  ?.section_subhead ||
                "Determine credit and debit restrictions, tailoring account functionality post-funding expiration or for specific transaction types."
              }
            />
          </Box>
          <Box className="app-form-field-container">
            <Table className="table" variant="unstyled" borderRadius="2xl">
              <Thead className="custom-table-header">
                <Tr className="custom-table-tr-fedwire">
                  <Th className="fedwire-left-custom-table-tr" rowSpan={2}>
                    <Text fontSize="xl">Enable any of the following</Text>
                  </Th>
                  <Th className="fedwire-right-custom-table-tr">
                    <Text className="fedwire-right-custom-text">
                      header-text
                    </Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr className="custom-table-data-row">
                  <Td className="custom-table-padding-appLabel">
                    <AppFormLabel
                      labelName={
                        "Do you want to restrict credits after funding expiration?"
                      }
                      isRequired
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get(
                          "restrictCrFundExp"
                        )?.tooltip_text_main
                      }
                    />
                  </Td>
                  <Td className="custom-table-padding-splitButton">
                    <SplitButton
                      name="restrictCreditsAfterFundingExpiration"
                      onSelect={handleOnChange(
                        "restrictCreditsAfterFundingExpiration"
                      )}
                      leftBtnName="Yes"
                      rightBtnName="No"
                      value={values.restrictCreditsAfterFundingExpiration}
                    />
                    {errors.restrictCreditsAfterFundingExpiration &&
                      touched.restrictCreditsAfterFundingExpiration && (
                        <ErrorAlert>
                          <span>
                            {errors.restrictCreditsAfterFundingExpiration}
                          </span>
                        </ErrorAlert>
                      )}
                  </Td>
                </Tr>
                <Tr className="custom-table-data-row">
                  <Td className="custom-table-padding-appLabel">
                    <AppFormLabel
                      labelName={"Do you want to restrict all credits?"}
                      isRequired
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("restrictCr")
                          ?.tooltip_text_main
                      }
                    />
                  </Td>
                  <Td className="custom-table-padding-splitButton">
                    <SplitButton
                      name="restrictCredits"
                      onSelect={handleOnChange("restrictCredits")}
                      leftBtnName="Yes"
                      rightBtnName="No"
                      value={values.restrictCredits}
                    />
                    {errors.restrictCredits && touched.restrictCredits && (
                      <ErrorAlert>
                        <span>{errors.restrictCredits}</span>
                      </ErrorAlert>
                    )}
                  </Td>
                </Tr>
                <Tr className="custom-table-data-last-row">
                  <Td className="custom-table-padding-appLabel">
                    <AppFormLabel
                      labelName={"Do you want to restrict all debits?"}
                      isRequired
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("restrictDr")
                          ?.tooltip_text_main
                      }
                    />
                  </Td>
                  <Td className="custom-table-padding-splitButton">
                    <SplitButton
                      name="restrictDebits"
                      onSelect={handleOnChange("restrictDebits")}
                      leftBtnName="Yes"
                      rightBtnName="No"
                      value={values.restrictDebits}
                    />
                    {errors.restrictDebits && touched.restrictDebits && (
                      <ErrorAlert>
                        <span>{errors.restrictDebits}</span>
                      </ErrorAlert>
                    )}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
          <Box className="app-form-field-container-sub-heading">
            <Heading as="h4" className="heading-styles-general-limit-h4">
              {tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[4]
                ?.section_title || "Minimum balance fee details"}
            </Heading>
            <StaticText
              textValue={
                tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[4]
                  ?.section_subhead ||
                "Detail the fee structure related to maintaining minimum balance requirements, including options and specific fee amounts"
              }
            />
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"What is the minimum balance fee?"}
              isRequired={true}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("minBalFee")
                  ?.tooltip_text_main
              }
              labelFor="minimum_balance_fee_dropdown_input"
            />
            <SelectDropdown
              id="minimum_balance_fee_dropdown"
              value={values.minimumBalanceFee}
              onChange={handleOnChange("minimumBalanceFee")}
              dropdownList={props.data.minimumBalanceFee}
            />
            {errors.minimumBalanceFee && touched.minimumBalanceFee && (
              <ErrorAlert>
                <span>{errors.minimumBalanceFee}</span>
              </ErrorAlert>
            )}
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName={"What is the minimum balance option?"}
              isRequired={true}
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("minBalOpt")
                  ?.tooltip_text_main
              }
            />
            <RadioButtonGroup
              value={values.minimumBalanceOption}
              onChange={handleOnChange("minimumBalanceOption")}
              isBoxedRadio={true}
              radioPropList={props.data.minimumBalanceOptions}
              stackDirection="row"
            />
            {errors.minimumBalanceOption && touched.minimumBalanceOption && (
              <ErrorAlert>
                <span>{errors.minimumBalanceOption}</span>
              </ErrorAlert>
            )}
          </Box>
        </CardContainer>
      </Box>
    </form>
  );
};

export default GeneralLimitDetails;
