"use client";
import {
  AccordionComponent,
  AddNewButton,
  AppFormLabel,
  SelectDropdown,
  CardContainer,
  CommonTitle,
  DatePicker,
  ErrorAlert,
  InputText,
  SplitButton,
  StaticText,
  TimeField,
} from "@/components/common";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Box, Stack, Text, useToast } from "@chakra-ui/react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useRef } from "react";
import {
  PartyAddress,
  PartyAddressDetailProps,
  validationSchema,
} from "./PartyAddressDetailValidation";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { ROUTE_PATH } from "@/route-config/route-path";
import { FinancialInstitutionData } from "@/components/institutional-configuration/model";
import { updateFinOrg } from "@/components/institutional-configuration/ic-config-api-service";
import {
  useQueryParams,
  FIN_ORG_SUB_STEPS,
  shouldUpdateWorkflowStatus,
  IC_ENTITY,
  TIME_FORMAT,
  makeDateTime,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";

type PartyAddressProps = {
  data: {
    addTypeOption: { label: string; value: string }[];
    partyAddOption: { label: string; value: string }[];
    formData: FinancialInstitutionData | null;
  };
};

const PartyAddressDetail = (props: PartyAddressProps) => {
  const {
    addTypeOption: addTypeDropdownList,
    partyAddOption: partyAddDopdownList,
  } = props.data;
  const toast = useToast();
  const activeAccordionRef = useRef<any>(null);
  const toastId = "gpp-party-address-details";

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,

    updateFormStatus,
    updateStatusInSideBarMenuList,
    checkFormStatus,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const updateQueryParams = useQueryParams();

  const moveToNextStep = () => {
    if (!canCheckFormStatus) {
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_PARTY_EMAILS_DETAILS"]
        }?${updateQueryParams()}`
      );
    }
    return;
  };

  const onSubmit = async (values: any, actions: any) => {
    const currentStage = "";
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?._Id!,
          stage: IC_ENTITY.financial_organization,
          status: currentStage,
        });
      }
      moveToNextStep();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };
    const response = await updateFinOrg({
      formData: {
        _vn: props.data.formData?._vn,
        _Id: props.data.formData?._Id,
        name: props.data.formData?.name,
        addrs: [],
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: "General Party Parameters 2 of 10 updated.",
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      moveToNextStep();
      return;
    }
  };
  // Handler for all the form fields
  const _handleOnChange = (key: string, index: number) => (value: any) => {
    setFieldValue(`PartyAddress.${index}.${key}`, value);
  };

  const _handleOnBlur = (key: string, index: number) => () => {
    setFieldTouched(`PartyAddress.${index}.${key}`, true);
  };

  let initialPartyAdd: PartyAddressDetailProps[] = [];
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: { PartyAddress: initialPartyAdd },
  });
  const {
    values,
    handleSubmit,
    errors,
    touched,
    dirty,
    setFieldValue,
    setFieldTouched,
  } = formik;
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack)
      navigateTo(
        `${
          ROUTE_PATH["GENERAL_PARTY_PARAMETER_IDENTIFICATION"]
        }?${updateQueryParams()}`
      );
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
      checkFormStatus?.(false);
    }
  }, [canCheckFormStatus, isGoingBack]);
  //Form_Error_Handler
  const _errorHandler = (key: keyof PartyAddressDetailProps, index: number) => {
    return (
      <>
        {touched.PartyAddress?.[index]?.[key] &&
          touched.PartyAddress[index] &&
          (touched.PartyAddress[index] as any)[key] &&
          errors.PartyAddress &&
          errors.PartyAddress[index] &&
          (errors.PartyAddress[index] as any)[key] && (
            <ErrorAlert>
              <span>{ERROR_MESSAGE}</span>
            </ErrorAlert>
          )}
      </>
    );
  };

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit} id="finxact-form">
          <CommonTitle
            depositName="financial institution setup"
            featureName=""
            version=""
            mainTitle={
              tooltipFlyoutDetails?.pageHeaderDetails?.heading ||
              "General party parameters - 2 of 10"
            }
            subText={
              tooltipFlyoutDetails?.pageHeaderDetails?.subHeading ||
              "Provide location details for the party address"
            }
          />
          <FieldArray
            name={"PartyAddress"}
            render={({ insert, remove, push }) => (
              <Box className="app-form-container">
                {values.PartyAddress.map((partyAdd: any, index: any) => (
                  <Box key={partyAdd.idx} ref={activeAccordionRef}>
                    <CardContainer styleProps={{ mb: "24px" }}>
                      <AccordionComponent
                        accordionTitle={`Party address details ${index + 1}`}
                        limitName={partyAdd.name}
                        deleteIcon
                        onDeleteHandler={() => remove(index)}
                        isExpand={
                          values.PartyAddress.length !== index + 1 ? 1 : 0
                        }
                      >
                        <Box className="app-form-field-container">
                          <Text
                            as="h2"
                            style={{ fontSize: "2rem", fontWeight: "bold" }}
                          >
                            {tooltipFlyoutDetails?.pageHeaderDetails
                              ?.sections?.[0]?.section_title ||
                              "General location information"}
                          </Text>

                          <StaticText
                            textValue={
                              tooltipFlyoutDetails?.pageHeaderDetails
                                ?.sections?.[0]?.section_subhead ||
                              "Ensure accurate and efficient communication"
                            }
                          />
                        </Box>
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="What is the party identifier?"
                            isRequired
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get("_Id")
                                ?.tooltip_text_main
                            }
                          />
                          <StaticText
                            textValue={props.data.formData?._Id ?? ""}
                          />
                        </Box>
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="What is the address type?"
                            isRequired
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get(
                                "addressType"
                              )?.tooltip_text_main
                            }
                            labelFor={`address_type_${index}_input`}
                          />
                          <SelectDropdown
                            dropdownList={addTypeDropdownList}
                            value={partyAdd.addressType}
                            onChange={_handleOnChange("addressType", index)}
                            onBlur={_handleOnBlur("addressType", index)}
                            placeholder="Select"
                            id={`address_type_${index}`}
                          />
                          {_errorHandler("addressType", index)}
                        </Box>
                        <Box className="app-form-field-container">
                          <Text
                            as="h2"
                            style={{ fontSize: "2rem", fontWeight: "bold" }}
                          >
                            {tooltipFlyoutDetails?.pageHeaderDetails
                              ?.sections?.[1]?.section_title ||
                              "Address verification"}
                          </Text>

                          <StaticText
                            textValue={
                              tooltipFlyoutDetails?.pageHeaderDetails
                                ?.sections?.[1]?.section_subhead || ""
                            }
                          />
                        </Box>
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="Enter a recognizable label for the address"
                            isRequired
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get(
                                "addressLabel"
                              )?.tooltip_text_main
                            }
                          />
                          <InputText
                            value={partyAdd.addressLabel}
                            onChange={_handleOnChange("addressLabel", index)}
                            onBlur={_handleOnBlur("addressLabel", index)}
                          />
                          {_errorHandler("addressLabel", index)}
                        </Box>
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="Is this the preferred address?"
                            isRequired
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get(
                                "addressPreferred"
                              )?.tooltip_text_main
                            }
                          />
                          <SplitButton
                            value={partyAdd.addressPreferred}
                            onSelect={_handleOnChange(
                              "addressPreferred",
                              index
                            )}
                            onBlur={_handleOnBlur("addressPreferred", index)}
                            leftBtnName="Yes"
                            rightBtnName="No"
                          />
                          {_errorHandler("addressPreferred", index)}
                        </Box>
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="Is this an invalid address?"
                            isRequired
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get(
                                "invalidAddress"
                              )?.tooltip_text_main
                            }
                          />
                          <SplitButton
                            value={partyAdd.invalidAddress}
                            onSelect={_handleOnChange("invalidAddress", index)}
                            onBlur={_handleOnBlur("invalidAddress", index)}
                            leftBtnName="Yes"
                            rightBtnName="No"
                          />
                          {_errorHandler("invalidAddress", index)}
                        </Box>
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="When is the address valid from?"
                            isRequired
                          />
                          <Box display="flex" gap="2rem">
                            <Stack flexBasis="50%">
                              <AppFormLabel
                                labelName="Date"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "fromDate"
                                  )?.tooltip_text_main
                                }
                                labelFor={`from_date_${index}_input`}
                              />
                              <DatePicker
                                value={partyAdd.fromDate}
                                onUpdate={_handleOnChange("fromDate", index)}
                                placeholder="Select"
                                id={`from_date_${index}`}
                                ariaLabel={`from_date_${index}`}
                              />
                              {_errorHandler("fromDate", index)}
                            </Stack>
                            <Stack flexBasis="50%">
                              <AppFormLabel
                                labelName="Time"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "fromTime"
                                  )?.tooltip_text_main
                                }
                              />
                              <TimeField
                                value={partyAdd.fromTime}
                                onChange={_handleOnChange("fromTime", index)}
                                onBlur={_handleOnBlur("fromTime", index)}
                                ariaLabel="address-valid-from"
                              />
                              {_errorHandler("fromTime", index)}
                            </Stack>
                          </Box>
                        </Box>
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="When is the address valid through?"
                            isRequired
                          />
                          <Box display="flex" gap="2rem">
                            <Stack flexBasis="50%">
                              <AppFormLabel
                                labelName="Date"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "throughDate"
                                  )?.tooltip_text_main
                                }
                                labelFor={`through_date_${index}_input`}
                              />
                              <DatePicker
                                value={partyAdd.throughDate}
                                onUpdate={_handleOnChange("throughDate", index)}
                                onBlur={_handleOnBlur("throughDate", index)}
                                placeholder="Select"
                                id={`through_date_${index}`}
                                ariaLabel={`through_date_${index}`}
                              />
                              {_errorHandler("throughDate", index)}
                            </Stack>
                            <Stack flexBasis="50%">
                              <AppFormLabel
                                labelName="Time"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "throughTime"
                                  )?.tooltip_text_main
                                }
                              />
                              <TimeField
                                value={partyAdd.throughTime}
                                onChange={_handleOnChange("throughTime", index)}
                                onBlur={_handleOnBlur("throughTime", index)}
                                ariaLabel="address-valid-through-time"
                              />
                              {_errorHandler("throughTime", index)}
                            </Stack>
                          </Box>
                        </Box>
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="When was the address verified on?"
                            isRequired
                          />
                          <Box display="flex" gap="2rem">
                            <Stack flexBasis="50%">
                              <AppFormLabel
                                labelName="Date"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "verifiedDate"
                                  )?.tooltip_text_main
                                }
                                labelFor={`verified_date_${index}_input`}
                              />
                              <DatePicker
                                value={partyAdd.verifiedDate}
                                onUpdate={_handleOnChange(
                                  "verifiedDate",
                                  index
                                )}
                                onBlur={_handleOnBlur("verifiedDate", index)}
                                placeholder="Select"
                                id={`verified_date_${index}`}
                                ariaLabel={`verified_date_${index}`}
                              />
                              {_errorHandler("verifiedDate", index)}
                            </Stack>
                            <Stack flexBasis="50%">
                              <AppFormLabel
                                labelName="Time"
                                tooltipDesc={
                                  tooltipFlyoutDetails?.tooltipsMap?.get(
                                    "verifiedTime"
                                  )?.tooltip_text_main
                                }
                              />
                              <TimeField
                                value={partyAdd.verifiedTime}
                                onChange={_handleOnChange(
                                  "verifiedTime",
                                  index
                                )}
                                onBlur={_handleOnBlur("verifiedTime", index)}
                                ariaLabel="address-verified-time"
                                placeholder={TIME_FORMAT.placeHolderTime}
                              />
                              {_errorHandler("verifiedTime", index)}
                            </Stack>
                          </Box>
                        </Box>
                        <Box className="app-form-field-container">
                          <AppFormLabel
                            labelName="How many years has this party been at this address?"
                            isRequired
                            tooltipDesc={
                              tooltipFlyoutDetails?.tooltipsMap?.get(
                                "partyYear"
                              )?.tooltip_text_main
                            }
                            labelFor={`party_year_${index}_input`}
                          />
                          <SelectDropdown
                            dropdownList={partyAddDopdownList}
                            value={partyAdd.partyYear}
                            onChange={_handleOnChange("partyYear", index)}
                            onBlur={_handleOnBlur("partyYear", index)}
                            placeholder="Select"
                            id={`party_year_${index}`}
                          />
                          {_errorHandler("partyYear", index)}
                        </Box>
                      </AccordionComponent>
                    </CardContainer>
                  </Box>
                ))}
                <Box marginTop="32px">
                  <AddNewButton
                    label={
                      !values.PartyAddress.length
                        ? "Add party address"
                        : "Add another party address"
                    }
                    onClick={() => {
                      push({ ...PartyAddress, idx: Math.random() });
                    }}
                  />
                </Box>
              </Box>
            )}
          ></FieldArray>
        </form>
      </FormikProvider>
    </>
  );
};
export default PartyAddressDetail;
