"use client";
import { Box, useToast } from "@/components/ChakraUiManager";
import {
  AccordionComponent,
  AppFormLabel,
  CardContainer,
  CoupleRadioWithDropdown,
  DurationInput,
  ErrorAlert,
  FrequencyModule,
  RadioButtonGroup,
  StaticText,
} from "@/components/common";
import { useContext, useEffect, useMemo, useState } from "react";
import CadencesStyle from "./ProductCandences.module.scss";
import SplitButton from "@/components/common/split-button/SplitButton";
import { PRODUCT_STATUS } from "@/components/data/form-data";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  ProductCadencesFormProps,
  getValidationSchema,
} from "./ProductCadencesValidation";
import { updateProduct, updateWorkflowGeneric } from "@/api-config/api-service";
import { useFormik } from "formik";
import {
  ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS,
  IFX_PRODUCT_TYPE_VAL,
  MAIN_FORM_ID,
  RjsfData,
  preventMainFormSubmitOnRJSFSubmit,
  useProductTypeCheck,
} from "@/utils";
import { ProductType } from "@/components/product-management/model/product-models";
import { addNewComponent } from "../../product-config-client-service";
import {
  OptionsSchema,
  PostSchema,
} from "@/components/product-management/model/types";
import {
  exceptRuleUiSchema,
  getFeeCalcDtlUiSchema,
  feeCalcDtlUnRequire,
  matrixTypeUiSchema,
} from "@finxact/finxact-shared-ui";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { Option } from "@/utils/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type Props = {
  data: {
    formData: ProductType | null;
    feeOptions: string[];
    restrictionCodes: string[];
    addNewSchema: OptionsSchema;
    addNewOptionEndpoint: string;
    addNewExceptRulesSchema: OptionsSchema;
    addNewOptionExceptRules: string;
    productCandencesOptionsData: PostSchema;
    startDateOptions: Option<number>[];
    matrixDDOptions: string[];
    addNewMatrixSchema: OptionsSchema;
    addNewMatrixEndpoint: string;
  };
};

const ProductCandences = (props: Props) => {
  const toast = useToast();
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getExistingProduct,
    tooltipFlyoutDetails,
    getWorflowStatusToBeUpdated,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const {
    formData,
    feeOptions,
    restrictionCodes,
    addNewSchema,
    addNewOptionEndpoint,
    addNewExceptRulesSchema,
    addNewOptionExceptRules,
    addNewMatrixEndpoint,
    addNewMatrixSchema,
    matrixDDOptions,
    productCandencesOptionsData,
    startDateOptions,
  } = props.data;

  const onSubmit = async (values: ProductCadencesFormProps) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS["cadences"],
          status: PRODUCT_STATUS.completed,
        });
      return;
    }

    if (formData) {
      const res = await updateProduct({
        productName: formData.name,
        body: JSON.stringify({
          // NOTE: _vn, componentName, version is required to do a PATCH update
          _vn: formData?._vn,
          // PATCH fields
          stmtFreq: values.stmtFreq ?? "",
          stmtFreqMatrix: values.stmtFreqMatrix || null,

          // Interim
          ...(values.interimStatementFrequencyButton
            ? {
                interimStmtFreq: values.interimStmtFreq ?? "",
              }
            : { interimStmtFreq: null }),

          // Inactive
          inactiveStatusDur: values.inactiveStatusDur,
          inactiveStatusAlertFreq: values.inactiveStatusAlertFreq ?? "",
          inactiveStatusFee: values.inactiveStatusFee,
          inactiveStatusRestrictCode: values.inactiveStatusRestrictCode,

          // Dormant
          dormantStatusAlertFreq: values.dormantStatusAlertFreq ?? "",
          dormantStatusDur: values.dormantStatusDur,
          dormantStatusFee:
            values.dormantStatusFee === "" ? null : values.dormantStatusFee,
          dormantStatusRestrictCode:
            values.dormantStatusRestrictCode === ""
              ? null
              : values.dormantStatusRestrictCode,
          stmtStartDtmOpt: values.stmtStartDtmOpt,
        } as ProductType),
      });
      if (res && productDetails?.name) {
        if (!toast.isActive("cadences")) {
          toast({
            id: "cadences",
            description: `${tooltipFlyoutDetails?.pageHeaderDetails?.heading || "Cadences"} updated`,
            status: "success",
          });
        }
        getExistingProduct?.(productDetails.name, true);
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails.name,
          stage: ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS["cadences"],
          status: PRODUCT_STATUS.completed,
        });
      }
    }
  };

  const inactivityFeeschema = useMemo(() => {
    const { actions, name } = addNewSchema;
    return { name, ...actions.POST };
  }, [addNewSchema]);

  const addNewInactivityOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("inactiveStatusFee", response.name);
  };

  const inactiveStatusSchema = useMemo(() => {
    const { actions, name } = addNewExceptRulesSchema;
    return { name, ...actions.POST };
  }, [addNewExceptRulesSchema]);

  const addNewInactiveStatusOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionExceptRules,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("inactiveStatusRestrictCode", response.code);
  };

  const dormantStatusSchema = useMemo(() => {
    const { actions, name } = addNewExceptRulesSchema;
    return { name, ...actions.POST };
  }, [addNewExceptRulesSchema]);

  const addNewDormantStatusOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionExceptRules,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("dormantStatusRestrictCode", response.code);
  };

  const dormantSchema = useMemo(() => {
    const { actions, name } = addNewSchema;
    return { name, ...actions.POST };
  }, [addNewSchema]);

  const addNewDormantOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("dormantStatusFee", response.name);
  };

  // Schema to pass into RJSF (Add New Matrix flow)
  const schema = useMemo(() => {
    const { actions, name } = addNewMatrixSchema;
    return { name, ...actions.POST };
  }, [addNewMatrixSchema]);

  const addNewMatrix = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewMatrixEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("stmtFreqMatrix", response.matrixName);
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(productCandencesOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [productCandencesOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      // helper fields
      ifxAcctType: formData?.ifxAcctType ?? "",
      stmtFreq: formData?.stmtFreq ?? "",
      stmtFreqMatrix: formData?.stmtFreqMatrix ?? "",

      // Interim
      interimStatementFrequencyButton:
        formData?.interimStmtFreq !== undefined
          ? Boolean(formData?.interimStmtFreq)
          : undefined,
      interimStmtFreq: formData?.interimStmtFreq ?? "",

      // Inactive
      inactiveStatusDur: formData?.inactiveStatusDur ?? "",
      inactiveStatusAlertFreq: formData?.inactiveStatusAlertFreq ?? "",
      inactiveStatusFee: formData?.inactiveStatusFee ?? "",
      inactiveStatusRestrictCode: formData?.inactiveStatusRestrictCode ?? "",

      // Dormant
      dormantStatusAlertFreq: formData?.dormantStatusAlertFreq ?? "",
      dormantStatusDur: formData?.dormantStatusDur ?? "",
      dormantStatusFee: formData?.dormantStatusFee ?? "",
      dormantStatusRestrictCode: formData?.dormantStatusRestrictCode ?? "",
      stmtStartDtmOpt: formData?.stmtStartDtmOpt ?? 0,
    },
  });
  const { values, handleSubmit, errors, touched, setFieldValue, dirty } =
    formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const handleFormData =
    (key: keyof ProductCadencesFormProps) => (value: any) => {
      if (key === "interimStatementFrequencyButton") {
        setFieldValue(key, value).then((res) => {
          setFieldValue("interimStmtFreq", "");
        });
      } else {
        setFieldValue(key, value);
      }
    };

  // we are using useffect for left side navigation
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus]);

  //check product type
  const isDepositProduct = useProductTypeCheck(
    productDetails?.ifxAcctType ?? "",
    IFX_PRODUCT_TYPE_VAL.deposit
  );

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id={MAIN_FORM_ID}
    >
      <StaticText
        heading
        headingName={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading || "Cadences"
        }
        pt={"9.6"}
      />
      <Box className={CadencesStyle["cadences-form-wrapper"]}>
        {/* Statement Cadence */}
        <CardContainer customClass={"app-form-container"}>
          <AccordionComponent
            accordionTitle={
              tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
                ?.section_title || "Statement cadence"
            }
            isExpand={0}
          >
            <Box marginTop="-10">
              <StaticText
                textValue={
                  tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
                    ?.section_subhead ||
                  "Design the frequency and timing of informational updates, ensuring users are consistently informed about their product's status."
                }
              />
            </Box>
            <Box
              className={
                CadencesStyle["all-four-form-section-component-seperator"]
              }
            >
              <AppFormLabel
                labelName="How often should the periodic service charge be applied to the position?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("stmtFreq")
                    ?.tooltip_text_main
                }
              />
              <CoupleRadioWithDropdown
                id="promotional_rate_offset"
                primaryOptionValue={values.stmtFreqMatrix}
                tertiaryOptionValue={values.stmtFreq}
                tertiaryFieldType="frequency"
                primaryRadioName={"Leverage existing matrix"}
                secondaryRadioName={"Add new rate matrix"}
                tertiaryRadioName={"Enter a single frequency"}
                handlePrimaryOptionSelect={
                  handleFormData("stmtFreqMatrix") as any
                }
                handleTertiaryOption={handleFormData("stmtFreq")}
                monthDropdownLabel="What day during the period should this fall on?"
                freqOutContentDesc="
                If the date falls on a non-business day, such as a weekend or holiday, the charge will still be occur on the"
                freqOutContent="A new charge will be occur every"
                monthDDTooltip={
                  tooltipFlyoutDetails?.tooltipsMap?.get("stmtFreqMon")
                    ?.tooltip_text_main
                }
                businessDayTooltip={
                  tooltipFlyoutDetails?.tooltipsMap?.get("stmtFreqNotBusiness")
                    ?.tooltip_text_main
                }
                placeHolderName={"Select matrix"}
                dropdownList={matrixDDOptions}
                schema={schema}
                uiSchema={matrixTypeUiSchema}
                onAddNewSubmit={addNewMatrix}
                modelViewUrl={API_ROUTE_CONFIGURATION.matrix}
              />
              {errors.stmtFreq && touched.stmtFreq ? (
                <ErrorAlert>
                  <span>{errors.stmtFreq}</span>
                </ErrorAlert>
              ) : null}
              {errors.stmtFreqMatrix && touched.stmtFreqMatrix ? (
                <ErrorAlert>
                  <span>{errors.stmtFreqMatrix}</span>
                </ErrorAlert>
              ) : null}
            </Box>
            <Box
              className={
                CadencesStyle["all-four-form-section-component-seperator"]
              }
            >
              <AppFormLabel
                labelName="What is the statement start date? "
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("stmtStartDtmOpt")
                    ?.tooltip_text_main
                }
                labelFor="stmtStartDtmOpt"
              />
              <RadioButtonGroup
                isBoxedRadio={true}
                value={values.stmtStartDtmOpt}
                onChange={handleFormData("stmtStartDtmOpt")}
                radioPropList={startDateOptions}
                spacing={4}
              />
              {errors.stmtStartDtmOpt && touched.stmtStartDtmOpt && (
                <ErrorAlert>
                  <span>{errors.stmtStartDtmOpt}</span>
                </ErrorAlert>
              )}
            </Box>
          </AccordionComponent>
        </CardContainer>

        {/* Interim Updates */}
        <Box className={"app-form-container"}>
          <CardContainer
            customClass={
              CadencesStyle["all-four-form-section-component-seperator"]
            }
          >
            <AccordionComponent
              accordionTitle={
                tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[1]
                  ?.section_title || "Interim updates"
              }
              isExpand={0}
            >
              <Box marginTop="-10">
                <StaticText
                  textValue={
                    tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[1]
                      ?.section_subhead ||
                    "Set preferences for occasional updates or statements that arise between the regular communication cadences."
                  }
                />
              </Box>
              <Box
                className={
                  CadencesStyle["all-four-form-section-component-seperator"]
                }
              >
                <AppFormLabel
                  labelName="Add a new interim statement frequency?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get(
                      "interimStatementFrequencyButton"
                    )?.tooltip_text_main
                  }
                />
                <SplitButton
                  leftBtnName="Yes"
                  rightBtnName="No"
                  value={values.interimStatementFrequencyButton}
                  onSelect={handleFormData("interimStatementFrequencyButton")}
                />
                {errors.interimStatementFrequencyButton &&
                  touched.interimStatementFrequencyButton && (
                    <ErrorAlert>
                      <span>
                        {errors.interimStatementFrequencyButton as string}
                      </span>
                    </ErrorAlert>
                  )}
              </Box>
              {values.interimStatementFrequencyButton && (
                <Box
                  className={
                    CadencesStyle["all-four-form-section-component-seperator"]
                  }
                >
                  <FrequencyModule
                    value={values.interimStmtFreq}
                    onChange={handleFormData("interimStmtFreq")}
                    frequencyQuestionTitle="What is the interim statement frequency?"
                    monthDropdownLabel="On what date should the interim statement frequency be implemented?"
                    frequencyQuestionDescription={
                      tooltipFlyoutDetails?.tooltipsMap?.get("interimStmtFreq")
                        ?.tooltip_text_main
                    }
                    frequencyOutputContent="A new charge will be occur every"
                    frequencyOutputContentDesc="If the date falls on a non-business day, such as a weekend or holiday, the charge will still be occur on the"
                    monthDropdownTooltip={
                      tooltipFlyoutDetails?.tooltipsMap?.get(
                        "interimStmtFreqMon"
                      )?.tooltip_text_main
                    }
                    notBusinessDayTooltip={
                      tooltipFlyoutDetails?.tooltipsMap?.get(
                        "interimStmtFreqNotBusiness"
                      )?.tooltip_text_main
                    }
                    notBusinessDayLabel="If the scheduled date falls on a non-business day, when should the interim statement be posted?"
                  />
                  {errors.interimStmtFreq && touched.interimStmtFreq ? (
                    <ErrorAlert>
                      <span>{errors.interimStmtFreq}</span>
                    </ErrorAlert>
                  ) : null}
                </Box>
              )}
            </AccordionComponent>
          </CardContainer>
        </Box>

        {/* Inactivity Protocols */}
        {isDepositProduct && (
          <Box className={"app-form-container"}>
            <CardContainer
              customClass={
                CadencesStyle["all-four-form-section-component-seperator"]
              }
            >
              <AccordionComponent
                accordionTitle={
                  tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[2]
                    ?.section_title || "Inactivity Protocols"
                }
                isExpand={0}
              >
                <Box marginTop="-10">
                  <StaticText
                    textValue={
                      tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[2]
                        ?.section_subhead ||
                      "Decide on how periods of no activity are flagged, managed, and if any charges are applied during such periods."
                    }
                  />
                </Box>
                <Box
                  className={
                    CadencesStyle["all-four-form-section-component-seperator"]
                  }
                >
                  <DurationInput
                    labelName={"What is the inactive status duration?"}
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get(
                        "inactiveStatusDur"
                      )?.tooltip_text_main
                    }
                    value={values.inactiveStatusDur}
                    onChange={handleFormData("inactiveStatusDur")}
                    modalLinkName={"Enter code manually"}
                    modalTitle={"Enter Inactive Status Duration"}
                    modalLabelName={"What is the inactive status duration?"}
                    modalLabelTooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get(
                        "inactiveStatusDur"
                      )?.tooltip_text_main
                    }
                  />
                  {errors.inactiveStatusDur && touched.inactiveStatusDur && (
                    <ErrorAlert>
                      <span>{errors.inactiveStatusDur as string}</span>
                    </ErrorAlert>
                  )}
                </Box>

                <Box
                  className={
                    CadencesStyle["all-four-form-section-component-seperator"]
                  }
                >
                  <FrequencyModule
                    value={values.inactiveStatusAlertFreq}
                    onChange={handleFormData("inactiveStatusAlertFreq")}
                    frequencyQuestionTitle="What is the inactive status alert frequency?"
                    monthDropdownLabel="On what date should the alert occur?"
                    frequencyQuestionDescription={
                      tooltipFlyoutDetails?.tooltipsMap?.get(
                        "inactiveStatusAlertFreq"
                      )?.tooltip_text_main
                    }
                    frequencyOutputContent="A new charge will be occur every"
                    frequencyOutputContentDesc="If the date falls on a non-business day, such as a weekend or holiday, the charge will still be occur on the"
                    monthDropdownTooltip={
                      tooltipFlyoutDetails?.tooltipsMap?.get(
                        "inactiveStatusAlertFreqMon"
                      )?.tooltip_text_main
                    }
                    notBusinessDayTooltip={
                      tooltipFlyoutDetails?.tooltipsMap?.get(
                        "inactiveStatusAlertFreqNotBusiness"
                      )?.tooltip_text_main
                    }
                    notBusinessDayLabel="If the scheduled date falls on a non-business day, when should the alert occur?"
                  />
                  {errors.inactiveStatusAlertFreq &&
                  touched.inactiveStatusAlertFreq ? (
                    <ErrorAlert>
                      <span>{errors.inactiveStatusAlertFreq}</span>
                    </ErrorAlert>
                  ) : null}
                </Box>
                <Box
                  className={
                    CadencesStyle["all-four-form-section-component-seperator"]
                  }
                >
                  <AppFormLabel
                    labelName="Set an inactivity fee"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get(
                        "inactiveStatusFee"
                      )?.tooltip_text_main
                    }
                    labelFor="inactivity_fee"
                  />
                  <CoupleRadioWithDropdown
                    id="inactivity_fee"
                    primaryOptionValue={values.inactiveStatusFee}
                    primaryRadioName={"Select an existing fee"}
                    secondaryRadioName={"Add a new fee"}
                    handlePrimaryOptionSelect={handleFormData(
                      "inactiveStatusFee"
                    )}
                    placeHolderName="Select existing fee"
                    dropdownList={feeOptions}
                    schema={inactivityFeeschema}
                    uiSchema={getFeeCalcDtlUiSchema()}
                    unRequire={feeCalcDtlUnRequire}
                    onAddNewSubmit={addNewInactivityOption}
                    modelViewUrl={API_ROUTE_CONFIGURATION.feeDetails}
                  />
                  {errors.inactiveStatusFee && touched.inactiveStatusFee && (
                    <ErrorAlert>
                      <span>{errors.inactiveStatusFee as string}</span>
                    </ErrorAlert>
                  )}
                </Box>
                <Box
                  className={
                    CadencesStyle["all-four-form-section-component-seperator"]
                  }
                >
                  <AppFormLabel
                    labelName="Set the inactive status restriction code"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get(
                        "inactiveStatusRestrictCode"
                      )?.tooltip_text_main
                    }
                    labelFor="inactivity_restriction_code"
                  />
                  <CoupleRadioWithDropdown
                    id="inactivity_restriction_code"
                    primaryOptionValue={values.inactiveStatusRestrictCode}
                    primaryRadioName={"Select from existing restriction codes"}
                    secondaryRadioName={"Add a new restriction code"}
                    handlePrimaryOptionSelect={handleFormData(
                      "inactiveStatusRestrictCode"
                    )}
                    placeHolderName="Select existing fee"
                    dropdownList={restrictionCodes}
                    schema={inactiveStatusSchema}
                    uiSchema={exceptRuleUiSchema}
                    onAddNewSubmit={addNewInactiveStatusOption}
                    modelViewUrl={API_ROUTE_CONFIGURATION.exceptRule}
                  />
                  {errors.inactiveStatusRestrictCode &&
                  touched.inactiveStatusRestrictCode ? (
                    <ErrorAlert>
                      <span>{errors.inactiveStatusRestrictCode}</span>
                    </ErrorAlert>
                  ) : null}
                </Box>
              </AccordionComponent>
            </CardContainer>
          </Box>
        )}
        {/* Dormancy Management */}
        {isDepositProduct && (
          <Box className={"app-form-container"}>
            <CardContainer
              customClass={
                CadencesStyle["all-four-form-section-component-seperator"]
              }
            >
              <AccordionComponent
                accordionTitle={
                  tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[3]
                    ?.section_title || "Dormancy Management"
                }
                isExpand={0}
              >
                <Box marginTop="-10">
                  <StaticText
                    textValue={
                      tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[3]
                        ?.section_subhead ||
                      "Establish guidelines for when an account goes silent for an extended period, from alerts to potential charges."
                    }
                  />
                </Box>
                <Box
                  className={
                    CadencesStyle["all-four-form-section-component-seperator"]
                  }
                >
                  <DurationInput
                    labelName={"What is the dormant status duration?"}
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("dormantStatusDur")
                        ?.tooltip_text_main
                    }
                    value={values.dormantStatusDur}
                    onChange={handleFormData("dormantStatusDur")}
                    modalLinkName={"Enter code manually"}
                    modalTitle={"Enter Dormant Status Duration"}
                    modalLabelName={"What is the dormant status duration?"}
                    modalLabelTooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("dormantStatusDur")
                        ?.tooltip_text_main
                    }
                  />
                  {errors.dormantStatusDur && touched.dormantStatusDur && (
                    <ErrorAlert>
                      <span>{errors.dormantStatusDur as string}</span>
                    </ErrorAlert>
                  )}
                </Box>

                <Box
                  className={
                    CadencesStyle["all-four-form-section-component-seperator"]
                  }
                >
                  <FrequencyModule
                    value={values.dormantStatusAlertFreq}
                    onChange={handleFormData("dormantStatusAlertFreq")}
                    frequencyQuestionTitle="What is the dormant status alert frequency?"
                    monthDropdownLabel="On what date should the alert occur?"
                    frequencyQuestionDescription={
                      tooltipFlyoutDetails?.tooltipsMap?.get(
                        "dormantStatusAlertFreq"
                      )?.tooltip_text_main
                    }
                    frequencyOutputContent="A new charge will be occur every"
                    frequencyOutputContentDesc="If the date falls on a non-business day, such as a weekend or holiday, the charge will still be occur on the"
                    monthDropdownTooltip={
                      tooltipFlyoutDetails?.tooltipsMap?.get(
                        "dormantStatusAlertFreqMon"
                      )?.tooltip_text_main
                    }
                    notBusinessDayTooltip={
                      tooltipFlyoutDetails?.tooltipsMap?.get(
                        "dormantStatusAlertFreqNotBusiness"
                      )?.tooltip_text_main
                    }
                    notBusinessDayLabel="If the scheduled date falls on a non-business day, when should the alert occur?"
                  />
                  {errors.dormantStatusAlertFreq &&
                  touched.dormantStatusAlertFreq ? (
                    <ErrorAlert>
                      <span>{errors.dormantStatusAlertFreq}</span>
                    </ErrorAlert>
                  ) : null}
                </Box>
                <Box
                  className={
                    CadencesStyle["all-four-form-section-component-seperator"]
                  }
                >
                  <AppFormLabel
                    labelName="Set a dormant status fee"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("dormantStatusFee")
                        ?.tooltip_text_main
                    }
                    labelFor="dormant_fee"
                  />
                  <CoupleRadioWithDropdown
                    id="dormant_fee"
                    primaryOptionValue={values.dormantStatusFee}
                    primaryRadioName={"Select an existing fee"}
                    secondaryRadioName={"Add a new fee"}
                    handlePrimaryOptionSelect={handleFormData(
                      "dormantStatusFee"
                    )}
                    placeHolderName="Select existing fee"
                    dropdownList={feeOptions}
                    schema={dormantSchema}
                    uiSchema={getFeeCalcDtlUiSchema()}
                    unRequire={feeCalcDtlUnRequire}
                    onAddNewSubmit={addNewDormantOption}
                    modelViewUrl={API_ROUTE_CONFIGURATION.feeDetails}
                  />
                  {errors.dormantStatusFee && touched.dormantStatusFee && (
                    <ErrorAlert>
                      <span>{errors.dormantStatusFee as string}</span>
                    </ErrorAlert>
                  )}
                </Box>
                <Box
                  className={
                    CadencesStyle["all-four-form-section-component-seperator"]
                  }
                >
                  <AppFormLabel
                    labelName="What is the dormant status restriction code?"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get(
                        "dormantStatusRestrictCode"
                      )?.tooltip_text_main
                    }
                    labelFor="dormant_restriction"
                  />
                  <CoupleRadioWithDropdown
                    id="dormant_restriction"
                    primaryOptionValue={values.dormantStatusRestrictCode}
                    primaryRadioName={"Select from existing restriction codes"}
                    secondaryRadioName={"Add a new code"}
                    handlePrimaryOptionSelect={handleFormData(
                      "dormantStatusRestrictCode"
                    )}
                    placeHolderName="Select existing fee"
                    dropdownList={restrictionCodes}
                    schema={dormantStatusSchema}
                    uiSchema={exceptRuleUiSchema}
                    onAddNewSubmit={addNewDormantStatusOption}
                    modelViewUrl={API_ROUTE_CONFIGURATION.exceptRule}
                  />
                  {errors.dormantStatusRestrictCode &&
                    touched.dormantStatusRestrictCode && (
                      <ErrorAlert>
                        <span>
                          {errors.dormantStatusRestrictCode as string}
                        </span>
                      </ErrorAlert>
                    )}
                </Box>
              </AccordionComponent>
            </CardContainer>
          </Box>
        )}
      </Box>
    </form>
  );
};

export default ProductCandences;
