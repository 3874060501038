"use client";

import * as prismic from "@prismicio/client";
import {
  Link,
  Stack,
  CardBody,
  Text,
  CardFooter,
  HStack,
} from "@/components/ChakraUiManager";
import "./TutorialPreview.scss";
import { ROUTE_PATH } from "@/route-config/route-path";
import { CardContainer } from "@/components/common";

export default function TutorialPreview(props: any) {
  const { data } = props;

  return (
    <Link
      href={`${ROUTE_PATH["TUTORIAL_DETAIL"]}/${data.uid}`}
      className={"preview-link"}
    >
      <CardContainer customClass="tutorial-preview">
        <Stack gap={6.4}>
          <CardBody p={0}>
            <Text as="h4" className="title">
              {data.title}
            </Text>
            <Text className="preview-text">
              {prismic.asText(data.previewText)}
            </Text>
          </CardBody>
          <CardFooter p={0}>
            <HStack flexWrap="wrap" gap={3.2}>
              {data.tags.map((tag: any, i: number) => (
                <Text className="tag" key={i}>
                  {tag}
                </Text>
              ))}
            </HStack>
          </CardFooter>
        </Stack>
      </CardContainer>
    </Link>
  );
}
