"use client";

import {
  AccordionComponent,
  AppFormLabel,
  SelectDropdown,
  CardContainer,
  ErrorAlert,
  InputText,
  ModalSelector,
  StaticText,
} from "@/components/common";
import AddNewButton from "@/components/common/add-new-button/AddNewButton";

import { Box, Heading, useToast } from "@chakra-ui/react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import {
  SetsArrayProps,
  SetsValuesProps,
  sets,
  setsArray,
  getValidationSchema,
} from "./SetsValidation";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { createSets, updateSets } from "../gl-config-api-service";
import {
  GL_CONFIG_ORDER,
  GL_ENTITY,
  GL_STEPS,
  NEW_IC_ENTITY_ID,
  Option,
  getGeneralLedgerAccountGroup,
} from "@/utils";
import { SetsData, TransformedObj } from "../model";
import { ROUTE_PATH } from "@/route-config/route-path";
import { useSearchParams } from "next/navigation";
import "./Sets.scss";
import {
  GLWorkflowResponse,
  WorkflowResponseUnion,
  createWorkflowGeneric,
  getAllWorkflowGeneric,
  updateWorkflowGeneric,
} from "@/api-config/api-service";
import Style from "./Sets.module.scss";
import { getGLCopyFlagInStorage } from "../general-ledger-util-service/general-ledger-util-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type SetsProps = {
  data: {
    accountGroupsOption: { label: string; value: number }[];
    accountNumberOptions: { label: string; value: number; acctGroup: string }[];
    glSubAccountsOption: Option<string>[];
    formData: SetsData | null;
    setsOptionsData: PostSchema;
  };
};

const getObjFromArray = (data: SetsArrayProps[]) => {
  const convertedObj = data.reduce((obj, item) => {
    if (!item.additionalProp.length) {
      return obj;
    }
    const additionalProp = item.additionalProp;
    const acctNbr = item.acctNbr;
    const expAcctNbr = item.expAcctNbr;
    const incAcctNbr = item.incAcctNbr;
    obj[additionalProp] = {
      acctGroup: item.acctGroup,
      acctNbr: acctNbr,
      expAcctNbr: expAcctNbr,
      incAcctNbr: incAcctNbr,
    };
    return obj;
  }, {} as TransformedObj);
  return convertedObj;
};

const getArrayFromObject = (
  data: TransformedObj,
  glSubAccountsOption: Option<string>[]
) => {
  if (Object.keys(data).length > 0 && glSubAccountsOption.length > 0) {
    const array = Object.entries(data).map(([key, value]) => ({
      accKey: key,
      acctGroup: value.acctGroup ?? 2,
      acctNbr: value.acctNbr ?? "",
      expAcctNbr: value.expAcctNbr ?? "",
      incAcctNbr: value.incAcctNbr ?? "",
    }));
    return array;
  } else {
    return [];
  }
};

const filterAccountNumberOptions = (
  subAccOpts: Option<string>[],
  accountkeyData: SetsArrayProps[],
  index: number
) => {
  const filteredAccountKeyData = accountkeyData.filter(
    (_, idx) => idx !== index
  );

  if (subAccOpts.length > 0 && filteredAccountKeyData) {
    const array = subAccOpts.filter(
      (item) =>
        !filteredAccountKeyData.some(
          (filteredData) =>
            filteredData.additionalProp === item.value.toString()
        )
    );
    return array;
  } else {
    return subAccOpts;
  }
};

const Sets = (props: SetsProps) => {
  const toast = useToast();
  const toastId = "sets-toast";
  const setsFormData = props.data?.formData ?? null;
  const glSubAccountsOption =
    props.data?.glSubAccountsOption !== undefined
      ? props.data?.glSubAccountsOption
      : [];
  const accountNumberOptions =
    props.data?.accountNumberOptions !== undefined
      ? props.data?.accountNumberOptions
      : [];

  const [workFlowData, setWorkFlowData] = useState<
    WorkflowResponseUnion[] | null
  >(null);

  const { setsOptionsData } = props.data;

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    checkFormStatus,
    navigateTo,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const params = useSearchParams();

  const moveToNextStep = () => {
    if (params?.get("initialFlow")) {
      navigateTo(
        `${ROUTE_PATH.GL_INTERSTITIAL_SCREEN}?entityId=${values.glSetCode}&entityType=${GL_ENTITY.sets}&initialFlow=true`
      );
    } else {
      navigateTo(
        `${ROUTE_PATH.GL_COMPLETION_SCREEN}?entityId=${values.glSetCode}&entityType=${GL_ENTITY.sets}`
      );
    }
  };

  // Form Submit Handler
  const onSubmit = async (values: SetsValuesProps) => {
    if (!dirty) {
      if (
        workFlowData?.some(
          (data: any) => data.modelKey === `${values.glSetCode}`
        )
      ) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
          key: `${values.glSetCode}`,
          stage: GL_ENTITY.sets,
          status: GL_STEPS.sets,
        });
      } else {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.generalLedgerConfig,
          key: `${values.glSetCode}`,
          stage: GL_ENTITY.sets,
          status: GL_STEPS.sets,
        });
      }

      moveToNextStep();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId,
    };
    if (!setsFormData || getGLCopyFlagInStorage()) {
      response = await createSets({
        formData: {
          balAcctGroup: values.balAcctGroup,
          balAcctNbr: values.balAcctNbr,
          desc: values.desc,
          glSetCode: values.glSetCode,
          subBalAccts: getObjFromArray(values.subBalAccts),
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `GL Set created.`,
        },
        stageName: GL_STEPS.sets,
      });
    } else {
      response = await updateSets({
        formData: {
          _vn: setsFormData._vn,
          balAcctGroup: values.balAcctGroup,
          balAcctNbr: values.balAcctNbr,
          desc: values.desc,
          glSetCode: values.glSetCode,
          subBalAccts: getObjFromArray(values.subBalAccts),
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `${configPageTitle} updated.`,
        },
        stageName: GL_STEPS.sets,
        updateWorkFlow: workFlowData?.some(
          (data) => data.modelKey === `${values.glSetCode}`
        )
          ? true
          : false,
      });
    }
    if (response) {
      moveToNextStep();
    }
  };
  // Handler for all the form fields
  const handleOnChangeAccordion =
    (key: string, index: number) => (value: any) => {
      if (key === "acctGroup") {
        setFieldValue(`subBalAccts.${index}.${key}`, value).then((res) => {
          setFieldValue(`subBalAccts.${index}.${"acctNbr"}`, "").then((res) => {
            setFieldValue(`subBalAccts.${index}.${"expAcctNbr"}`, "").then(
              (res) => {
                setFieldValue(`subBalAccts.${index}.${"incAcctNbr"}`, "");
              }
            );
          });
        });
      } else {
        setFieldValue(`subBalAccts.${index}.${key}`, value);
      }
    };

  const handleOnChange = (key: string) => (value: string) => {
    if (key === "balAcctGroup") {
      setFieldValue(key, value).then((res) => {
        setFieldValue("balAcctNbr", "");
      });
    } else {
      setFieldValue(key, value);
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(setsOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [setsOptionsData]);

  let initialSubBalAccts: SetsArrayProps[] =
    props.data.formData?.subBalAccts !== undefined &&
    Object.keys(props.data.formData?.subBalAccts).length > 0 &&
    setsFormData !== undefined
      ? getArrayFromObject(
          setsFormData?.subBalAccts as TransformedObj,
          glSubAccountsOption
        ).map((account: any, idx: number) => ({
          idx: idx,
          additionalProp: account.accKey ?? setsArray.additionalProp,
          acctGroup: account.acctGroup ?? setsArray.acctGroup,
          acctNbr: account.acctNbr ?? setsArray.acctNbr,
          expAcctNbr: account.expAcctNbr ?? setsArray.expAcctNbr,
          incAcctNbr: account.incAcctNbr ?? setsArray.incAcctNbr,
        }))
      : [];

  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      balAcctGroup: setsFormData?.balAcctGroup
        ? setsFormData.balAcctGroup
        : getGeneralLedgerAccountGroup(
            props.data.accountGroupsOption,
            sets.balAcctGroup
          ),
      balAcctNbr: setsFormData?.balAcctNbr ?? sets.balAcctNbr,
      glSetCode: getGLCopyFlagInStorage()
        ? sets.glSetCode
        : setsFormData?.glSetCode ?? sets.glSetCode,
      desc: setsFormData?.desc ?? sets.desc,
      subBalAccts: initialSubBalAccts,
    },
  });
  const { values, handleSubmit, dirty, touched, setFieldValue } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const errors: any = formik.errors;

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
      checkFormStatus?.(false);
    }
    // Find the correct entityId from workflow data for going back
    if (isGoingBack) {
      const backEntityId = entityWorkflowData?.find(
        (entity) => entity.stage === GL_ENTITY.system_accounts
      )?.modelKey;
      navigateTo(
        `${ROUTE_PATH.GL_SYSTEM_ACCOUNTS}?entityId=${backEntityId ?? NEW_IC_ENTITY_ID}`
      );
    }
    (async () => {
      // Get all GL workflows to determine what page to display
      const [response] = await Promise.all([
        getAllWorkflowGeneric(API_ROUTE_CONFIGURATION.generalLedgerConfig),
      ]);
      // Sort before storing
      response.sort(
        (entityA: GLWorkflowResponse, entityB: GLWorkflowResponse) => {
          return (
            GL_CONFIG_ORDER[entityA.stage] - GL_CONFIG_ORDER[entityB.stage]
          );
        }
      );
      setWorkFlowData(response);
    })();
  }, [canCheckFormStatus, isGoingBack]);

  return (
    <form onSubmit={formik.handleSubmit} id="finxact-form" noValidate>
      <CardContainer customClass="app-form-container app-form-field-container">
        <Box className="app-form-field-container">
          <Heading
            as="h3"
            className={`${Style["app-form-field-container-sets"]} app-form-field-header`}
          >
            General account information
          </Heading>
          <StaticText
            textValue={
              "Establish key account information to create the general ledger set"
            }
            className={Style["static-text-sets-style"]}
          />
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the account group?"
            isRequired
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("balAcctGroup")
                ?.tooltip_text_main
            }
            labelFor="account-group"
          />
          <SelectDropdown
            value={values.balAcctGroup as any}
            onChange={handleOnChange("balAcctGroup")}
            dropdownList={props.data.accountGroupsOption}
            id="account-group"
          />
          {errors.balAcctGroup && touched.balAcctGroup && (
            <ErrorAlert>
              <span>{errors.balAcctGroup}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the account number the position balance accumulates to?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("balAcctNbr")
                ?.tooltip_text_main
            }
            isRequired
            labelFor={`position-balance-account-number_input`}
          />
          <SelectDropdown
            value={values.balAcctNbr}
            onChange={handleOnChange("balAcctNbr")}
            dropdownList={props.data.accountNumberOptions.filter(
              (acc) => parseInt(acc.acctGroup) === values.balAcctGroup
            )}
            id={`position-balance-account-number`}
            withDescription
          />
          {touched.balAcctNbr && errors.balAcctNbr && (
            <ErrorAlert>
              <span>{errors.balAcctNbr}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
      <CardContainer customClass="app-form-container app-form-field-container">
        <Box className="app-form-field-container">
          <Heading
            as="h3"
            className={`${Style["app-form-field-container-sets"]} app-form-field-header`}
          >
            General ledger sets information
          </Heading>
          <StaticText
            textValue={
              "Establish identifiers and additional specifications for the general ledger set"
            }
            className={Style["static-text-sets-style"]}
          />
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the set code for the new GL set?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("glSetCode")
                ?.tooltip_text_main
            }
            isRequired
          />
          <InputText
            value={values.glSetCode}
            onChange={handleOnChange("glSetCode")}
            disabled={Boolean(
              setsFormData?.glSetCode && !getGLCopyFlagInStorage()
            )}
          />
          {errors.glSetCode && touched.glSetCode && (
            <ErrorAlert>
              <span>{errors.glSetCode}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the GL set description?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("desc")?.tooltip_text_main
            }
          />
          <InputText value={values.desc} onChange={handleOnChange("desc")} />
          {errors.desc && touched.desc && (
            <ErrorAlert>
              <span>{errors.desc}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
      <FormikProvider value={formik}>
        <FieldArray
          name="subBalAccts"
          render={({ insert, remove, push }) => (
            <Box className="app-form-container">
              {values.subBalAccts?.map((subBalAcct, index) => (
                <CardContainer
                  customClass="app-form-field-container"
                  key={subBalAcct.idx}
                >
                  <AccordionComponent
                    accordionTitle={`Sub-balance account ${index + 1}`}
                    deleteIcon
                    onDeleteHandler={() => remove(index)}
                    isExpand={0}
                  >
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="Please select a key for the new sub-balance GL accounts"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("subBalAccts")
                            ?.tooltip_text_main
                        }
                        isRequired={false}
                      />
                      <ModalSelector
                        linkName="+ Please select a key for the new sub-balance GL accounts"
                        modalTitle="Select a sub-balance account key"
                        optionList={filterAccountNumberOptions(
                          glSubAccountsOption,
                          values.subBalAccts,
                          index
                        )}
                        onChange={handleOnChangeAccordion(
                          "additionalProp",
                          index
                        )}
                        value={subBalAcct?.additionalProp}
                        optionsTitle="Sub-balance Account"
                        optionsDescTitle="Description"
                        showSelection="value - description"
                      />
                      {touched.subBalAccts &&
                        touched.subBalAccts[index]?.additionalProp &&
                        errors?.subBalAccts &&
                        errors?.subBalAccts?.[index]?.additionalProp && (
                          <ErrorAlert>
                            <span>
                              {errors.subBalAccts[index].additionalProp}
                            </span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the account group?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("acctGroup")
                            ?.tooltip_text_main
                        }
                        labelFor={`account-group-${index}_input`}
                      />
                      <SelectDropdown
                        value={subBalAcct.acctGroup}
                        onChange={handleOnChangeAccordion("acctGroup", index)}
                        dropdownList={props.data.accountGroupsOption}
                        id={`account-group-${index}`}
                      />
                      {touched.subBalAccts &&
                        touched.subBalAccts[index]?.acctGroup &&
                        errors?.subBalAccts &&
                        errors?.subBalAccts?.[index]?.acctGroup && (
                          <ErrorAlert>
                            <span>{errors.subBalAccts[index].acctGroup}</span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the balance account number for a simple sub-balance accumulation?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("acctNbr")
                            ?.tooltip_text_main
                        }
                      />
                      <ModalSelector
                        linkName="+ Select balance account number"
                        modalTitle="Select balance account number"
                        optionList={props.data.accountNumberOptions.filter(
                          (acc) =>
                            parseInt(acc.acctGroup) ===
                            values.subBalAccts?.[index].acctGroup
                        )}
                        onChange={handleOnChangeAccordion("acctNbr", index)}
                        value={subBalAcct?.acctNbr}
                        optionsTitle="Balance Account"
                        optionsDescTitle="Description"
                        showSelection="value - description"
                      />
                      {touched.subBalAccts &&
                        touched.subBalAccts[index]?.acctNbr &&
                        errors?.subBalAccts &&
                        errors?.subBalAccts?.[index]?.acctNbr && (
                          <ErrorAlert>
                            <span>{errors.subBalAccts[index].acctNbr}</span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the expense account number for sub-balance expense accumulation?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("expAcctNbr")
                            ?.tooltip_text_main
                        }
                      />
                      <ModalSelector
                        linkName="+ Select expense account number"
                        modalTitle="Select expense account number"
                        optionList={props.data.accountNumberOptions.filter(
                          (acc) =>
                            parseInt(acc.acctGroup) ===
                            values.subBalAccts?.[index].acctGroup
                        )}
                        onChange={handleOnChangeAccordion("expAcctNbr", index)}
                        value={subBalAcct?.expAcctNbr}
                        optionsTitle="Expense Account"
                        optionsDescTitle="Description"
                        showSelection="value - description"
                      />
                      {touched.subBalAccts &&
                        touched.subBalAccts[index]?.expAcctNbr &&
                        errors?.subBalAccts &&
                        errors?.subBalAccts?.[index]?.expAcctNbr && (
                          <ErrorAlert>
                            <span>{errors.subBalAccts[index].expAcctNbr}</span>
                          </ErrorAlert>
                        )}
                    </Box>
                    <Box className="app-form-field-container">
                      <AppFormLabel
                        labelName="What is the income account number for sub-balance income accumulation?"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("incAcctNbr")
                            ?.tooltip_text_main
                        }
                      />
                      <ModalSelector
                        linkName="+ Select income account number"
                        modalTitle="Select income account number"
                        optionList={props.data.accountNumberOptions.filter(
                          (acc) =>
                            parseInt(acc.acctGroup) ===
                            values.subBalAccts?.[index].acctGroup
                        )}
                        onChange={handleOnChangeAccordion("incAcctNbr", index)}
                        value={subBalAcct?.incAcctNbr}
                        optionsTitle="Income Account"
                        optionsDescTitle="Description"
                        showSelection="value - description"
                      />
                      {touched.subBalAccts &&
                        touched.subBalAccts[index]?.incAcctNbr &&
                        errors?.subBalAccts &&
                        errors?.subBalAccts?.[index]?.incAcctNbr && (
                          <ErrorAlert>
                            <span>{errors.subBalAccts[index].incAcctNbr}</span>
                          </ErrorAlert>
                        )}
                    </Box>
                  </AccordionComponent>
                </CardContainer>
              ))}
              <Box>
                <AddNewButton
                  label={"Add new sub-balance account"}
                  onClick={() => {
                    push({
                      ...setsArray,
                      idx: Math.random(),
                    });
                  }}
                />
              </Box>
            </Box>
          )}
        ></FieldArray>
      </FormikProvider>
    </form>
  );
};

export default Sets;
