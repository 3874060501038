"use client";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  AccordionComponent,
  AppFormLabel,
  AppNumberInput,
  SelectDropdown,
  CardContainer,
  CoupleRadioWithDropdown,
  DatePicker,
  ErrorAlert,
  InputText,
  ModalSelector,
  ProductTitle,
  RadioButtonGroup,
  SplitButton,
  StaticText,
  TimeField,
} from "@/components/common";
import {
  Box,
  CardBody,
  Flex,
  Stack,
  useToast,
} from "@/components/ChakraUiManager";
import BasicProductStyle from "./BasicProduct.module.scss";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Option, RjsfData } from "@/utils/types";
import { useFormik } from "formik";
import {
  BasicProductFormProps,
  PRODUCT_AVAILABILITY_OPTIONS,
  getValidationSchema,
} from "./BasicProductValidation";
import { updateProduct, updateWorkflowGeneric } from "@/api-config/api-service";
import {
  ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS,
  IFX_ACCOUNT_MAPPING,
  MAIN_FORM_ID,
  POSITION_CLASS,
  makeDateTime,
  preventMainFormSubmitOnRJSFSubmit,
  splitDateTime,
} from "@/utils";
import { ProductType } from "@/components/product-management/model/product-models";
import { PRODUCT_STATUS } from "@/components/data/form-data";
import { addNewComponent } from "../../product-config-client-service";
import { glSetUiSchema } from "@finxact/finxact-shared-ui";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

interface AssetIdOption extends Option<string> {
  assetClass: number;
}

type Props = {
  data: {
    glSetCodeOptions: Option<string>[];
    startDateOptions: Option<number>[];
    assetClassOptions: Option<number>[];
    assetIdOptions: AssetIdOption[];
    glMatrixOptions: Option<string>[];
    indexNameOptions: string[];
    glCategories: Record<string, string>;
    addNewGLSetSchema: any;
    addNewGLSetEndpoint: string;
    formData: ProductType | null;
    basicProductOptionsData: PostSchema;
    productType: string[];
    productSubType: Option<string>[];
    productGroup: Option<string>[];
  };
};

const BasicProduct = (props: Props) => {
  const toast = useToast();
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getExistingProduct,
    tooltipFlyoutDetails,
    getWorflowStatusToBeUpdated,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const {
    formData,
    glSetCodeOptions,
    glMatrixOptions,
    indexNameOptions,
    startDateOptions,
    assetClassOptions,
    assetIdOptions,
    glCategories,
    basicProductOptionsData,
    productType,
    productSubType,
    productGroup,
  } = props.data;

  const _setInitialProductAvailability = () => {
    switch (true) {
      case Boolean(formData?.avlStartDtmMatrix):
        return PRODUCT_AVAILABILITY_OPTIONS.matrix;
      case Boolean(formData?.avlStartDtm):
        return PRODUCT_AVAILABILITY_OPTIONS.customDate;
      case formData?.stmtStartDtmOpt !== 0
        ? Boolean(formData?.stmtStartDtmOpt)
        : true:
        return PRODUCT_AVAILABILITY_OPTIONS.knownDate;
      default:
        return "";
    }
  };

  const onSubmit = async (values: BasicProductFormProps) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS["productBasics"],
          status: PRODUCT_STATUS.completed,
        });
      return;
    }

    if (formData) {
      const res = await updateProduct({
        productName: formData.name,
        body: JSON.stringify({
          // NOTE: _vn, componentName, version is required to do a PATCH update
          _vn: formData?._vn,
          // PATCH fields
          assetClass: values.assetClass,
          assetId: values.assetId,
          glSetMatrixName: values.isGLMatrixRequired
            ? values.glSetMatrixName
            : null,
          glSetCode: values.glSetCode === "" ? null : values.glSetCode,
          posnAcctNbrPrefix: values.posnAcctNbrPrefix
            ? values.posnAcctNbrPrefix
            : null,
          ...(values.operationalStartSectionDate &&
          values.productAvailability === PRODUCT_AVAILABILITY_OPTIONS.customDate
            ? {
                avlStartDtm: makeDateTime(
                  values.operationalStartSectionDate,
                  values.operationalStartSectionTime
                ),
              }
            : { avlStartDtm: null }),
          ...(values.productAvailability === PRODUCT_AVAILABILITY_OPTIONS.matrix
            ? { avlStartDtmMatrix: values.avlStartDtmMatrix }
            : { avlStartDtmMatrix: null }),
          ...(values.operationalEndSectionDate
            ? {
                avlEndDtm: makeDateTime(
                  values.operationalEndSectionDate,
                  values.operationalEndSectionTime
                ),
              }
            : {}),

          prodType: values.prodType !== "" ? values.prodType : null,
          prodSubType: values.prodSubType !== "" ? values.prodSubType : null,
          prodGroup: values.prodGroup !== "" ? values.prodGroup : null,
          version: values.version ?? null,
        } as ProductType),
      });
      if (res && productDetails?.name) {
        if (!toast.isActive("product-basics")) {
          toast({
            id: "product-basics",
            description: `${tooltipFlyoutDetails?.pageHeaderDetails?.heading || "Products Basics"} updated`,
            status: "success",
          });
        }
        getExistingProduct?.(productDetails.name, true);
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails.name,
          stage: ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS["productBasics"],
          status: PRODUCT_STATUS.completed,
        });
      }
    }
  };

  const addNewOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      props.data.addNewGLSetEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue(`glSetCode`, response.glSetCode);
  };

  const schema = useMemo(() => {
    const { actions, name } = props.data.addNewGLSetSchema;
    return { name, ...actions.POST };
  }, [props.data.addNewGLSetSchema]);

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(basicProductOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [basicProductOptionsData]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      // helper fields
      productAvailability: _setInitialProductAvailability(),
      isGLMatrixRequired: Boolean(formData?.glSetMatrixName),
      // form fields
      assetClass: formData?.assetClass ?? undefined,
      assetId: formData?.assetId ?? "",
      glSetCode: formData?.glSetCode ?? "",
      glSetMatrixName: formData?.glSetMatrixName ?? "",
      avlStartDtmMatrix: formData?.avlStartDtmMatrix ?? "",
      stmtStartDtmOpt: formData?.stmtStartDtmOpt ?? 0,
      operationalStartSectionDate: formData?.avlStartDtm
        ? splitDateTime(formData.avlStartDtm).date
        : null,
      operationalStartSectionTime: formData?.avlStartDtm
        ? splitDateTime(formData.avlStartDtm).time
        : "",
      operationalEndSectionDate: formData?.avlEndDtm
        ? splitDateTime(formData.avlEndDtm).date
        : null,
      operationalEndSectionTime: formData?.avlEndDtm
        ? splitDateTime(formData.avlEndDtm).time
        : "",
      posnAcctNbrPrefix: formData?.posnAcctNbrPrefix ?? "",
      prodGroup: formData?.prodGroup ?? "",
      prodSubType: formData?.prodSubType ?? "",
      prodType: formData?.prodType ?? "",
      version: formData?.version ?? 0,
    },
  });
  const {
    values,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    dirty,
    handleBlur,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);
  const filteredAssetIdOptions = useMemo(() => {
    return assetIdOptions.filter(
      (option) => option.assetClass === values.assetClass
    );
  }, [values.assetClass]);
  // Handle form field changes
  const handleFormData = (key: keyof BasicProductFormProps) => (value: any) => {
    if (key === "assetClass") {
      const foundAssetId = filteredAssetIdOptions.find(
        (opt) => opt.assetClass === value
      );
      // unset assetId if it doesn't exist in selected assetClass
      if (!foundAssetId) {
        setFieldValue("assetId", "");
      }
    }
    setFieldValue(key, value);
  };
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus]);

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id={MAIN_FORM_ID}
      noValidate
    >
      <StaticText
        heading
        headingName={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading || "Products Basics"
        }
        pt={"9.6"}
      />
      <Box className={"app-form-container"}>
        <CardContainer>
          <AccordionComponent
            accordionTitle={
              tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
                ?.section_title || "Product hierarchy"
            }
            isExpand={0}
          >
            <Box marginTop="-10" className="app-form-field-container">
              <StaticText
                textValue={
                  tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[0]
                    ?.section_subhead ||
                  "Define your product hierarchy to lay the foundation of your institutional reporting."
                }
              />
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the product type?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("prodType")
                    ?.tooltip_text_main
                }
                labelFor="prodType_input"
              />
              <SelectDropdown
                id="prodType_id"
                value={values.prodType}
                onChange={handleFormData("prodType")}
                placeholder="Select product type"
                dropdownList={productType}
              />
              {errors.prodType && touched.prodType && (
                <ErrorAlert>
                  <span>{errors.prodType}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the product type version?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("version")
                    ?.tooltip_text_main
                }
                labelFor="version_input"
              />
              <AppNumberInput
                label="Product type version"
                value={values.version!}
                onChange={handleFormData("version")}
                onBlur={handleBlur}
              />
              {errors.version && touched.version && (
                <ErrorAlert>
                  <span>{errors.version}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the product subtype?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("prodSubType")
                    ?.tooltip_text_main
                }
                labelFor="prodSubType"
              />
              <SelectDropdown
                id="prodSubType_id"
                value={values.prodSubType}
                onChange={handleFormData("prodSubType")}
                placeholder="Select product subtype"
                dropdownList={productSubType
                  ?.filter((option: any) => option.prodType === values.prodType)
                  ?.map((option: any) => option.prodSubType)}
              />
              {errors.prodSubType && touched.prodSubType && (
                <ErrorAlert>
                  <span>{errors.prodSubType}</span>
                </ErrorAlert>
              )}
            </Box>
            <Box className="app-form-field-container">
              <AppFormLabel
                labelName="What is the product group?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("prodGroup")
                    ?.tooltip_text_main
                }
                labelFor="prodGroup"
              />
              <SelectDropdown
                id="prodGroup_id"
                value={values.prodGroup}
                onChange={handleFormData("prodGroup")}
                placeholder="Select product group"
                dropdownList={productGroup
                  ?.filter(
                    (option: any) => option.prodSubType === values.prodSubType
                  )
                  ?.map((option: any) => option.prodGroup)}
              />
              {errors.prodGroup && touched.prodGroup && (
                <ErrorAlert>
                  <span>{errors.prodGroup}</span>
                </ErrorAlert>
              )}
            </Box>
          </AccordionComponent>
        </CardContainer>
      </Box>
      <Box
        className={
          (BasicProductStyle["basic-product-form-wrapper"],
          "app-form-container")
        }
      >
        {/* Account Details */}
        <CardContainer
          customClass={
            BasicProductStyle["basic-product-account-detail-container"]
          }
        >
          <CardBody>
            <AccordionComponent
              accordionTitle={
                tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[1]
                  ?.section_title || "Account Details"
              }
              isExpand={0}
            >
              <Box marginTop="-10">
                <StaticText
                  textValue={
                    tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[1]
                      ?.section_subhead ||
                    "Determine the fundamental nature and currency for your product, setting the stage for its primary functions and interactions"
                  }
                />
              </Box>

              <Flex
                mt={12}
                mb={8}
                className={BasicProductStyle["account-type-position-class"]}
              >
                <Box flexBasis="40%">
                  <AppFormLabel
                    labelName="IFX account type"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("ifxAcctType")
                        ?.tooltip_text_main
                    }
                  />
                  <StaticText
                    textValue={
                      formData?.ifxAcctType
                        ? IFX_ACCOUNT_MAPPING[formData.ifxAcctType]
                        : ""
                    }
                  />
                </Box>
                <Box flexBasis="60%">
                  <AppFormLabel
                    labelName="Position class"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("posnClass")
                        ?.tooltip_text_main
                    }
                  />
                  <StaticText
                    textValue={
                      formData?.posnClass
                        ? POSITION_CLASS[formData.posnClass]
                        : ""
                    }
                  />
                </Box>
              </Flex>
              <Box mb={12} className={BasicProductStyle["content-seperator"]}>
                <AppFormLabel
                  labelName="What is the position account number prefix?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("posnAcctNbrPrefix")
                      ?.tooltip_text_main
                  }
                />
                <InputText
                  value={values.posnAcctNbrPrefix}
                  onChange={handleFormData("posnAcctNbrPrefix")}
                  onBlur={handleBlur}
                  label="What is the position account number prefix?"
                  name="posnAcctNbrPrefix"
                />
                {errors.posnAcctNbrPrefix && touched.posnAcctNbrPrefix && (
                  <ErrorAlert>
                    <span>{errors.posnAcctNbrPrefix}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box mb={12} className={BasicProductStyle["content-seperator"]}>
                <AppFormLabel
                  labelName="What is the asset class?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("assetClass")
                      ?.tooltip_text_main
                  }
                  labelFor="asset_class"
                />
                <SelectDropdown
                  id="asset_class"
                  value={values.assetClass}
                  onChange={handleFormData("assetClass")}
                  placeholder="Select class identifier"
                  dropdownList={assetClassOptions}
                />
                {errors.assetClass && touched.assetClass && (
                  <ErrorAlert>
                    <span>{errors.assetClass}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="What is the asset identifier?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("assetId")
                      ?.tooltip_text_main
                  }
                  labelFor="asset_id"
                />
                <SelectDropdown
                  id="asset_id"
                  value={values.assetId}
                  onChange={handleFormData("assetId")}
                  placeholder="Select asset identifier"
                  dropdownList={filteredAssetIdOptions}
                />
                {errors.assetId && touched.assetId && (
                  <ErrorAlert>
                    <span>{errors.assetId}</span>
                  </ErrorAlert>
                )}
              </Box>
            </AccordionComponent>
          </CardBody>
        </CardContainer>

        {/* General Ledger Parameters */}

        <CardContainer
          customClass={
            BasicProductStyle["basic-product-general-ledger-container"]
          }
        >
          <AccordionComponent
            accordionTitle={
              tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[2]
                ?.section_title || "General ledger parameters"
            }
            isExpand={0}
          >
            <Box marginTop="-10">
              <StaticText
                textValue={
                  tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[2]
                    ?.section_subhead ||
                  "Guide the system on organizing and categorizing financial transactions, ensuring clarity and precision in record-keeping"
                }
              />
            </Box>
            <Box my={12} className={BasicProductStyle["content-seperator"]}>
              <AppFormLabel
                labelName="General Ledger category"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("glCat")
                    ?.tooltip_text_main
                }
              />
              <StaticText
                textValue={formData?.glCat ? glCategories[formData?.glCat] : ""}
              />
            </Box>
            <Box className={BasicProductStyle["content-seperator"]}>
              <AppFormLabel
                labelName="Do you want a GL set code matrix?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("isGLMatrixRequired")
                    ?.tooltip_text_main
                }
              />
              <SplitButton
                leftBtnName="Yes"
                rightBtnName="No"
                value={values.isGLMatrixRequired}
                onSelect={handleFormData("isGLMatrixRequired")}
              />
              {errors.isGLMatrixRequired && touched.isGLMatrixRequired && (
                <ErrorAlert>
                  <span>{errors.isGLMatrixRequired}</span>
                </ErrorAlert>
              )}
              {values.isGLMatrixRequired && (
                <ModalSelector
                  value={values.glSetMatrixName}
                  linkName={"+Select a matrix"}
                  modalTitle={"General Ledger code"}
                  optionList={glMatrixOptions}
                  onChange={handleFormData("glSetMatrixName")}
                />
              )}
            </Box>
            <Box mt={12} className={BasicProductStyle["content-seperator"]}>
              <AppFormLabel
                labelName="What is the General Ledger set code?"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("isGLMatrixExst")
                    ?.tooltip_text_main +
                  "\n" +
                  tooltipFlyoutDetails?.tooltipsMap?.get("isGLMatrixAdd")
                    ?.tooltip_text_main
                }
                labelFor="gl_set_code"
              />
              <CoupleRadioWithDropdown
                id="gl_set_code"
                primaryOptionValue={values.glSetCode}
                primaryRadioName="Select an existing General Ledger set code"
                secondaryRadioName="Add a new General Ledger set code"
                dropdownList={glSetCodeOptions}
                handlePrimaryOptionSelect={handleFormData("glSetCode")}
                schema={schema}
                uiSchema={glSetUiSchema}
                onAddNewSubmit={addNewOption}
                dropDownLabelName="General Ledger set code"
                isDropdownLabelRequired={true}
                withDescription
              />
            </Box>
          </AccordionComponent>
        </CardContainer>

        {/* Product Operational Dates */}

        <CardContainer
          customClass={BasicProductStyle["basic-product-operational-container"]}
        >
          <CardBody>
            <AccordionComponent
              accordionTitle={
                tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[3]
                  ?.section_title || "Product operational dates"
              }
              isExpand={0}
            >
              <Box marginTop="-10" paddingBottom="32px">
                <StaticText
                  textValue={
                    tooltipFlyoutDetails?.pageHeaderDetails?.sections?.[3]
                      ?.section_subhead ||
                    "Specify the lifecycle of your product, marking when it becomes available and when it concludes."
                  }
                />
              </Box>
              <StaticText
                textValue="Start date"
                className={BasicProductStyle["product-operational-dates-title"]}
              />
              <AppFormLabel
                labelName="When would you like your product to become available? "
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("productAvailability")
                    ?.tooltip_text_main
                }
                labelFor="prod_avlblty"
              />
              <SelectDropdown
                id="prod_avlblty"
                value={values.productAvailability}
                onChange={handleFormData("productAvailability")}
                placeholder="Select"
                dropdownList={Object.values(PRODUCT_AVAILABILITY_OPTIONS)}
              />
              {errors.productAvailability && touched.productAvailability && (
                <ErrorAlert>
                  <span>{errors.productAvailability}</span>
                </ErrorAlert>
              )}
              {values.productAvailability ===
                PRODUCT_AVAILABILITY_OPTIONS.customDate && (
                <Box className="dashed-left-border" marginTop="16px">
                  <Box display="flex" gap="2rem">
                    <Stack flexBasis="50%">
                      <AppFormLabel
                        labelName="Select Available start date "
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get("stmtStartDtm")
                            ?.tooltip_text_main
                        }
                        labelFor="start_date"
                      />
                      <DatePicker
                        id="start_date"
                        value={values.operationalStartSectionDate}
                        onUpdate={handleFormData("operationalStartSectionDate")}
                        placeholder="Select"
                      />
                      {errors.operationalStartSectionDate &&
                        touched.operationalStartSectionDate && (
                          <ErrorAlert>
                            <span>{errors.operationalStartSectionDate}</span>
                          </ErrorAlert>
                        )}
                    </Stack>
                    <Stack flexBasis="50%">
                      <AppFormLabel
                        labelName="Time"
                        tooltipDesc={
                          tooltipFlyoutDetails?.tooltipsMap?.get(
                            "stmtStartTime"
                          )?.tooltip_text_main
                        }
                        labelFor="start_time_input"
                      />
                      <TimeField
                        value={values.operationalStartSectionTime}
                        onChange={handleFormData("operationalStartSectionTime")}
                        ariaLabel="start time"
                      />
                      {errors.operationalStartSectionTime &&
                        touched.operationalStartSectionTime && (
                          <ErrorAlert>
                            <span>{errors.operationalStartSectionTime}</span>
                          </ErrorAlert>
                        )}
                    </Stack>
                  </Box>
                </Box>
              )}
              {values.productAvailability ===
                PRODUCT_AVAILABILITY_OPTIONS.matrix && (
                <Box className="dashed-left-border" marginTop="16px">
                  <SelectDropdown
                    value={values.avlStartDtmMatrix}
                    onChange={handleFormData("avlStartDtmMatrix")}
                    dropdownList={indexNameOptions}
                    placeholder="Select from available start date matrices"
                  />
                  {errors.avlStartDtmMatrix && touched.avlStartDtmMatrix && (
                    <ErrorAlert>
                      <span>{errors.avlStartDtmMatrix}</span>
                    </ErrorAlert>
                  )}
                </Box>
              )}
              <Box paddingTop="32px">
                <StaticText
                  textValue="End date"
                  className={
                    BasicProductStyle["product-operational-dates-title"]
                  }
                />
                <Box display="flex" gap="2rem">
                  <Stack flexBasis="50%">
                    <AppFormLabel
                      labelName="Select available end date "
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("avlEndDtm")
                          ?.tooltip_text_main
                      }
                      labelFor="end_date"
                    />
                    <DatePicker
                      id="end_date"
                      value={values.operationalEndSectionDate}
                      onUpdate={handleFormData("operationalEndSectionDate")}
                      placeholder="Select"
                    />
                    {errors.operationalEndSectionDate &&
                      touched.operationalEndSectionDate && (
                        <ErrorAlert>
                          <span>{errors.operationalEndSectionDate}</span>
                        </ErrorAlert>
                      )}
                  </Stack>
                  <Stack flexBasis="50%">
                    <AppFormLabel
                      labelName="Time"
                      tooltipDesc={
                        tooltipFlyoutDetails?.tooltipsMap?.get("avlEndTime")
                          ?.tooltip_text_main
                      }
                    />
                    <TimeField
                      value={values.operationalEndSectionTime}
                      onChange={handleFormData("operationalEndSectionTime")}
                      ariaLabel="End time"
                    />
                    {errors.operationalEndSectionTime &&
                      touched.operationalEndSectionTime && (
                        <ErrorAlert>
                          <span>{errors.operationalEndSectionTime}</span>
                        </ErrorAlert>
                      )}
                  </Stack>
                </Box>
              </Box>
            </AccordionComponent>
          </CardBody>
        </CardContainer>
      </Box>
    </form>
  );
};

export default BasicProduct;
