"use client";
import { Box, Text, Button, Show } from "@/components/ChakraUiManager";
import Image from "next/image";
import { PrismicRichText } from "@prismicio/react";
import css from "./WelcomeBanner.module.scss";
import { Content } from "@prismicio/client";
import { SVGProps } from "react";

type Props = {
  name: string;
  imageSrc: React.FC<SVGProps<SVGElement>>;
  isUserAuthenticated: boolean;
  bannerData: Content.DocCenterConfigDocumentDataDocCenterHeroItem;
};

function WelcomeBanner(props: Props) {
  const { bannerData, imageSrc: BannerIcon, name, isUserAuthenticated } = props;
  const heading = bannerData?.headline;

  return (
    <Box className={css["banner-slider-container"]}>
      <Box className={css["banner-text"]}>
        <Text as="h2">
          {heading}
          {name ? `, ${name}` : ""}
        </Text>

        {bannerData.description && (
          <PrismicRichText field={bannerData.description} />
        )}

        {!isUserAuthenticated && (
          <Box className={css["banner-buttons"]}>
            <Button
              className="app-btn-solid-white"
              onClick={() => {
                window.location.href = `/profile-setup`;
              }}
              width={{ base: "100%", md: "unset" }}
            >
              Enroll now
            </Button>
            <Button
              className="app-btn-inverse-secondary"
              onClick={() => {
                window.location.href = `/console/login?returnPage=${window.location.pathname}`;
              }}
              display={{ base: "none", md: "flex" }}
            >
              Log in
            </Button>
          </Box>
        )}
      </Box>
      <Show above="md">
        <BannerIcon />
      </Show>
    </Box>
  );
}
export default WelcomeBanner;
