"use client";

import {
  Box,
  Flex,
  Heading,
  VStack,
  Text,
  useToast,
} from "@/components/ChakraUiManager";
import "./ProfileTemplate.scss";
import React, { useState, useContext } from "react";
import {
  AddNewButton,
  AppFormLabel,
  SelectDropdown,
  CardContainer,
  ErrorAlert,
  InputText,
  MultiSelectCheckBox,
} from "@/components/common";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { accordionDeleteIcon } from "public/assets";
import Image from "next/image";
import { updateProfileTemplate } from "../access-management-api-service";
import { MAIN_FORM_ID, TAB_NAMES } from "@/utils";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import { useRouter } from "next/navigation";
import {
  ProfileTemplateProps,
  initialProfileTemplateValues,
  initialFormValues,
  initialEnvironmentValues,
  ProfileTemplateFormProps,
  Environment,
  validationSchema,
  ProfileTemplateForm,
} from "./ProfileTemplateValidation";
import { ERROR_MESSAGE } from "@/components/data/form-data";
import { getEnvSpecificCoreRoles } from "@/api-config/api-service";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";

export default function ProfileTemplate({
  data,
}: {
  data: ProfileTemplateProps;
}) {
  const {
    consoleRoles,
    coreRoles,
    environments,
    supplementalRoles,
    formData,
    envsSpecificCoreRoles,
  } = data;
  const [envsCoreRoles, setEnvsCoreRoles] = useState<any>(
    envsSpecificCoreRoles ?? {}
  );
  const toast = useToast();
  const toastId = "profile-template";
  const router = useRouter();

  const { tooltipFlyoutDetails } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );

  const onSubmit = async (values: ProfileTemplateForm, actions: any) => {
    let formValue: ProfileTemplateFormProps = {
      ...initialProfileTemplateValues,
      ...formData,
      ...values,
      roles: values.roles?.map((role: any) => {
        return { id: Number(role) };
      }),
      environments: values.environments?.map((env: any) => {
        return {
          ...env,
          roles: supplementalRoles.filter((role) =>
            env.roles?.includes(role.id.toString())
          ),
        };
      }),
    };
    const response = updateProfileTemplate(formValue)
      .then((data) => {
        toast({
          id: toastId,
          description: `Profile template ${formData ? "updated" : "created"} successfully`,
          status: "success",
        });
        setTimeout(() => {
          router.push(
            `${ROUTE_BASE_URL.ACCESS_MANAGEMENT}?tab=${TAB_NAMES.profileTemplates}`
          );
        }, 5000);
        return data;
      })
      .catch((e) => {
        let message = e;
        if (typeof e === "object") {
          message = e.errors?.[0]?.errorDesc;
        }
        if (!toast.isActive(toastId)) {
          toast({
            id: toastId,
            description: message,
            status: "error",
          });
        }
        return null;
      });
  };

  let initialTemplateFormValues: ProfileTemplateForm = { ...initialFormValues };

  if (formData) {
    initialTemplateFormValues.name = formData.name || "";
    initialTemplateFormValues.roles =
      formData.roles?.map((role) => role.id?.toString()) || [];
    initialTemplateFormValues.environments =
      formData.environments?.map((environment) => {
        return {
          envID: environment.envID,
          coreRoles: environment.coreRoles,
          roles: environment.roles.map((role) => role?.id.toString()),
        };
      }) || [];
  }
  //formik configs
  const formikConfigs = useFormik({
    onSubmit,
    validationSchema,
    initialValues: initialTemplateFormValues,
  });

  // using useFormik hook from Formik Library
  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = formikConfigs;
  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };
  const handleEnvironmentChange =
    (key: string, index: number) => async (value: any) => {
      if (key?.includes("envID") && !envsCoreRoles[value]) {
        const res = await getEnvSpecificCoreRoles({ id: value });
        if (res) {
          setEnvsCoreRoles((prevState: any) => {
            let stateToBeUpdated = prevState;
            stateToBeUpdated[value] = res?.data;
            return stateToBeUpdated;
          });
        }
      }
      setFieldValue(`environments.${index}.${key}`, value);
    };
  return (
    <Box width="55%">
      <FormikProvider value={formikConfigs}>
        <form onSubmit={handleSubmit} id={MAIN_FORM_ID} noValidate>
          <VStack
            className="profile-template-container access-management-details-container"
            align={"start"}
            gap={12.8}
          >
            <Text as={"h3"}>
              {formData ? "Edit" : "Create new"} profile template
            </Text>
            <CardContainer customClass="profile-template-form-container">
              <Box>
                <AppFormLabel
                  labelName="What is the name of this new profile template?"
                  isRequired={true}
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("name")
                      ?.tooltip_text_main
                  }
                  labelFor="templateName"
                />
                <InputText
                  id="templateName"
                  onChange={handleOnChange("name")}
                  value={values.name}
                  onBlur={handleBlur}
                />
                {errors.name && touched.name && (
                  <ErrorAlert>
                    <span>{ERROR_MESSAGE}</span>
                  </ErrorAlert>
                )}
              </Box>
            </CardContainer>
            <CardContainer customClass="profile-template-form-container">
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="Select the Console user role type(s)"
                  isRequired={true}
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("roles")
                      ?.tooltip_text_main
                  }
                />
                <MultiSelectCheckBox
                  data={consoleRoles}
                  setSelectedIds={handleOnChange("roles")}
                  selectedIds={values.roles}
                  keyName={"id"}
                  columnDef={CONSOLE_ROLE_LIST_TABLE_COLUMN_DEF}
                />
              </Box>
            </CardContainer>
            <CardContainer customClass="profile-template-form-container environment">
              <FieldArray
                name="environments"
                render={({ insert, remove, push }) => (
                  <>
                    {values.environments?.map(
                      (env: Environment, index: number) => (
                        <Box
                          key={index}
                          className={`environment-container ${values.environments.length === index + 1 ? "is-last" : ""}`}
                        >
                          <Flex className="environment-heading">
                            <Heading as={"h3"}>
                              Environment {index + 1}{" "}
                            </Heading>
                            <Image
                              onClick={() => remove(index)}
                              src={accordionDeleteIcon}
                              alt="delete icon"
                              style={{ cursor: "pointer" }}
                            />
                          </Flex>
                          <Box className="app-form-field-container">
                            <AppFormLabel
                              labelName="Select environment(s)"
                              tooltipDesc={
                                tooltipFlyoutDetails?.tooltipsMap?.get("envID")
                                  ?.tooltip_text_main
                              }
                              isRequired={true}
                              labelFor={`environmentName${index}_input`}
                            />
                            <SelectDropdown
                              dropdownList={environments}
                              value={env.envID}
                              onChange={handleEnvironmentChange("envID", index)}
                              placeholder="Select"
                              id={`environmentName${index}`}
                              clearable={false}
                            ></SelectDropdown>
                          </Box>
                          {env.envID && (
                            <>
                              <Box className="app-form-field-container">
                                <AppFormLabel
                                  labelName="Select the Core user role type(s)"
                                  isRequired={true}
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "coreRoles"
                                    )?.tooltip_text_main
                                  }
                                />
                                <MultiSelectCheckBox
                                  data={envsCoreRoles?.[env?.envID] ?? []}
                                  setSelectedIds={handleEnvironmentChange(
                                    "coreRoles",
                                    index
                                  )}
                                  selectedIds={env.coreRoles}
                                  keyName={"userRole"}
                                  columnDef={CORE_ROLES_TABLE_COLUMN_DEF}
                                />
                                {errors.environments &&
                                  (errors.environments as any[])[index]
                                    ?.coreRoles &&
                                  touched.environments?.[index]?.coreRoles && (
                                    <ErrorAlert>
                                      <span>
                                        {
                                          (errors.environments as any[])[index]
                                            ?.coreRoles
                                        }
                                      </span>
                                    </ErrorAlert>
                                  )}
                              </Box>
                              <Box className="app-form-field-container">
                                <AppFormLabel
                                  labelName="Select any supplemental role type(s)"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "supCoreRoles"
                                    )?.tooltip_text_main
                                  }
                                  isRequired
                                />
                                <MultiSelectCheckBox
                                  data={supplementalRoles}
                                  setSelectedIds={handleEnvironmentChange(
                                    "roles",
                                    index
                                  )}
                                  selectedIds={env.roles}
                                  keyName={"id"}
                                  columnDef={CONSOLE_ROLE_LIST_TABLE_COLUMN_DEF}
                                />
                              </Box>
                            </>
                          )}
                        </Box>
                      )
                    )}
                    {values.environments?.length < environments?.length && (
                      <AddNewButton
                        label={
                          values.environments?.length
                            ? "Add another environment"
                            : "Add environment"
                        }
                        onClick={() => {
                          push({ ...initialEnvironmentValues });
                        }}
                      />
                    )}
                  </>
                )}
              ></FieldArray>
            </CardContainer>
          </VStack>
        </form>
      </FormikProvider>
    </Box>
  );
}

const CONSOLE_ROLE_LIST_TABLE_COLUMN_DEF = [
  { header: "Role", accessorKey: "name", enableSorting: false },
  { header: "Description", accessorKey: "description", enableSorting: false },
  { header: "", accessorKey: "action", enableSorting: false },
];
const CORE_ROLES_TABLE_COLUMN_DEF = [
  { header: "Role", accessorKey: "userRole", enableSorting: false },
  { header: "Description", accessorKey: "description", enableSorting: false },
  { header: "", accessorKey: "action", enableSorting: false },
];
