"use client";
import { Box, useToast } from "@/components/ChakraUiManager";
import {
  AppFormLabel,
  AppNumberInput,
  SelectDropdown,
  CardContainer,
  CommonTitle,
  CoupleRadioWithDropdown,
  ErrorAlert,
  RadioButtonGroup,
  SplitButton,
} from "@/components/common";

import { useContext, useEffect, useMemo, useState } from "react";
import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useFormik } from "formik";
import {
  AccountBalanceConfigurationProps,
  accountBalanceConfiguration,
  getValidationSchema,
} from "./AccountBalanceConfigurationValidation";
import {
  LimitComponentData,
  OptionsSchema,
  PostSchema,
} from "../../../model/types";
import {
  addNewComponent,
  createLimitComponent,
} from "../../product-config-client-service";
import {
  COMPONENT_CLASS,
  getConstructedFeatureNameWithComponent,
  Option,
  preventMainFormSubmitOnRJSFSubmit,
  RjsfData,
} from "@/utils";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getIfxProdType } from "@/components/product-management";
import { getFeeCalcDtlUiSchema } from "@finxact/finxact-shared-ui";

type AccountBalanceConfigurationsProps = {
  data: {
    formData: LimitComponentData | null;
    limitComponentOptionsData: PostSchema;
    minimumBalanceFee: string[];
    minimumBalanceOption: Option<string>[];
    feeCalcEndpoint: string;
    feeCalcDtlSchema: OptionsSchema;
  };
};

const AccountBalanceConfiguration = (
  props: AccountBalanceConfigurationsProps
) => {
  const { formData, limitComponentOptionsData, feeCalcEndpoint } = props.data;
  const toast = useToast();
  // Form Submit Handler
  const onSubmit = async (
    values: AccountBalanceConfigurationProps,
    actions: any
  ) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage: COMPONENT_CLASS["limit"],
          status: getWorflowStatusToBeUpdated?.() ?? "",
        });
      return;
    }

    if (formData) {
      if (productDetails) {
        const res = await createLimitComponent({
          productDetails,
          formData: {
            ...formData,
            // PATCH fields
            deminimisAmt: parseFloat(values.deminimisAmt),
            minBal: values.isMinBalRequired
              ? parseFloat(values.minBal)
              : parseFloat(""),
            maxBal: parseFloat(values.maxBal),
            minToOpen: parseFloat(values.minToOpen),
            minBalFee: values.minBalFee !== "" ? values.minBalFee : null,
            minBalOpt: values.minBalOpt,
          },
          toastOptions: {
            toast,
            toastId: "acc-bal-config",
            successMessage: `${
              tooltipFlyoutDetails?.pageHeaderDetails?.heading
                ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
                : "Account Balance Configuration"
            } updated`,
          },
          stageName: getWorflowStatusToBeUpdated?.() ?? "",
        });
        if (res) {
          getExistingProduct?.(productDetails.name, true);
        }
      }
    } else {
      console.error("Component not found");
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(limitComponentOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [limitComponentOptionsData]);

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      isMinBalRequired:
        props.data.formData?.minBal === undefined &&
        props.data.formData?.minToOpen === undefined
          ? undefined
          : props.data.formData?.minBal !== undefined,
      minBal:
        props.data.formData?.minBal?.toString() ??
        accountBalanceConfiguration.minBal,
      minToOpen:
        props.data.formData?.minToOpen?.toString() ??
        accountBalanceConfiguration.minToOpen,
      maxBal:
        props.data.formData?.maxBal?.toString() ??
        accountBalanceConfiguration.maxBal,
      deminimisAmt:
        props.data.formData?.deminimisAmt?.toString() ??
        accountBalanceConfiguration.deminimisAmt,
      minBalFee:
        props.data.formData?.minBalFee ?? accountBalanceConfiguration.minBalFee,
      minBalOpt:
        props.data.formData?.minBalOpt ?? accountBalanceConfiguration.minBalOpt,
    },
  });
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    ifxAcctProductType,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    productDetails,
    getWorflowStatusToBeUpdated,
    tooltipFlyoutDetails,
    getExistingProduct,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  /**
   * Component Handler
   */
  const _handleChange =
    (key: keyof AccountBalanceConfigurationProps) =>
    (value: string | SplitBtnInputValType | number) => {
      setFieldValue(key, value);
    };

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (isGoingBack) {
      navigateToNextPage?.();
      return;
    }
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [canCheckFormStatus, isGoingBack]);

  const feeCalcDtlOptionsSchema = useMemo(() => {
    const { actions, name } = props.data.feeCalcDtlSchema;
    return { name, ...actions.POST };
  }, [props.data.feeCalcDtlSchema]);

  // Add new FeeCalcDtl
  const addNewFeeCalcDtl = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      feeCalcEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }

    setFieldValue("minBalFee", response.name);
  };

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      noValidate
      id="finxact-form"
    >
      <CommonTitle
        depositName={getConstructedFeatureNameWithComponent(
          "componentLimit",
          getIfxProdType(productDetails),
          true
        )}
        featureName={props.data.formData?.componentName ?? ""}
        version={`VERSION ${props.data.formData?.version ?? "1"}`}
        mainTitle={
          tooltipFlyoutDetails?.pageHeaderDetails?.heading
            ? tooltipFlyoutDetails?.pageHeaderDetails?.heading
            : "Account Balance Configuration"
        }
        subText={
          tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
            ? tooltipFlyoutDetails?.pageHeaderDetails?.subHeading
            : ""
        }
      />
      <CardContainer customClass="app-form-container">
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Do you want to require a minimum balance?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isMinBalRequired")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            leftBtnName="Yes"
            rightBtnName="No"
            onSelect={_handleChange("isMinBalRequired")}
            value={values.isMinBalRequired}
            onBlur={handleBlur}
          />
          {errors.isMinBalRequired && touched.isMinBalRequired && (
            <ErrorAlert>
              <span>{errors.isMinBalRequired}</span>
            </ErrorAlert>
          )}
        </Box>
        {values.isMinBalRequired && (
          <Box className="dashed-left-border app-form-field-container">
            <Box className="app-form-field-container">
              <AppNumberInput
                value={values.minBal}
                labelName="What is the minimum required balance?"
                onChange={_handleChange("minBal")}
                onBlur={handleBlur}
                valuePrefix="$"
                tooltipDesc={
                  tooltipFlyoutDetails?.tooltipsMap?.get("minBal")
                    ?.tooltip_text_main
                }
              />
              {errors.minBal && touched.isMinBalRequired && (
                <ErrorAlert>
                  <span>{errors.minBal}</span>
                </ErrorAlert>
              )}
            </Box>
          </Box>
        )}
        <Box className="app-form-field-container">
          <AppNumberInput
            value={values.minToOpen}
            labelName="What is the minimum amount to open?"
            onChange={_handleChange("minToOpen")}
            onBlur={handleBlur}
            valuePrefix="$"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("minToOpen")
                ?.tooltip_text_main
            }
          />
          {errors.minToOpen && touched.minToOpen && (
            <ErrorAlert>
              <span>{errors.minToOpen}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppNumberInput
            value={values.maxBal}
            labelName="What is the maximum balance?"
            onChange={_handleChange("maxBal")}
            onBlur={handleBlur}
            valuePrefix="$"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("maxBal")
                ?.tooltip_text_main
            }
          />

          {errors.maxBal && touched.maxBal && (
            <ErrorAlert>
              <span>{errors.maxBal}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppNumberInput
            value={values.deminimisAmt}
            labelName="What is the de minimis amount?"
            onChange={_handleChange("deminimisAmt")}
            onBlur={handleBlur}
            valuePrefix="$"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("deminimisAmt")
                ?.tooltip_text_main
            }
          />
          {errors.deminimisAmt && touched.deminimisAmt && (
            <ErrorAlert>
              <span>{errors.deminimisAmt}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the minimum balance fee?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("minBalFee")
                ?.tooltip_text_main
            }
            labelFor="minimum_balance_fee_dropdown_input"
          />
          <CoupleRadioWithDropdown
            id="minimum_balance_fee_dropdown"
            primaryOptionValue={values.minBalFee}
            primaryRadioName="Leverage existing fee"
            secondaryRadioName="Create new fee"
            handlePrimaryOptionSelect={_handleChange("minBalFee") as any}
            placeHolderName="Select existing fee"
            dropdownList={props.data.minimumBalanceFee}
            onBlur={handleBlur}
            schema={feeCalcDtlOptionsSchema}
            uiSchema={getFeeCalcDtlUiSchema}
            onAddNewSubmit={addNewFeeCalcDtl}
          />
          {errors.minBalFee && touched.minBalFee && (
            <ErrorAlert>
              <span>{errors.minBalFee}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the minimum balance option?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("minBalOpt")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            value={values.minBalOpt}
            onChange={_handleChange("minBalOpt")}
            radioPropList={props.data.minimumBalanceOption}
            stackDirection={"row"}
            isBoxedRadio
          />
          {errors.minBalOpt && touched.minBalOpt && (
            <ErrorAlert>
              <span>{errors.minBalOpt}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};
export default AccountBalanceConfiguration;
