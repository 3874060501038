"use client";

import {
  AppFormLabel,
  SelectDropdown,
  CardContainer,
  ErrorAlert,
  InputText,
  ModalSelector,
} from "@/components/common";

import { useFormik } from "formik";

import {
  AccountTypeRelationshipFoundationalDetailsValueProps,
  accountTypeRelationshipFoundationDetails,
  getValidationSchema,
} from "./AccountTypeRelationshipFoundationalDetailsValidation";
import { Box, useToast } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  shouldUpdateWorkflowStatus,
  preventMainFormSubmitOnRJSFSubmit,
} from "@/utils/common";
import { AccountTypeData } from "../../model";
import { updateAccountType } from "../../ic-config-api-service";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { IC_ENTITY } from "@/utils";
import { PostSchema } from "@/components/product-management/model/types";
import { MAIN_FORM_ID } from "@/utils";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { QUERY_PARAM_KEY } from "@/route-config/route-path";
import { constructRelData } from "../../ic-utils-service";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type AccountTypeRelationshipFoundationalDetailsProps = {
  data: {
    formData: AccountTypeData | null;
    relationshipTypeOptions: any[];
    groupTypeOptions: any[];
    depositRightCapacityOptions: any[];
    acctTypeOptionsData: PostSchema;
  };
};

const AccountTypeRelationshipFoundationalDetails = (
  props: AccountTypeRelationshipFoundationalDetailsProps
) => {
  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.acctTypeOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.acctTypeOptionsData]);
  const toast = useToast();
  const toastId = "account-type-rel-found-det";

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    getQueryParam,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const currEntriesIndex = getQueryParam?.(QUERY_PARAM_KEY.ACCT_TYPE_REL_INDEX)
    ? Number(getQueryParam?.(QUERY_PARAM_KEY.ACCT_TYPE_REL_INDEX))
    : 0;
  const onSubmit = async (
    values: AccountTypeRelationshipFoundationalDetailsValueProps,
    actions: any
  ) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: props.data.formData?.acctType!,
          stage: IC_ENTITY.account_type,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    const commonToastOptions = {
      toast,
      toastId,
    };
    const relData: any = constructRelData(
      props.data.formData,
      {
        relType: values.relType,
        reldesc: values.reldesc,
        groupType: values.groupType,
        depRightCapacity: values.depRightCapacity,
      },
      currEntriesIndex
    );
    const response = await updateAccountType({
      formData: {
        _vn: props.data.formData?._vn,
        acctType: props.data.formData?.acctType,
        rels: relData,
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },

      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      navigateToNextPage?.();
    }
  };

  const handleOnChange = (key: string) => (value: string | string[]) => {
    setFieldValue(key, value);
  };

  function relationshipTypeSelect(option: string) {
    setFieldValue("relType", option);
  }

  function depositRightCapacitySelect(option: string) {
    setFieldValue(`rels.depRightCapacity`, option);
  }

  const getGroupTypeNumber = (value: string | string[]) => {
    // From console enum (integer) and enumnames {string} are there for group type, dropdown contains enumnames, so based on selection below code will get corresponding enum value.
    const selectedObject = props.data.groupTypeOptions.find(
      (obj: { label: string; value: number }) => obj.label === value
    );

    return selectedObject ? selectedObject.value : 0;
  };

  const val = props.data.groupTypeOptions.filter(
    (item) =>
      item.value === props.data?.formData?.rels?.[currEntriesIndex]?.groupType
  );

  // using useFormik hook from Formik Library
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    dirty,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: props.data.formData?.rels
      ? {
          relType:
            props.data?.formData?.rels?.[currEntriesIndex]?.relType ?? "",
          reldesc:
            props.data?.formData?.rels?.[currEntriesIndex]?.reldesc ?? "",
          groupType:
            props.data?.formData?.rels?.[currEntriesIndex]?.groupType ?? "",
          depRightCapacity:
            props?.data?.formData?.rels?.[currEntriesIndex]?.depRightCapacity ??
            "",
        }
      : accountTypeRelationshipFoundationDetails,
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);

  const relTypeOptionError = errors?.relType && touched?.relType && (
    <ErrorAlert>
      <span>{errors.relType}</span>
    </ErrorAlert>
  );

  const relDescOptionError = errors?.reldesc && touched?.reldesc && (
    <ErrorAlert>
      <span>{errors.reldesc}</span>
    </ErrorAlert>
  );

  const groupTypeOptionError = errors?.groupType && touched?.groupType && (
    <ErrorAlert>
      <span>{errors.groupType}</span>
    </ErrorAlert>
  );

  const depRightCapacityOptionError = errors?.depRightCapacity &&
    touched?.depRightCapacity && (
      <ErrorAlert>
        <span>{errors.depRightCapacity}</span>
      </ErrorAlert>
    );

  return (
    <form
      onSubmit={preventMainFormSubmitOnRJSFSubmit(handleSubmit)}
      id={MAIN_FORM_ID}
    >
      <CardContainer customClass="app-form-container">
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the account relationship type?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("relType")
                ?.tooltip_text_main
            }
          />
          <ModalSelector
            linkName={"+Select a relationship type"}
            modalTitle={"Relationship Type"}
            optionList={props.data.relationshipTypeOptions}
            onChange={handleOnChange("relType")}
            showSelection="description"
            value={values.relType}
          />
          {relTypeOptionError}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Describe the relationship type"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("reldesc")
                ?.tooltip_text_main
            }
          />
          <InputText
            name="relationship type"
            value={values.reldesc}
            onChange={handleOnChange("reldesc")}
            onBlur={handleBlur}
            label="Relationship type description"
          />
          {relDescOptionError}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the group type?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("groupType")
                ?.tooltip_text_main
            }
            labelFor="account_type_rel_foundational-input"
          />
          <SelectDropdown
            value={values.groupType}
            onChange={handleOnChange("groupType")}
            dropdownList={props.data.groupTypeOptions}
            id="account_type_rel_foundational"
          />
          {groupTypeOptionError}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the deposit right capacity?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("depRightCapacity")
                ?.tooltip_text_main
            }
          />
          <ModalSelector
            linkName={"+Select a deposit right capacity"}
            modalTitle={"Deposit Right Capacity?"}
            optionList={props.data.depositRightCapacityOptions}
            onChange={handleOnChange("depRightCapacity")}
            value={values.depRightCapacity}
          />
          {depRightCapacityOptionError}
        </Box>
      </CardContainer>
    </form>
  );
};

export default AccountTypeRelationshipFoundationalDetails;
