import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import MainContentWithoutStep from "../main-content-without-step/MainContentWithoutStep";
import "./ProductConfiguration.scss";
import { Badges } from "@/components/common";
import { getBadgeByState } from "@/components/academy/learning-util-service";
import { useRouter } from "next/navigation";
import { ProductProps } from "../model";
import { ROUTE_PATH } from "@/route-config/route-path";
interface ProductConfigurationProps {
  title: string;
  productConfigDetails: ProductProps;
  disabledState: boolean;
  completed: number | null;
  inprogress: number | null;
}

export default function ProductConfiguration(props: ProductConfigurationProps) {
  const { title, productConfigDetails, disabledState, completed, inprogress } =
    props;
  const reviewProductsDetails = productConfigDetails?.review_products;
  const reviewComponentsDetails = productConfigDetails?.review_components;
  const configureProductsDetails = productConfigDetails?.configure_products;
  const router = useRouter();

  const handleOnClick = (stepName: string, e: any) => {
    e.stopPropagation();
    router.push(ROUTE_PATH.PRODUCT_LAUNCHPAD);
  };

  return (
    <Box>
      <Flex className="product-configuration-contents">
        <Heading as={"h4"}>{title}</Heading>
        <Text className="product-configuration-status">3 available steps </Text>
      </Flex>
      {completed || inprogress ? (
        <Box className="badge-container">
          {completed ? (
            <Box>
              <Badges
                size="lg"
                {...getBadgeByState({
                  status: "COMPLETED",
                  label: `${completed} PRODUCT${
                    completed === 1 ? "" : "S"
                  } COMPLETED`,
                  showState: true,
                })}
              />
            </Box>
          ) : (
            ""
          )}

          {inprogress ? (
            <Box>
              <Badges
                size="lg"
                {...getBadgeByState({
                  status: "STARTED",
                  label: `${inprogress} PRODUCT${
                    inprogress === 1 ? "" : "S"
                  } IN PROGRESS`,
                  showState: true,
                })}
              />
            </Box>
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}

      <MainContentWithoutStep
        btnLink
        btnName="Go to Launchpad"
        title={reviewProductsDetails?.sectionTitle}
        desc={reviewProductsDetails?.sectionSubTitle}
        overviewHeader={reviewProductsDetails?.header}
        overview={reviewProductsDetails?.description}
        helpfullRes={reviewProductsDetails?.resourcesLinks}
        btnDisabled={disabledState}
        onClick={handleOnClick.bind(null, "configure product")}
      />
      <MainContentWithoutStep
        btnLink
        btnName="Go to Launchpad"
        btnDisabled={disabledState}
        onClick={handleOnClick.bind(null, "configure product")}
        title={reviewComponentsDetails?.sectionTitle}
        desc={reviewComponentsDetails?.sectionSubTitle}
        overviewHeader={reviewComponentsDetails?.header}
        overview={reviewComponentsDetails?.description}
        helpfullRes={reviewComponentsDetails?.resourcesLinks}
      />
      <MainContentWithoutStep
        btnLink
        btnName="Go to Launchpad"
        title={configureProductsDetails?.sectionTitle}
        desc={configureProductsDetails?.sectionSubTitle}
        overviewHeader={configureProductsDetails?.header}
        overview={configureProductsDetails?.description}
        helpfullRes={configureProductsDetails?.resourcesLinks}
        btnDisabled={disabledState}
        onClick={handleOnClick.bind(null, "configure product")}
        isLastItem
      />
    </Box>
  );
}
