"use client";

import {
  AppFormLabel,
  AppNumberInput,
  AppRadioProps,
  CardContainer,
  CommonTitle,
  ErrorAlert,
  InputText,
  RadioButtonGroup,
  SplitButton,
} from "@/components/common";
import "./AssetCatalogFoundationalDetails.scss";
import { Box, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import {
  AssetCatalogFoundationDetailsProps,
  assetCatalogFoundationDetails,
  getValidationSchema,
} from "./AssetCatalogFoundationalDetailsValidation";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useContext, useEffect, useState } from "react";
import { AssetCatalogData } from "../../model";
import {
  createAssetCatalog,
  updateAssetCatalog,
} from "../../ic-config-api-service";
import { ROUTE_PATH } from "@/route-config/route-path";
import {
  DUPLICATE_CONFIG,
  IC_ENTITY,
  NEW_IC_ENTITY_ID,
  REGEX_PATTERN,
  SIDEBAR_STATUS,
  useEntityId,
  useQueryParams,
} from "@/utils";
import {
  checkIfICWorflowNeedsToBeCreated,
  getIcCopyFlagInStorage,
  setIcCopyFlagInStorage,
} from "../../ic-utils-service";
import { createWorkflowGeneric } from "@/api-config/api-service";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type AccountGroupsConfigurationProps = {
  data: {
    assetClassOption: AppRadioProps<number>[];
    formData: AssetCatalogData | null;
    assetCatalogueOptionsData: PostSchema;
  };
};

const AssetCatalogFoundationalDetails = (
  props: AccountGroupsConfigurationProps
) => {
  const assetCatalogData = props.data?.formData ?? null;
  const toast = useToast();
  const toastId = "asset-catalog-toast";
  const updateQueryParams = useQueryParams();
  const entityId = useEntityId();
  const isDuplicateConfig = getIcCopyFlagInStorage();

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(
        props.data.assetCatalogueOptionsData
      );
      setValidationSchema(yupSchema);
    })();
  }, [props.data.assetCatalogueOptionsData]);

  const onSubmit = async (values: AssetCatalogFoundationDetailsProps) => {
    if (!dirty) {
      if (
        checkIfICWorflowNeedsToBeCreated(
          entityId,
          entityWorkflowData ?? [],
          IC_ENTITY.asset_catalog
        )
      ) {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: entityId,
          stage: IC_ENTITY.asset_catalog,
          status: getCurrentStageStatusName?.() ?? "",
        });
      }
      navigateToNextPage?.();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId,
    };

    if (!assetCatalogData || isDuplicateConfig) {
      response = await createAssetCatalog({
        formData: {
          ...props.data?.formData,
          assetClass: values.assetClass!,
          assetId: values.assetId,
          desc: values.desc,
          isoNumeric: parseInt(values.isoNumeric),
          scale: parseInt(values.scale),
          acrScale: parseInt(values.acrScale),
          isNotIso4217: values.isNotIso4217,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: "Asset catalog created.",
        },

        stageName: isDuplicateConfig
          ? DUPLICATE_CONFIG
          : getCurrentStageStatusName?.() ?? "",
      });
    } else {
      response = await updateAssetCatalog({
        formData: {
          _vn: assetCatalogData._vn,
          assetClass: assetCatalogData.assetClass,
          assetId: assetCatalogData.assetId,
          // PATCH Fields
          desc: values.desc,
          isoNumeric: parseInt(values.isoNumeric),
          scale: parseInt(values.scale),
          acrScale: parseInt(values.acrScale),
          isNotIso4217: values.isNotIso4217,
        },
        toastOptions: {
          ...commonToastOptions,
          successMessage: `${configPageTitle} updated.`,
        },
        // Don't udpate workflow for first step of each entity as it will be handled while creating the Entity
        stageName: "",
      });
    }
    if (response) {
      if (
        checkIfICWorflowNeedsToBeCreated(
          entityId,
          entityWorkflowData ?? [],
          IC_ENTITY.asset_catalog
        )
      ) {
        createWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: entityId,
          stage: IC_ENTITY.asset_catalog,
          status: getCurrentStageStatusName?.() ?? "",
        });
      }
      setIcCopyFlagInStorage(false);
      updateFormStatus?.(SIDEBAR_STATUS.completed);
      updateStatusInSideBarMenuList?.(SIDEBAR_STATUS.completed);
      navigateTo(
        `${ROUTE_PATH.IC_ASSET_ORIGIN_AND_CLASSIFICATION}?${updateQueryParams(
          "entityId",
          `${response.assetClass}~${response.assetId}`
        )}`
      );
    }
  };
  const handleOnChange = (key: string) => (value: any) => {
    setFieldValue(key, value);
  };
  // using useFormik hook from Formik Library
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
  } = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      assetClass:
        assetCatalogData?.assetClass ??
        assetCatalogFoundationDetails.assetClass,
      assetId: isDuplicateConfig
        ? assetCatalogFoundationDetails.assetId
        : assetCatalogData?.assetId.toString() ??
          assetCatalogFoundationDetails.assetId,
      desc: assetCatalogData?.desc ?? assetCatalogFoundationDetails.desc,
      isoNumeric:
        assetCatalogData?.isoNumeric?.toString() ??
        assetCatalogFoundationDetails.isoNumeric,
      isNotIso4217: assetCatalogData
        ? assetCatalogData?.isNotIso4217
        : assetCatalogFoundationDetails.isNotIso4217,
      scale:
        assetCatalogData?.scale?.toString() ??
        assetCatalogFoundationDetails.scale,
      acrScale:
        assetCatalogData?.acrScale?.toString() ??
        assetCatalogFoundationDetails.acrScale,
    },
  });

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    navigateTo,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
    if (isGoingBack) {
      const backEntityId = entityWorkflowData?.find(
        (entity) => entity.stage === IC_ENTITY.account_group
      )?.modelKey;
      navigateTo(
        `${ROUTE_PATH.IC_ACCOUNT_GROUP_ADVANCED_CONFIGURATION}?entityId=${backEntityId ?? NEW_IC_ENTITY_ID}`
      );
    }
  }, [isGoingBack, canCheckFormStatus]);

  return (
    <form onSubmit={handleSubmit} id="finxact-form">
      <CardContainer customClass="app-form-container">
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the asset class?"
            isRequired
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("assetClass")
                ?.tooltip_text_main
            }
          />
          <RadioButtonGroup
            isBoxedRadio={true}
            value={values.assetClass}
            onChange={handleOnChange("assetClass")}
            radioPropList={props.data.assetClassOption}
            stackDirection={"column"}
            spacing={"1rem"}
            disabled={Boolean(
              props.data.formData?.assetClass && !isDuplicateConfig
            )}
          />
          {errors.assetClass && touched.assetClass && (
            <ErrorAlert>
              <span>{errors.assetClass}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the asset identifier?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("assetId")
                ?.tooltip_text_main
            }
            labelFor="asset-identifier"
            isRequired
          />
          <InputText
            id="asset-identifier"
            value={values.assetId}
            onChange={handleOnChange("assetId")}
            onBlur={handleBlur}
            disabled={Boolean(
              props.data.formData?.assetId && !isDuplicateConfig
            )}
          />
          {errors.assetId && touched.assetId && (
            <ErrorAlert>
              <span>{errors.assetId}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the asset description?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("desc")?.tooltip_text_main
            }
            labelFor="asset-description"
          />
          <InputText
            id="asset-description"
            value={values.desc}
            onChange={handleOnChange("desc")}
            onBlur={handleBlur}
          />
          {errors.desc && touched.desc && (
            <ErrorAlert>
              <span>{errors.desc}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="Is this a valid ISO4217 Currency?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isNotIso4217")
                ?.tooltip_text_main
            }
          />
          <SplitButton
            name="isNotIso4217"
            onSelect={handleOnChange("isNotIso4217")}
            leftBtnName="Yes"
            rightBtnName="No"
            value={values.isNotIso4217}
          />
          {errors.isNotIso4217 && touched.isNotIso4217 && (
            <ErrorAlert>
              <span>{errors.isNotIso4217}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the ISO Numeric Code?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("isoNumeric")
                ?.tooltip_text_main
            }
            labelFor="iso-numeric-code"
          />
          <InputText
            id="iso-numeric-code"
            value={values.isoNumeric.toString()}
            onChange={handleOnChange("isoNumeric")}
            onBlur={handleBlur}
            regex={REGEX_PATTERN.ONLY_NUMBERS}
          />
          {errors.isoNumeric && touched.isoNumeric && (
            <ErrorAlert>
              <span>{errors.isoNumeric}</span>
            </ErrorAlert>
          )}
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the accrual decimal precision (scale) of the asset identifier?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("acrScale")
                ?.tooltip_text_main
            }
            labelFor="accrual-scale"
          />
          <AppNumberInput
            label="accrual-scale"
            value={values.acrScale}
            onChange={handleOnChange("acrScale")}
            onBlur={handleBlur}
          />
          {errors.acrScale && touched.acrScale && (
            <ErrorAlert>
              <span>{errors.acrScale}</span>
            </ErrorAlert>
          )}
        </Box>

        <Box className="app-form-field-container">
          <AppFormLabel
            labelName="What is the decimal precision (scale) of the asset identifier?"
            tooltipDesc={
              tooltipFlyoutDetails?.tooltipsMap?.get("scale")?.tooltip_text_main
            }
            labelFor="scale-field"
          />
          <AppNumberInput
            label="scale-field"
            value={values.scale}
            onChange={handleOnChange("scale")}
            onBlur={handleBlur}
          />
          {errors.scale && touched.scale && (
            <ErrorAlert>
              <span>{errors.scale}</span>
            </ErrorAlert>
          )}
        </Box>
      </CardContainer>
    </form>
  );
};

export default AssetCatalogFoundationalDetails;
