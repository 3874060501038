"use client";
import {
  AddNewButton,
  AppFormLabel,
  CardContainer,
  DatePicker,
  DragDropContainer,
  ErrorAlert,
  InputText,
  RadioButtonGroup,
  SplitButton,
  TimeField,
} from "@/components/common";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { Box, Stack, useToast } from "@/components/ChakraUiManager";
import {
  LendingPartyEmailProps,
  SortModuleProps,
  lpaSortModuleValue,
  getValidationSchema,
} from "./LendingPartyEmailValidation";
import { FinancialInstitutionUSBankInfoData } from "../../model";
import { updateBankParametersUSBankParams } from "../../ic-config-api-service";
import { makeDateTime, shouldUpdateWorkflowStatus } from "@/utils/common";
import { updateWorkflowGeneric } from "@/api-config/api-service";
import { IC_ENTITY } from "@/utils";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { PostSchema } from "@/components/product-management/model/types";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";

type SBAProps = {
  data: {
    emailTypeOptions: { label: string; value: string | number }[];
    formData: FinancialInstitutionUSBankInfoData;
    bankParamOptionsData: PostSchema;
  };
};
const SBALendingPartyEmail = ({ data }: SBAProps) => {
  const { bankParamOptionsData } = data;
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getCurrentStageStatusName,
    entityWorkflowData,
    tooltipFlyoutDetails,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const toast = useToast();
  const activeAccordionRef = useRef<any>();
  const onSubmit = async (values: LendingPartyEmailProps, actions: any) => {
    const currentStage = getCurrentStageStatusName();
    // If user hasn't changed any field, don't save, navigate to next page
    if (!dirty) {
      // Update workflow if required
      if (shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)) {
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.institutionalConfig,
          key: data.formData?._Id!,
          stage: IC_ENTITY.bank_parameters,
          status: currentStage,
        });
      }
      navigateToNextPage?.();
      return;
    }
    let response;
    const commonToastOptions = {
      toast,
      toastId: "SBA_Lending_Party_Email",
    };
    response = await updateBankParametersUSBankParams({
      formData: {
        _vn: data.formData._vn,
        _Id: data.formData._Id,
        // PATCH Feilds
        sbaLnContact: {
          name: values.name,
          emails: values.emails.map((email, index: number) => ({
            emailType: email.emailType,
            isPreferred: email.isPreferred,
            data: email.data,
            label: email.label,
            validFromDtm:
              email.fromDate && email.fromTime !== ""
                ? makeDateTime(email.fromDate as Date, email.fromTime)
                : null,
            validToDtm:
              email.throughDate && email.throughTime !== ""
                ? makeDateTime(email.throughDate as Date, email.throughTime)
                : null,
            verifyDtm:
              email.verifiedDate && email.verifiedTime !== ""
                ? makeDateTime(email.verifiedDate as Date, email.verifiedTime)
                : null,
          })),
        },
      },
      toastOptions: {
        ...commonToastOptions,
        successMessage: `${configPageTitle} updated.`,
      },
      stageName: shouldUpdateWorkflowStatus(entityWorkflowData!, currentStage)
        ? currentStage
        : "",
    });
    if (response) {
      navigateToNextPage?.();
    }
  };

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(bankParamOptionsData);
      setValidationSchema(
        yupSchema.fields.bankparamUSBankInfo.fields.sbaLnContact
      );
    })();
  }, [bankParamOptionsData]);

  const initialSortModuleValue: SortModuleProps[] = data?.formData?.sbaLnContact
    ?.emails?.length!
    ? data?.formData?.sbaLnContact?.emails.map((emailData) => ({
        emailType: emailData.emailType ?? undefined,
        data: emailData.data ?? "",
        label: emailData.label ?? "",
        isPreferred: emailData.isPreferred ?? undefined,
        fromDate: emailData.validFromDtm
          ? new Date(emailData.validFromDtm as string)
          : null,
        fromTime: emailData.validFromDtm
          ? new Date(emailData.validFromDtm as string).toLocaleTimeString(
              "it-IT"
            )
          : "",
        throughDate: emailData.validToDtm
          ? new Date(emailData.validToDtm as string)
          : null,
        throughTime: emailData.validToDtm
          ? new Date(emailData.validToDtm as string).toLocaleTimeString("it-IT")
          : "",
        verifiedDate: emailData.verifyDtm
          ? new Date(emailData.verifyDtm as string)
          : null,
        verifiedTime: emailData.verifyDtm
          ? new Date(emailData.verifyDtm as string).toLocaleTimeString("it-IT")
          : "",
      }))
    : [];
  // using useFormik hook from Formik Library
  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      businessLending: data.formData?.sbaLnContact?.name?.length! > 0,
      name: data.formData?.sbaLnContact?.name ?? "",
      emails: initialSortModuleValue,
    },
  });
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    dirty,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  const setPartyEmail = (fn: any) => {
    setFieldValue(`emails`, fn(values.emails));
  };
  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (!isGoingBack && canCheckFormStatus) {
      handleSubmit();
    }
  }, [isGoingBack, canCheckFormStatus]);
  const _handleLpeForm = (key: string, index?: number) => (value: any) => {
    //To avoid falsy value 0 as first time.
    const indexCheck = index == 0 ? true : index;
    if (indexCheck) {
      setFieldValue(`emails.${index}.${key}`, value);
    } else {
      setFieldValue(`${key}`, value);
    }
  };

  const _handleOnBlur = (key: string, index?: number) => () => {
    //To avoid falsy value 0 as first time.
    const indexCheck = index == 0 ? true : index;
    if (indexCheck) {
      setFieldTouched(`emails.${index}.${key}`, true);
    } else {
      setFieldTouched(`${key}`, true);
    }
  };
  //Form_Error_Handler
  const _errorHandler = (key: keyof SortModuleProps, index: number) => {
    return (
      <>
        {touched.emails?.[index]?.[key] &&
          touched.emails[index] &&
          (touched.emails[index] as any)[key] &&
          errors.emails &&
          errors.emails[index] &&
          (errors.emails[index] as any)[key] && (
            <ErrorAlert>
              <span>{(errors.emails[index] as any)[key]}</span>
            </ErrorAlert>
          )}
      </>
    );
  };

  return (
    <FormikProvider value={formik}>
      <form id="finxact-form" onSubmit={handleSubmit}>
        <CardContainer customClass="app-form-field-container app-form-container">
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="Are you doing small business lending?"
              tooltipDesc={
                tooltipFlyoutDetails?.tooltipsMap?.get("businessLending")
                  ?.tooltip_text_main
              }
            />
            <SplitButton
              onSelect={_handleLpeForm("businessLending")}
              leftBtnName="Yes"
              rightBtnName="No"
              value={values.businessLending}
            />
            {touched.businessLending && errors.businessLending && (
              <ErrorAlert>
                <span>{touched.businessLending}</span>
              </ErrorAlert>
            )}
          </Box>
          {values.businessLending && (
            <>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="Who is the party contact for SBA lending reporting?"
                  labelFor="party-name"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("partyName")
                      ?.tooltip_text_main
                  }
                />
                <InputText
                  value={values.name}
                  onChange={_handleLpeForm("name")}
                />
                {touched.name && errors.name && (
                  <ErrorAlert>
                    <span>{errors.name}</span>
                  </ErrorAlert>
                )}
              </Box>
              <FieldArray
                name="emails"
                render={({ insert, remove, push }) => (
                  <Box className="app-form-field-container">
                    <DragDropContainer
                      items={values.emails?.map((sortEle: any, index: any) => {
                        return {
                          id: index + 1,
                          deleteHandler: () => remove(index),
                          showDeleteIcon: true,
                          content: (
                            <Box key={index} ref={activeAccordionRef}>
                              <Box className="app-form-field-container">
                                <AppFormLabel
                                  labelName="What is the email type?"
                                  labelFor="party-name"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "emailType"
                                    )?.tooltip_text_main
                                  }
                                />
                                <RadioButtonGroup
                                  isBoxedRadio
                                  value={sortEle.emailType as string}
                                  onChange={_handleLpeForm("emailType", index)}
                                  radioPropList={data.emailTypeOptions}
                                  stackDirection={"row"}
                                  spacing={"1rem"}
                                  onBlur={_handleOnBlur("emailType", index)}
                                />
                                {_errorHandler("emailType", index)}
                              </Box>
                              <Box className="app-form-field-container">
                                <AppFormLabel
                                  labelName="What is the email address?"
                                  labelFor="party-name"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "email"
                                    )?.tooltip_text_main
                                  }
                                />
                                <InputText
                                  value={sortEle.data}
                                  onChange={_handleLpeForm("data", index)}
                                  onBlur={_handleOnBlur("data", index)}
                                  placeholder="abc@abc.com"
                                />
                                {_errorHandler("data", index)}
                              </Box>
                              <Box className="app-form-field-container">
                                <AppFormLabel
                                  labelName="Enter a recognizable label for the email"
                                  labelFor="party-name"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "label"
                                    )?.tooltip_text_main
                                  }
                                />
                                <InputText
                                  value={sortEle.label}
                                  onChange={_handleLpeForm("label", index)}
                                  placeholder="Finxact
                                    ID"
                                  onBlur={_handleOnBlur("label", index)}
                                />
                                {_errorHandler("label", index)}
                              </Box>
                              <Box className="app-form-field-container">
                                <AppFormLabel
                                  labelName="Is this the preferred email?"
                                  labelFor="preferred email"
                                  tooltipDesc={
                                    tooltipFlyoutDetails?.tooltipsMap?.get(
                                      "isPreferred"
                                    )?.tooltip_text_main
                                  }
                                />
                                <SplitButton
                                  value={sortEle.isPreferred}
                                  onSelect={_handleLpeForm(
                                    "isPreferred",
                                    index
                                  )}
                                  leftBtnName="Yes"
                                  rightBtnName="No"
                                  onBlur={_handleOnBlur("isPreferred", index)}
                                />
                                {_errorHandler("isPreferred", index)}
                              </Box>
                              <Box className="app-form-field-container">
                                <AppFormLabel labelName="When is the address valid from?" />
                                <Box display="flex" gap="2rem">
                                  <Stack flexBasis="50%">
                                    <AppFormLabel
                                      labelName="Date"
                                      tooltipDesc={
                                        tooltipFlyoutDetails?.tooltipsMap?.get(
                                          "validFromDate"
                                        )?.tooltip_text_main
                                      }
                                    />
                                    <DatePicker
                                      ariaLabel="address-valid-from"
                                      value={sortEle.fromDate}
                                      onUpdate={_handleLpeForm(
                                        "fromDate",
                                        index
                                      )}
                                      placeholder="Select"
                                      onBlur={_handleOnBlur("fromDate", index)}
                                    />
                                    {_errorHandler("fromDate", index)}
                                  </Stack>
                                  <Stack flexBasis="50%">
                                    <AppFormLabel
                                      labelName="Time"
                                      tooltipDesc={
                                        tooltipFlyoutDetails?.tooltipsMap?.get(
                                          "validFromTime"
                                        )?.tooltip_text_main
                                      }
                                    />
                                    <TimeField
                                      value={sortEle.fromTime}
                                      onChange={_handleLpeForm(
                                        "fromTime",
                                        index
                                      )}
                                      onBlur={_handleOnBlur("fromTime", index)}
                                      ariaLabel="time-for-address-valid-through"
                                    />
                                    {_errorHandler("fromTime", index)}
                                  </Stack>
                                </Box>
                              </Box>
                              <Box className="app-form-field-container">
                                <AppFormLabel labelName="When is the email valid through?" />
                                <Box display="flex" gap="2rem">
                                  <Stack flexBasis="50%">
                                    <AppFormLabel
                                      labelName="Date"
                                      tooltipDesc={
                                        tooltipFlyoutDetails?.tooltipsMap?.get(
                                          "validToDate"
                                        )?.tooltip_text_main
                                      }
                                    />
                                    <DatePicker
                                      ariaLabel="email-valid-through"
                                      value={sortEle.throughDate}
                                      onUpdate={_handleLpeForm(
                                        "throughDate",
                                        index
                                      )}
                                      onBlur={_handleOnBlur(
                                        "throughDate",
                                        index
                                      )}
                                      placeholder="Select"
                                    />
                                    {_errorHandler("throughDate", index)}
                                  </Stack>
                                  <Stack flexBasis="50%">
                                    <AppFormLabel
                                      labelName="Time"
                                      tooltipDesc={
                                        tooltipFlyoutDetails?.tooltipsMap?.get(
                                          "validToTime"
                                        )?.tooltip_text_main
                                      }
                                    />
                                    <TimeField
                                      value={sortEle.throughTime}
                                      onChange={_handleLpeForm(
                                        "throughTime",
                                        index
                                      )}
                                      onBlur={_handleOnBlur(
                                        "throughTime",
                                        index
                                      )}
                                      ariaLabel="time-for-address-valid-through"
                                    />
                                    {_errorHandler("throughTime", index)}
                                  </Stack>
                                </Box>
                              </Box>
                              <Box className="app-form-field-container">
                                <AppFormLabel labelName="When was the email verified on?" />
                                <Box display="flex" gap="2rem">
                                  <Stack flexBasis="50%">
                                    <AppFormLabel
                                      labelName="Date"
                                      tooltipDesc={
                                        tooltipFlyoutDetails?.tooltipsMap?.get(
                                          "verifyDate"
                                        )?.tooltip_text_main
                                      }
                                    />
                                    <DatePicker
                                      ariaLabel="email-verified-on"
                                      value={sortEle.verifiedDate}
                                      onUpdate={_handleLpeForm(
                                        "verifiedDate",
                                        index
                                      )}
                                      onBlur={_handleOnBlur(
                                        "verifiedDate",
                                        index
                                      )}
                                      placeholder="Select"
                                    />
                                    {_errorHandler("verifiedDate", index)}
                                  </Stack>
                                  <Stack flexBasis="50%">
                                    <AppFormLabel
                                      labelName="Time"
                                      tooltipDesc={
                                        tooltipFlyoutDetails?.tooltipsMap?.get(
                                          "verifyTime"
                                        )?.tooltip_text_main
                                      }
                                    />
                                    <TimeField
                                      value={sortEle.verifiedTime}
                                      onChange={_handleLpeForm(
                                        "verifiedTime",
                                        index
                                      )}
                                      onBlur={_handleOnBlur(
                                        "verifiedTime",
                                        index
                                      )}
                                      ariaLabel="time-for-address-valid-through"
                                    />
                                    {_errorHandler("verifiedTime", index)}
                                  </Stack>
                                </Box>
                              </Box>
                            </Box>
                          ),
                        };
                      })}
                      setItems={setPartyEmail}
                      isElement
                    />

                    <Box marginTop="32px">
                      <AddNewButton
                        label={
                          !values.emails.length
                            ? "Add SBA lending party email?"
                            : "Add another party email"
                        }
                        onClick={() => {
                          push({
                            ...lpaSortModuleValue,
                            sortModuleId: Math.random(),
                          });
                        }}
                      />
                    </Box>
                  </Box>
                )}
              ></FieldArray>
            </>
          )}
        </CardContainer>
      </form>
    </FormikProvider>
  );
};
export default SBALendingPartyEmail;
